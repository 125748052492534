<div class="container-table" [ngClass]="{'d-flex': (rows && rows.length === 0) || isLoading}">
  <table class="table table-hover table-borderless" *ngIf="(rows && rows.length > 0) || isLoading; else noItemsTemplate"
    [ngClass]="{'sticky-last-column': stickyLastColumn}">

    <thead>
      <tr class="border-bottom border-top table-row">

        <th>
          <input class="form-check-input" type="checkbox" (change)="selectAllRows($event)">
        </th>

        <th class="table-header" *ngFor="let column of columns" (click)="sortColumnHeader(column.field)">  
          <div class="d-flex" *ngIf="!(column.hide ?? false)">
            {{ column.header | translate }}
            <i *ngIf="column.isSortable" [class]="'ms-1 bi ' + headerIcons[column.field]"></i>
          </div>
        </th>
        
      </tr>
    </thead>

    <tbody>
      @if (isLoading) {
        <ngx-spinner
          bdColor="rgba(0,0,0,0)"
          size="default"
          color="#01B269"
          type="ball-beat"
          [fullScreen]="false">
        </ngx-spinner>
      } @else {
        <tr #tableRow class="table-row" *ngFor="let row of rows; let i = index">
  
          <td class="table-data">
            <input class="form-check-input" type="checkbox" [(ngModel)]="row.isSelected" (change)="selectRow(row?.id, $event)">
          </td>
          
          <td class="table-data" [ngStyle]="{'max-width': maxWidthStyle}" *ngFor="let column of columns" 
            (mouseenter)="onMouseEnter($event)" (mouseleave)="onMouseLeave($event)">
    
            <div class="table-data-content" *ngIf="!column.routerLink" [tooltip]="setTooltip(row, column)"
              [ngClass]="setCssClasses(row, column)">
  
              {{ displayValue(row, column) }}
            </div>
  
            <div class="table-data-content" *ngIf="column.routerLink" [tooltip]="setTooltip(row, column)"
              [ngClass]="setCssClasses(row, column)" [routerLink]="setRouteLink(row, column)" [queryParams]="setQueryParams(row, column)">
  
              {{ displayValue(row, column) }}
            </div>
  
            <div *ngIf="column.field == 'actionsDynamic' && row['showActions'] ?? true">
              <dropdown-orizontal-dots [dropdownId]="row[customId]" [options]="setActionOptions(row, column)"></dropdown-orizontal-dots>
            </div>
    
          </td>
  
        </tr>        
      }
    </tbody>

  </table>


  <ng-template #noItemsTemplate>
    <no-items class="flex-1"></no-items>
  </ng-template>
</div>

<div class="table-footer">
  
  <div class="table-footer-left">
    <span class="total-rows pe-2">Total {{totalItems}} rows</span>
    <pagination [totalItems]="totalItems" [(ngModel)]="currentPage" [itemsPerPage]="itemsPerPage"
      (pageChanged)="pageChanged.emit($event.page)" [boundaryLinks]="true" [maxSize]="maxPagesToShowPagination"
      [rotate]="false" previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
    </pagination>
  </div>


  <div *ngIf="showFooterRight" class="table-footer-right">
    <div class="d-flex align-items-center">      
      <select class="row-per-page page-size p-1" id="pageSize" [(ngModel)]="itemsPerPage"
        (ngModelChange)="onPageSizeChange($event)">
        <option *ngFor="let size of listRowToShowPerPage;" [ngValue]="size">{{ size }} / {{'PAGE' | translate}}</option>
      </select>
    </div>

    <div class="go-to-page d-flex align-items-center gap-2">
      <label class="m-0 form-label text-nowrap" for="goToInput">{{'GO_TO' | translate}}</label>
      <input class="go-to form-control rounded-1" id="goToInput" [(ngModel)]="goToPageNumber"
        (keyup.enter)="onGoToPage()" type="number" min="1" [max]="totalPages()">
    </div>

  </div>

</div>