<div class="card-light card-cost">
    <div class="h-100">

        <h3 class="title-cost">{{'COST' | translate}}</h3>
    
        <div class="container-no-cost" *ngIf="!task.taskCost?.netCost && !task.taskCost?.grossCost && !task.taskCost?.accountingDate && !isEditMode">
            <svg width="64" height="40" viewBox="0 0 64 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_6612_3364)">
                <rect width="64" height="40" fill="white" fill-opacity="0.01"/>
                <path d="M32 39.7031C49.6731 39.7031 64 36.5923 64 32.755C64 28.9177 49.6731 25.807 32 25.807C14.3269 25.807 0 28.9177 0 32.755C0 36.5923 14.3269 39.7031 32 39.7031Z" fill="#F5F5F5"/>
                <path d="M55 13.6653L44.854 2.24866C44.367 1.47048 43.656 1 42.907 1H21.093C20.344 1 19.633 1.47048 19.146 2.24767L9 13.6663V22.8367H55V13.6653Z" stroke="#CED4DA"/>
                <path d="M41.613 16.8128C41.613 15.2197 42.607 13.9046 43.84 13.9036H55V31.9059C55 34.0132 53.68 35.7402 52.05 35.7402H11.95C10.32 35.7402 9 34.0122 9 31.9059V13.9036H20.16C21.393 13.9036 22.387 15.2167 22.387 16.8098V16.8317C22.387 18.4248 23.392 19.7111 24.624 19.7111H39.376C40.608 19.7111 41.613 18.4128 41.613 16.8198V16.8128Z" fill="#FAFAFA" stroke="#CED4DA"/>
                </g>
                <defs>
                <clipPath id="clip0_6612_3364">
                <rect width="64" height="40" fill="white"/>
                </clipPath>
                </defs>
            </svg> 
            <div class="no-costs">{{'COSTS.NO_COSTS_INSERTED' | translate}}</div>
        </div>
    
        <div class="container-cost mb-3" *ngIf="task.taskCost?.netCost || task.taskCost?.grossCost || task.taskCost?.accountingDate || isEditMode">
            <div *ngIf="isEditMode">
    
                <form class="d-flex gap-2 flex-wrap" [formGroup]="form">

                    <div class="form-group col">
                        <label for="cost-net" class="form-label">{{'COSTS.COST_NET' | translate}}</label>
                        <input type="number" class="form-control" id="cost-net" formControlName="netCost">
                    </div>

                    <div class="form-group col">
                        <label for="cost-gross" class="form-label">{{'COSTS.COST_GROSS' | translate}}</label>
                        <input type="number" class="form-control" id="cost-gross" formControlName="grossCost">
                    </div>
    
                    <div class="form-group col">        
                        <label for="accountingDate" class="form-label">{{'COSTS.DATE_ACCOUNTING' | translate}}</label>                        
                        <input-date-picker formControlName="accountingDate"></input-date-picker>        
                    </div>
    
                </form>
    
            </div>

            <div *ngIf="!isEditMode && (task.taskCost?.netCost || task.taskCost?.grossCost || task.taskCost?.accountingDate)">
                <div class="d-flex flex-column gap-1">
                    <div class="d-flex gap-1" *ngIf="task.taskCost?.netCost">
                        <span>{{'COSTS.COST_NET' | translate}}</span> 
                        <div>{{task.taskCost?.netCost}} €</div>
                    </div>
                    <div class="d-flex gap-1" *ngIf="task.taskCost?.grossCost">
                        <span>{{'COSTS.COST_GROSS' | translate}}</span> 
                        <div>{{task.taskCost?.grossCost}} €</div>
                    </div>
                    <div class="d-flex gap-1" *ngIf="task.taskCost?.accountingDate">
                        <span>{{'COSTS.DATE_ACCOUNTING' | translate}}</span> 
                        <div>{{task.taskCost?.accountingDate | date: 'dd-MM-yyyy'}}</div>
                    </div>
                
                </div>
            </div>
        
        </div>
    </div>

    <div class="card-light-footer d-flex gap-2">
        <button class="btn-gs-primary-noborder mt-4" 
            *ngIf="!isEditMode" (click)="editMode()">{{'ACTIONS.EDIT' | translate}}</button>

        <button class="btn-gs-secondary" (click)="cancel()" *ngIf="isEditMode">{{'ACTIONS.CANCEL' | translate}}</button>
        <button class="btn-gs-primary" (click)="onSubmit()" *ngIf="isEditMode" [disabled]="form.invalid">{{'ACTIONS.SAVE' | translate}}</button>
    </div>
</div>
