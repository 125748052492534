import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateUtilsService } from './date-utils.service';
import { Task, TaskType } from '../models/task';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TaskUtilsService {
  constructor(private datePipe: DatePipe, 
    private dateUtilsService: DateUtilsService,
    private _translate: TranslateService
  ) {}

  setActionsTask (task: Task) : Task {
    
    task.actions = [];
    if (task.deleted === false) {
      task.actions.push({ text: this._translate.instant("ACTIONS.EDIT"), value: 'edit'});
      task.actions.push({ text: this._translate.instant("ACTIONS.CANCEL"), value: 'cancel'});
    } else {
      task.actions.push({ text: this._translate.instant("ACTIONS.UNCANCEL"), value: 'uncancel'});
    }

    return task;
  }

  setStatusBadgeTask(task : Task, taskType : TaskType, vehicleMileage : number = null) : Task {
    task.statusClient = { text: '', tooltip: '', cssClass: ''};

    if (!task.endDate) {
      task.statusClient.text = this._translate.instant('TASKS.TASK_ACTIONS_LIST.TO_DO');
    }

    if (task.endDate) {
      task.statusClient.text = this._translate.instant('TASKS.TASK_ACTIONS_LIST.DONE');
    }

    if (task.deleted) {
      task.statusClient.text = this._translate.instant('TASKS.TASK_ACTIONS_LIST.CANCEL');
    }

    if (!task.endDate) {
      task.statusClient.cssClass = 'bg-info';

      if (!task.assignedUserId) {
        task.statusClient.cssClass  = 'bg-warning';
      }

      let expirationDate;
      try {
        expirationDate = this.datePipe.transform(task.expirationDate, 'dd-MM-yyyy');
      } catch (error) {
        let taskExpirationDate = this.dateUtilsService.parseDateFromString(task.expirationDate);
        expirationDate = this.datePipe.transform(taskExpirationDate, 'dd-MM-yyyy');
      }
      
      let now = new Date();
      expirationDate = new Date(expirationDate);
      if (expirationDate && expirationDate < now) {
        task.statusClient.cssClass  = 'bg-danger';
      }

      if (vehicleMileage && task.expirationMileage && vehicleMileage >= task.expirationMileage && taskType.vehicleRequired) {
        task.statusClient.cssClass  = 'bg-danger';
      }
    }

    if (task.endDate) {
      task.statusClient.cssClass  = 'bg-success';
    }

    if (task.deleted) {
      task.statusClient.cssClass  = 'bg-black';
    }

    task.statusClient.cssClass += ' custom-badge';

    return task;
  }

  setStatusDropdownTask(task : Task, mileage : number = null) : Task {
    task.statusClient = { text: '', tooltip: '', cssClass: ''};

    if (!task.endDate) {
      task.statusClient.text = this._translate.instant('TASKS.TASK_ACTIONS_LIST.TO_DO');
    }

    if (task.endDate) {
      task.statusClient.text = this._translate.instant('TASKS.TASK_ACTIONS_LIST.DONE');
    }

    if (task.deleted) {
      task.statusClient.text = this._translate.instant('TASKS.TASK_ACTIONS_LIST.CANCEL');
    }

    if (!task.endDate) {
      task.statusClient.cssClass = 'bg-info';

      if (!task.assignedUserId) {
        task.statusClient.cssClass  = 'bg-warning';
      }

      let expirationDate;
      try {
        expirationDate = this.datePipe.transform(task.expirationDate, 'dd-MM-yyyy');
      } catch (error) {
        let taskExpirationDate = this.dateUtilsService.parseDateFromString(task.expirationDate);
        expirationDate = this.datePipe.transform(taskExpirationDate, 'dd-MM-yyyy');
      }
      
      let now = new Date();
      expirationDate = new Date(expirationDate);
      if (expirationDate && expirationDate < now) {
        task.statusClient.cssClass  = 'bg-danger';
      }

      if (mileage && task.expirationMileage && mileage >= task.expirationMileage) {
        task.statusClient.cssClass  = 'bg-danger';
      }
    }

    if (task.endDate) {
      task.statusClient.cssClass  = 'bg-success';
    }

    if (task.deleted) {
      task.statusClient.cssClass  = 'bg-black';
    }

    return task;
  }

  setShowProgressBarTask(task : Task) : boolean {
    if (!task.endDate && (task.expirationDate || task.expirationMileage)) {
        return true;
    }
    else {
      return false;
    } 
  }

  getRemainingDays(task: Task): Task {
    if (!task?.expirationDate) {
      return task;
    }

    const now = new Date();
    const expirationDate = new Date(task.expirationDate);
    const timeDiff = expirationDate.getTime() - now.getTime();

    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

    task.remaingDays = {
      number: daysDiff,
      cssClass: 'color-danger'
    };

    return task;
  }
}