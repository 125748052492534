import * as Sentry from "@sentry/angular-ivy";
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { StorageService } from '../api-services/storage.service';
import { TenantService } from '../api-services/tenant.service';

@Injectable({
  providedIn: 'root'
})
export class StorageUtilsService {
  tenantId = this._tenatService.getTenantId();

  constructor(
    private _tenatService: TenantService,
    private _storageService: StorageService,
    private _sanitizer: DomSanitizer
  ) { }

  getFileSelect($event: Event) {
    const inputElement = $event.target as HTMLInputElement;
    return inputElement.files?.[0] || null;
  }

  onFileSelected(selectedFile: File) {    
    if (selectedFile) {
      let urlCreator = window.URL || window.webkitURL;
      let safeUrl = this._sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(selectedFile));
      return of(safeUrl);
    } else {
      return of(null); 
    }
  }

  uploadFile(selectedFile: File, bucketNameImage: string): Observable<any> {
    return new Observable(observer => {
      if (!selectedFile) {
        observer.next(null);
        observer.complete();
        return;
      }
      
      const formData = new FormData();
      const encodedFileName = encodeURIComponent(selectedFile.name);
      formData.append('formFile', selectedFile, encodedFileName);

      this._storageService.addFile$(bucketNameImage, this.tenantId, formData).subscribe({
        next: response => {
          observer.next(response);
          observer.complete();
        },
        error: error => {
          Sentry.captureEvent(error);
          observer.error(error);
        }
      });
    });
  }
}