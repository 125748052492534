import { Injectable, signal } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
    private eventSubject = new Subject<any>();
    private navbarSubject = new Subject<any>();

    private specificEventSubject = new Map<string, Subject<any>>();

    updateSignal = signal<boolean>(false);

    sendEvent() {
        this.eventSubject.next(undefined);
    }

    getEvent() {
        return this.eventSubject.asObservable();
    }

    sendSpecificEvent(eventName: string) {
        if (!this.specificEventSubject.has(eventName)) {
            this.specificEventSubject.set(eventName, new Subject<any>());
        }
        this.specificEventSubject.get(eventName).next(undefined);
    }

    getSpecificEvent(eventName: string) {
        if (!this.specificEventSubject.has(eventName)) {
            this.specificEventSubject.set(eventName, new Subject<any>());
        }
        return this.specificEventSubject.get(eventName).asObservable();
    }

    sendSpecificEventWithData(eventName: string, data: any) {
        if (!this.specificEventSubject.has(eventName)) {
            this.specificEventSubject.set(eventName, new Subject<any>());
        }
        this.specificEventSubject.get(eventName).next(data);
    }

    getSpecificEventWithData(eventName: string) {
        if (!this.specificEventSubject.has(eventName)) {
            this.specificEventSubject.set(eventName, new Subject<any>());
        }
        return this.specificEventSubject.get(eventName).asObservable();
    }

    sendEvetToUpdateNavbar() {
        this.navbarSubject.next(undefined);
    }

    getEvetToUpdateNavbar() {
        return this.navbarSubject.asObservable();
    }
}