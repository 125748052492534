import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Observer, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { VehiclesService } from 'src/app/shared/api-services/vehicles.service';
import { VehicleResp } from 'src/app/shared/models/vehicle';
import { FilterModalService } from '../filter-modal.service';
import { DropdownPrimaryBookingTypeComponent } from '../../../dropdown/dropdown-primary/dropdown-primary-booking-type/dropdown-primary-booking-type.component';

@Component({
  selector: 'filter-vehicle-usages-modal',
  templateUrl: './filter-vehicle-usages-modal.component.html',
  styleUrls: ['./filter-vehicle-usages-modal.component.scss']
})
export class FilterVehicleUsagesModalComponent {
  id = 'filter-vehicle-usages-modal';
  private tenantId = this._tenantService.getTenantId();
  form: FormGroup;

  @Input() autoCompleteParams: any = {};
  vehicleDisplayName$: Observable<string[]>;
  vehicleLicensePlate$: Observable<string[]>;

  constructor(
    private _tenantService: TenantService,
    private _vehiclesService: VehiclesService,
    private _filterTaskModalService: FilterModalService,
    public bsModalRef: BsModalRef
  ) {
    this.form = new FormGroup({
      vehicleId: new FormControl(null),
      vehicleDisplayName: new FormControl(null),
      vehicleLicensePlate: new FormControl(null),
    });
  }
  
  ngOnInit() {
    let form = this._filterTaskModalService.getForm(this.id);
    if (form) {
      this.form = form;
    }
    this.autoComplete();
  }

  private autoComplete() {
    this.vehicleDisplayName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['vehicleDisplayName'].value);
    }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._vehiclesService.listVehicles$(this.tenantId, 
        {displayNameContains: token, limit: 5, ...this.autoCompleteParams})),
      map((vehicle : VehicleResp) => {
        let displayNames : string[] = [];
        vehicle.items.forEach((vehicle) => {
          displayNames.push(vehicle.displayName);
        });
        return displayNames;
      }),
    );

    this.vehicleLicensePlate$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['vehicleLicensePlate'].value);
    }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._vehiclesService.listVehicles$(this.tenantId, 
        {licensePlateContains: token, limit: 5, ...this.autoCompleteParams})),
      map((vehicle : VehicleResp) => {
        let licensePlates : string[] = [];
        vehicle.items.forEach((vehicle) => {
          licensePlates.push(vehicle.licensePlate);
        });
        return licensePlates;
      }),
    );
  }

  onSelectVehicleDisplayName(match: TypeaheadMatch) {
    const displayName = match.value;
    
    this._vehiclesService.listVehicles$(this.tenantId, { displayName: displayName }).subscribe({
      next: (response) => {
        if (response.items.length > 0) {
          this.form.patchValue({ 
            vehicleId: response.items[0].id, 
            vehicleDisplayName: response.items[0].displayName,
            vehicleLicensePlate: response.items[0].licensePlate
          });
        }
      }
    });
  }

  onSelectVehicleLicensePlate(match: TypeaheadMatch) {
    const licensePlate = match.value;
    
    this._vehiclesService.listVehicles$(this.tenantId, { licensePlate : licensePlate }).subscribe({
      next: (response) => {
        if (response.items.length > 0) {
          this.form.patchValue({ 
            vehicleId: response.items[0].id, 
            vehicleDisplayName: response.items[0].displayName,
            vehicleLicensePlate: response.items[0].licensePlate
          });
        }
      }
    });
  }

  apply() {
    this._filterTaskModalService.applyFilterFromForm(this.id, this.form);
    this.bsModalRef.hide();
  }

  reset() {
    this.form.reset();
    this._filterTaskModalService.applyFilterFromForm(this.id, this.form);
  }
}
