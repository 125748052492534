import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Asset } from '../models/asset';

@Injectable({
  providedIn: 'root'
})
export class AssetUtilsService {
  constructor(
    private _translate: TranslateService
  ) { }

  setActionsAssets (asset: Asset) : Asset {
    asset.actions = [
      { text: this._translate.instant("ACTIONS.DETAIL"), value: 'detail'},
      { text: this._translate.instant("ACTIONS.EDIT"), value: 'edit'},
      { text: this._translate.instant("ACTIONS.DELETE"), value: 'delete'},
    ];

    return asset;
  }
}