<div [class]="'input-gs-group d-flex align-items-center justify-content-between border-gs rounded ' + cssClass">
                    
    <input type="text" class="form-control-gs" inputmode='none'
        [placeholder]="placeholder | translate"
        bsDatepicker 
        #dp="bsDatepicker" 
        [bsConfig]="{ 
            dateInputFormat: 'DD-MM-YYYY', 
            isAnimated: true, 
            adaptivePosition: true 
        }" 
        placement="top" 
        [(ngModel)]="value"
        (bsValueChange)="onChange($event)">
    
    <i class="icon bi bi-calendar-check text-black-50" (click)="dp.toggle()" [attr.aria-expanded]="dp.isOpen" type="button"></i>
    
</div>