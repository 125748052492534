import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { Headquarter, HeadquarterResponse, Location, LocationResp } from '../models/headquarters';
import { Observable, catchError, map, share, shareReplay } from 'rxjs';
import { CommonService } from './common.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class HeadquartersService {
  
  constructor(
    private _http: HttpClient,
    private _commonService: CommonService,
    private _toasterService: ToastrService,
    private _translateService: TranslateService,
    ) { }
    
    createHeadquarter$(tenantId: string, headquarter: Headquarter): Observable<Headquarter> {
      return this._http.post<Headquarter>(`${VECTORE_API_BASE_URL}/${tenantId}/headquarters`, headquarter)
      .pipe(
        shareReplay(1),
        map(response => {
          if (response.id) {
            this._toasterService.success(this._translateService.instant('HEADQUARTER.MESSAGES.SUCCESS_CREATED'));
          }
          return response;
        }),
        catchError(error => this._commonService.handleError(error))
      );      
    }
    
    listHeadquarters$(tenantId: string, params?: any): Observable<HeadquarterResponse> {
      return this._http.get<HeadquarterResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/headquarters`, 
      { params: this._commonService.buildParams(params) })
      .pipe(
        catchError(error => this._commonService.handleError(error))
      );    
    }
    
    getHeadquarterById$(tenantId: string, headquarterId: string): Observable<Headquarter> {
      return this._http.get<Headquarter>(`${VECTORE_API_BASE_URL}/${tenantId}/headquarters/${headquarterId}`)
      .pipe(
        catchError(error => this._commonService.handleError(error))
      );
    }
    
    updateHeadquarter$(tenantId: string, headquarterId: string, headquarter: Headquarter): Observable<Headquarter> {      
      return this._http.patch<Headquarter>(`${VECTORE_API_BASE_URL}/${tenantId}/headquarters/${headquarterId}`, headquarter)
      .pipe(
        shareReplay(1),
        map(response => {
          if (response.id) {
            this._toasterService.success(this._translateService.instant('HEADQUARTER.MESSAGES.SUCCESS_UPDATED'));
          }
          return response;
        }),
        catchError(error => this._commonService.handleError(error))
      );      
    }
    
    deleteHadquarter$(tenantId: string, headquarterId: string): Observable<HttpResponse<Object>> {      
      return this._http.delete<Headquarter>(`${VECTORE_API_BASE_URL}/${tenantId}/headquarters/${headquarterId}`, {
        observe:'response'
      })
      .pipe(
        shareReplay(1),
        map(response => {
          if (response.status === 204) {
            this._toasterService.success(this._translateService.instant('HEADQUARTER.MESSAGES.SUCCESS_DELETED'));
          } else {
            this._toasterService.error(this._translateService.instant('HEADQUARTER.MESSAGES.ERROR_DELETED'));
          }
          return response;
        }),
        catchError(error => this._commonService.handleError(error))
      );      
    }
    
    createLocation$(tenantId: string, location: Location): Observable<Location> {      
      return this._http.post<Location>(`${VECTORE_API_BASE_URL}/${tenantId}/locations`, location)
      .pipe(
        shareReplay(1),
        map(response => {
          this._toasterService.success(this._translateService.instant('LOCATION.MESSAGES.SUCCESS_CREATED'));
          return response;
        }),
        catchError(error => this._commonService.handleError(error))
      );      
    }
        
    listLocations$(tenantId: string, params?: any): Observable<LocationResp> {      
      return this._http.get<LocationResp>(`${VECTORE_API_BASE_URL}/${tenantId}/locations`, {
        params: this._commonService.buildParams(params)
      })
      .pipe(
        catchError(error => this._commonService.handleError(error))
      );
    }
    
    getLocationById$(tenantId: string, locationId: string): Observable<Location> {      
      return this._http.get<Location>(`${VECTORE_API_BASE_URL}/${tenantId}/locations/${locationId}`)
      .pipe(
        catchError(error => this._commonService.handleError(error))
      );
    }
    
    updateLocation$(tenantId: string, locationId: string, location: Location): Observable<Location> {
      return this._http.patch<Location>(`${VECTORE_API_BASE_URL}/${tenantId}/locations/${locationId}`, location)
      .pipe(
        shareReplay(1),
        map(response => {
          if (response.id) {
            this._toasterService.success(this._translateService.instant('LOCATION.MESSAGES.SUCCESS_UPDATED'));
          }
          return response;
        }),
        catchError(error => this._commonService.handleError(error))
      );
    }
    
    deleteLocation$(tenantId: string, locationId: string): Observable<HttpResponse<Object>> {
      return this._http.delete<Location>(`${VECTORE_API_BASE_URL}/${tenantId}/locations/${locationId}`, {
        observe:'response'
      })
      .pipe(
        map(response => {
          if (response.status === 204) {
            this._toasterService.success(this._translateService.instant('LOCATION.MESSAGES.SUCCESS_DELETED'));
          } else {
            this._toasterService.error(this._translateService.instant('LOCATION.MESSAGES.ERROR_DELETED'));
          }
          return response
        }),
        shareReplay(1),
        catchError(error => this._commonService.handleError(error))
      );
    }    
  }
  