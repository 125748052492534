<div class="card-person-modal">
    <header class="card-person-modal-header">
        <h4 class="card-person-modal-title" *ngIf="this.modalStatus.isCreate">
            {{ 'PERSONS.NEW' | translate }}
        </h4>

        <h4 class="card-person-modal-title" *ngIf="this.modalStatus.isPatch">
            {{ 'PERSONS.PATCH' | translate }}
        </h4>
            
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </header>

    <content>
        <form [formGroup]="personForm">
            <div class="container-avatar">
                <input #fileInput 
                    type="file" class="form-control d-none" 
                    id="imageFileId"
                    aria-describedby="imageFileIdHelp" (change)="onFileSelected($event)">

                <div class="avatar-placeholder-md">
                    <img class="w-100 h-100" *ngIf="srcImage | async as safeUrl" [src]="safeUrl">
                </div>

                <button-first-type
                    [cssClass]="'btn-gs-primary-icon'"
                    [buttonText]="file ? 'CHANGE_PHOTO' : 'UPLOAD_PHOTO'"
                    [iconClass]="'bi-camera-fill'"
                    (click)="fileInput.click()">
                </button-first-type>
            </div>

            <div class="container-main-fields d-flex gap-3 flex-column mb-4">

                <div class="form-group">
                    <label for="display-name" class="form-label">{{ 'REGISTRATION.NAME_TO_SHOW' | translate }}</label>
                    <input type="text" class="form-control" id="display-name" formControlName="displayName" aria-describedby="display-name-help">
                    <span>{{ 'REGISTRATION.NAME_TO_SHOW_INFO' | translate }}</span>
                </div>
    
                <div class="form-group">
                    <label for="username" class="form-label">{{ 'USERNAME' | translate }} <span>*</span></label>
                    <input type="text" class="form-control" id="username" formControlName="userName" aria-describedby="username-help"
                        [class.is-invalid]="personForm.controls['userName'].invalid && (personForm.controls['userName'].dirty || personForm.controls['userName'].touched)">
                </div>

                <div class="form-group">
                    <label for="email" class="form-label">{{ 'EMAIL' | translate }} <span>*</span></label>
                    <input type="text" class="form-control" id="email" formControlName="email" aria-describedby="email-help"
                        [class.is-invalid]="personForm.controls['email'].invalid && (personForm.controls['email'].dirty || personForm.controls['email'].touched)">
                </div>
    
                <div class="form-group" *ngIf="!me">
                    <label for="headquarter" class="form-label">{{'HEADQUARTER.NAME' | translate}}</label>
                    <dropdown-primary 
                        class="w-100" 
                        [emitNgOnInit]="false"
                        [activeScrollbar]="true"
                        [selectedOption]="headquarterSelected"
                        [options]="headquarterListOptions"
                        (selectedOptionChange)="onSelectedHeadquarter($event)"
                        [iconClass]="'bi bi-chevron-down'"
                        [cssClass]="'btn-dropdown'">
                    </dropdown-primary>
                </div>

            </div>
            
            <h3 class="title" *ngIf="fields && fields.length > 0">
                {{ 'REGISTRATION.TITLE_PERSONAL_INFORMATION' | translate }}
            </h3>

            <formly-form [form]="personForm" [fields]="fields"></formly-form>
            
            <div class="d-flex flex-column gap-3">
                <div class="form-group" *ngIf="timezoneListOptions">
                    <label for="timezone" class="form-label">{{'TIMEZONE' | translate}}</label>
                    <dropdown-primary 
                        class="w-100" 
                        [emitNgOnInit]="false"
                        [activeScrollbar]="true"
                        [selectedOption]="timezoneSelected"
                        [options]="timezoneListOptions"
                        (selectedOptionChange)="onSelectedTimezone($event)"
                        [iconClass]="'bi bi-chevron-down'"
                        [cssClass]="'btn-dropdown'">
                    </dropdown-primary>
                </div>
    
                <div class="form-group" *ngIf="cultureInfosListOptions">
                    <label for="culture-infos" class="form-label">{{'CULTURE_INFOS' | translate}}</label>
                    <dropdown-primary 
                        class="w-100" 
                        [emitNgOnInit]="false"
                        [activeScrollbar]="true"
                        [selectedOption]="cultureInfoSelected"
                        [options]="cultureInfosListOptions"
                        (selectedOptionChange)="onSelectedCultureInfos($event)"
                        [iconClass]="'bi bi-chevron-down'"
                        [cssClass]="'btn-dropdown'">
                    </dropdown-primary>
                </div>
            </div>

            <div class="d-flex gap-3 justify-content-center mt-4">
                <div type="button" class="btn-gs-secondary-light" (click)="this.bsModalRef.hide()">
                    {{ 'ACTIONS.CANCEL' | translate }}
                </div>

                <button type="submit" class="btn-gs-primary" 
                    [disabled]="personForm.invalid" (click)="onSubmit()">
                    {{ 'ACTIONS.SAVE' | translate }}
                </button>
            </div>

        </form>
    </content>
</div>