import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownOption } from '../dropdown-primary/dropdown.interface';
import { DropdownEventsService } from 'src/app/shared/utilities/dropdown-events.service';

@Component({
  selector: 'dropdown-dynamic',
  templateUrl: './dropdown-dynamic.component.html',
  styleUrls: ['./dropdown-dynamic.component.scss']
})
export class DropdownDynamicComponent {
  
  @Input() dropdownId!: string;
  selectedOption!: DropdownOption;
  option!: string;
  options!: DropdownOption[];

  @Input() cssClass!: string;
  @Input() iconClass!: string;

  @Input() isDisabled: boolean = false;
  @Input() isMobile!: boolean;

  @Input() activeScrollbar = false;
  
  selectedMobileOption: any;

  constructor(private dropdownEventsService: DropdownEventsService) {}

  ngOnInit(): void {
    // Impostare le opzioni ottenute dal esterno
    this.dropdownEventsService.getOptions(this.dropdownId).subscribe((newOptions: DropdownOption[]) => {
      this.options = newOptions;
    });

    // Impostare l'opzione selezionata in base all'opzione selezionata dal dropdown ed emettere l'evento
    this.dropdownEventsService.getSelectedOption(this.dropdownId).subscribe((newSelectedOption: DropdownOption) => {
      this.selectedOption = newSelectedOption;
      this.option = newSelectedOption ? newSelectedOption.text : '';
    });
    
  }

  // Metodo per selezionare l'opzione ed emettere l'evento
  selectOption(option: DropdownOption): void {
    this.selectedOption = option;
    this.option = option.text;
    this.dropdownEventsService.setSelectedOption(this.dropdownId, option);
  }

  onMobileSelectChange(): void {
    const selected = this.options.find(option => option.value === this.selectedMobileOption);
    if (selected) {
      this.selectOption(selected);
    }
  }
}
