import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownOption } from '../../dropdown-primary/dropdown.interface';

@Component({
  selector: 'dropdown-vertical-dots',
  templateUrl: './dropdown-vertical-dots.component.html',
  styleUrls: ['./dropdown-vertical-dots.component.scss']
})
export class DropdownVerticalDotsComponent {
  @Input() selectedOption!: DropdownOption;
  @Output() selectedOptionChange = new EventEmitter<DropdownOption>();
  @Input() options!: DropdownOption[]; 
  
  @Input() cssClass: string = '';

  @Input() emitNgOnInit: boolean = false;
  @Input() isDisabled: boolean = false;

  @Input() isMobile: boolean = false;

  @Input() activeScrollbar = false;

  selectedMobileOption: any;

  constructor() { }

  ngOnInit(): void {

    if (this.selectedOption?.text) {
      this.selectedOption = this.selectedOption;
    }
    
    if (this.emitNgOnInit && this.selectedOption) {
      this.selectedOptionChange.emit(this.selectedOption);
    }

  }

  ngOnChanges(): void {
    if (this.isMobile) {
      this.onMobileSelectChange();
    }
  }

  selectOption(option: DropdownOption): void {
    this.selectedOption = option;
    this.selectedOptionChange.emit(this.selectedOption);
  }

  onMobileSelectChange(): void {
    if (this.options && this.selectedMobileOption) {
      const selected = this.options.find(option => option.value === this.selectedMobileOption);
      if (selected) {
        this.selectOption(selected);
      }
    }
    
    else if (this.options) {
      this.selectOption(this.options[0]);
    }
  }
}