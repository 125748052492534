import * as Sentry from "@sentry/angular-ivy";
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { TableColumn } from '../dynamic-table/dynamic-table-desktop/table-column.interface';
import { TableRow } from '../dynamic-table/dynamic-table-desktop/table-row.interface';
import { FiltersPage } from 'src/app/shared/models/filter-page';
import { DropdownOption } from '../../dropdown/dropdown-primary/dropdown.interface';
import { FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { TranslateService } from '@ngx-translate/core';
import { DropdownEventsService } from 'src/app/shared/utilities/dropdown-events.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BookingUtilsService } from "src/app/shared/utilities/booking-utils.service";
import { VehicleUsagesService } from "src/app/shared/api-services/vehicle-usages.service";
import { VehicleUsageClient } from "src/app/shared/models/vehicle-usages/vehicle-usages";
import { VehicleUsageFilter } from "src/app/shared/models/vehicle-usages/vehicle-usage-filter";
import { MappingModel } from "src/app/shared/models/users";
import { FilterModalService } from "../../modals/filter-modal/filter-modal.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: 'vehicle-usages-table',
  templateUrl: './vehicle-usages-table.component.html',
  styleUrls: ['./vehicle-usages-table.component.scss']
})
export class VehicleUsagesTableComponent {
  tenantId: string;

  @Input() titleTable: string;

  @Input() vehicleUsageFilter: VehicleUsageFilter = {};

  tableColumns: TableColumn[];
  tableRows: TableRow[];
  rowIds: string[] = [];
  
  filtersPage: FiltersPage = {
    firstElement: 0,
    page: 1,
    itemsPerPage: 10,
    listRowToShowPerPage: [5, 10, 20, 30, 50, 100],
    totalRows: 0,
    totalPage: 1,
    maxPagesToShowPagination: 6
  };
  
  filterHeader: string;
  filterTask: DropdownOption;

  fitersTable: FormGroup;
  expirationStartDate: string;
  expirationEndDate: string;

  isMobile: boolean = false;
  inputFilter: string;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _tenantService: TenantService,
    private _vehicleUsagesService: VehicleUsagesService,
    private _translateService: TranslateService,
    private _bookingUtilsService: BookingUtilsService,
    private _dropdownEventsService: DropdownEventsService,
    private _breakpointObserver: BreakpointObserver,
    private _cdr: ChangeDetectorRef,
    private _datePipe: DatePipe,
    private _filterModalService: FilterModalService
  ) { 
    this.tenantId = this._tenantService.getTenantId();
    this._breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });

    this.filtersPage.page = 1;
    this.filtersPage.firstElement = 0;
    this.filtersPage.orderBy = '-Id';
  }

  ngOnInit(): void {
    this.isMobile? this.filtersPage.maxPagesToShowPagination = 3 : this.filtersPage.maxPagesToShowPagination = 6;
    this.applyFilterToTable();
    this.getTable();
  }

  getTable() {
    
    this.getVehicleUsages();

    this.tableColumns = [];
    if (this.isMobile) {
      this.tableColumns = [  
        { field: 'startDate', isSortable: true, 
          header: "START_DATE"
        },
        { field: 'endDate', isSortable: true, 
          header: "END_DATE"
        }, 
        { field: 'startMileage', isSortable: true, 
          header: "KM_START"
        }, 
        { field: 'endMileage', isSortable: true, 
          header: "KM_END"
        }, 
      ];

    }
    else {
      this.tableColumns = [
        { 
          field: 'vehicleId', isSortable: true, 
          header: "LICENSE_PLATE",
          value: (bookingUsage : VehicleUsageClient) => bookingUsage.vehicle?.licensePlate
        },
        { 
          field: 'userId', isSortable: true,
          header: "BOOKINGS.DRIVER",
          value: (bookingUsage : VehicleUsageClient) => bookingUsage.user?.displayName
        },    
        { field: 'startDate', isSortable: true, 
          header: "START_DATE"
        },
        { field: 'endDate', isSortable: true, 
          header: "END_DATE"
        }, 
        { field: 'startMileage', isSortable: true, 
          header: "KM_START"
        }, 
        { field: 'endMileage', isSortable: true, 
          header: "KM_END"
        },   
        { field: 'distance',
          header: "BOOKINGS.DISTANCE",
          value: (bookingUsage : VehicleUsageClient) => bookingUsage?.endMileage && bookingUsage?.startMileage ? 
            parseFloat(bookingUsage?.endMileage?.toString()) - parseFloat(bookingUsage?.startMileage?.toString()) : 'n.d.'
        },  
        {
          field: 'headquarterId', isSortable: true,
          header: "HEADQUARTER.NAME",
          value: (bookingUsage : VehicleUsageClient) => bookingUsage.headquarter?.name
        },
        {
          field: 'locationId', isSortable: true,
          header: "LOCATION.NAME",
          value: (bookingUsage : VehicleUsageClient) => bookingUsage.location?.name
        },
        {
          field: 'status',
          header: "STATUS",
          value: (booking : VehicleUsageClient) => booking.statusClient?.text,
          tooltip: (booking : VehicleUsageClient) => booking.statusClient?.tooltip,
          cssClass: (booking : VehicleUsageClient) => booking.statusClient?.cssClass
        }
      ];
    }
    this._cdr.detectChanges();
  }

  getVehicleUsages() {
    this.rowIds.forEach(id => this._dropdownEventsService.removeSubscriptions(id));

    let params : VehicleUsageFilter = {
      start: this.filtersPage.firstElement,
      limit: this.filtersPage.itemsPerPage, 
      orderBy: this.filtersPage.orderBy,
      includeHeadquarter: true,
      includeLocation: true,
      includeVehicle: true,
      includeUser: true,
    };

    if (this.vehicleUsageFilter.userId) {
      params.userId = this.vehicleUsageFilter.userId;
    }

    if (this.vehicleUsageFilter.vehicleId) {
      params.vehicleId = this.vehicleUsageFilter.vehicleId;
    }

    if (this.vehicleUsageFilter.bookingId) {
      params.bookingId = this.vehicleUsageFilter.bookingId;
    }

    this.applyFilter(params);

    this._vehicleUsagesService.listVehicleUsages$(this.tenantId, params).subscribe({
      next: (vehicleUsagesResponse) => {
        this.rowIds = [];
        vehicleUsagesResponse.items.forEach((vehicleUsage) => {
          this.rowIds.push(vehicleUsage.id);
          vehicleUsage = this._bookingUtilsService.setStatusActionVehicleUsages(vehicleUsage);
          vehicleUsage = this._bookingUtilsService.setDateVehicleUsage(vehicleUsage);
        });
        this.onActionTable();

        this.tableRows = [];
        this.tableRows = vehicleUsagesResponse.items;

        this.filtersPage.totalRows = vehicleUsagesResponse.count;
        this.filtersPage.totalPage = Math.ceil(this.filtersPage.totalRows / this.filtersPage.itemsPerPage);
      },
      error: (error) => {
        Sentry.captureEvent(error);
      }
    });
  }

  private applyFilter(params: VehicleUsageFilter) {
    if (this.fitersTable) {
      if (this.fitersTable.value.headerToFilter && this.fitersTable.value.headerToFilter === 'DisplayName') {
        this.inputFilter = this.fitersTable.value.inputFilter;
        params.vehicleId = this.inputFilter;
      }

      if (this.fitersTable.value.headerToFilter && this.fitersTable.value.headerToFilter === 'LicensePlate') {
        this.inputFilter = this.fitersTable.value.inputFilter;
        params.vehicleId = this.inputFilter;
      }

      if (this.fitersTable.value.startDate) {
        let startDate = new Date(this.fitersTable.value.startDate);
        this.vehicleUsageFilter.rangeStartDate = startDate.toISOString();
        params.rangeStartDate = this.vehicleUsageFilter.rangeStartDate;
      }
      else {
        this.vehicleUsageFilter.rangeStartDate = null;
      }

      if (this.fitersTable.value.endDate) {
        let endDate = new Date(this.fitersTable.value.endDate);
        this.vehicleUsageFilter.rangeEndDate = endDate.toISOString();
        params.rangeEndDate = this.vehicleUsageFilter.rangeEndDate;
      }
      else {
        this.vehicleUsageFilter.rangeEndDate = null;
      }
    }
  }

  getDataForm($event: FormGroup) {
    this.fitersTable = $event;
    this.getVehicleUsages();
  }

  onActionTable() {

  }

  onPageChange($newPage: number) {
    this.filtersPage.page = $newPage;
    this.filtersPage.firstElement = ($newPage - 1) * this.filtersPage.itemsPerPage;

    this.getVehicleUsages();
  }

  onPageSizeChange(newSize: number): void {
    this.filtersPage.itemsPerPage = newSize;
    this.filtersPage.page = 1;
    this.filtersPage.firstElement = 0;
    this.filtersPage.orderBy = null;
    
    this.getVehicleUsages();
  }

  onSortColumnHeader(columnField: string) {
    this.filtersPage.orderBy = columnField;
    this.getVehicleUsages();
  }

  private applyFilterToTable() {
    this._filterModalService.getForm$('filter-vehicle-usages-modal')
    .pipe(takeUntil(this.destroy$))
    .subscribe(form => {
      if (form) {
        this.vehicleUsageFilter.vehicleId = form.value.vehicleId ?? null;
        this.getVehicleUsages();
      }
    });
  }

  getExport() {
    let mappings: MappingModel = {
      mappings: [
        { sourceName: 'Code', destName: this._translateService.instant('Code') },
        { sourceName: 'Vehicle.LicensePlate', destName: this._translateService.instant('LicensePlate') },

        { sourceName: 'User.UserName', destName: this._translateService.instant('UserName') },

        { sourceName: 'Headquarter.Name', destName: this._translateService.instant('Headquarter') },
        { sourceName: 'Location.Name', destName: this._translateService.instant('Location') },

        { sourceName: 'StartDate', destName: this._translateService.instant('StartDate') },
        { sourceName: 'EndDate', destName: this._translateService.instant('EndDate') },

        { sourceName: 'StartMileage', destName: this._translateService.instant('StartMileage') },
        { sourceName: 'EndMileage', destName: this._translateService.instant('EndMileage') },

        { sourceName: 'Note', destName: this._translateService.instant('Note') },
      ]
    };

    let params : VehicleUsageFilter = {
      includeVehicle: true,
      includeUser: true,
      includeHeadquarter: true,
      includeLocation: true,
    }

    this.applyFilter(params);

    this._vehicleUsagesService.exportVehicleUsages$(this.tenantId, mappings, params)
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (response) => {
        const filename = 'export_vehicle_usages.xlsx';
        const blob = new Blob([response.body], { type: response.body.type });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(url);
      },
      error: (error) => {
        Sentry.captureEvent(error);
      }
    });
  }

  ngOnDestroy(): void {
    this._dropdownEventsService.removeSubscriptionsAll();
    this.destroy$.next();
    this.destroy$.complete();
  }
}