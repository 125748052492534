import * as Sentry from "@sentry/angular-ivy";
import { Component, Input } from '@angular/core';
import { CardShowData } from '../card-show-data.interface';
import { FilterDateService } from 'src/app/shared/utilities/filter-date.service';
import { TasksService } from 'src/app/shared/api-services/tasks.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { TaskDataResp } from 'src/app/shared/models/task';
import { DropdownOption } from "../../../dropdown/dropdown-primary/dropdown.interface";
import { TranslateService } from "@ngx-translate/core";
import { DropdownEventsService } from "src/app/shared/utilities/dropdown-events.service";
import { UserModel } from "src/app/shared/models/users";
import { Asset } from "src/app/shared/models/asset";
import { Vehicle } from "src/app/shared/models/vehicle";

@Component({
  selector: 'card-show-data-task-todo',
  templateUrl: './card-show-data-task-todo.component.html',
  styleUrls: ['./card-show-data-task-todo.component.scss']
})
export class CardShowDataTaskTodoComponent {
  tenantId: string = this._tenantService.getTenantId();

  @Input() isMobile: boolean = false;
  @Input() user: UserModel;
  @Input() vehicle: Vehicle;
  @Input() asset: Asset;

  listFilterTime: DropdownOption[];
  timeFiltersSelected!: DropdownOption[];

  cardTaskToDo: CardShowData;

  @Input() showTabs: boolean = false;

  constructor(
    private _filterDateService: FilterDateService,
    private _tenantService: TenantService,
    private _taskService: TasksService,
    private _translateService: TranslateService,
    private _dropdownEventsService: DropdownEventsService,
  ) { }

  ngOnInit(): void {
    this.listFilterTime = this._translateService.instant('TIME_FILTERS');

    if (this.user) {
      this.cardTaskToDo = {
        id: 'card-task-user',
        iconType: 'icon-task-vectore',
        iconBg: 'bg-icon-primary-light',
        cardTitle: "TASK",
        cardInfoItems: [
          {
            title: "CREATED",
            value: 0,
          },
          {
            title: "DONED",
            value: 0,
            textColorValue: 'text-success'
          },
          {
            title: "TO_DO",
            value: 0,
            textColorValue: 'text-info'
          },
          {
            title: "LATE",
            value: 0,
            textColorValue: 'text-danger'
          }
        ]
      };
    }
    else {
      this.cardTaskToDo = {
        id: 'card-task-todo',
        iconType: 'icon-task-vectore',
        iconBg: 'bg-icon-primary-light',
        cardTitle: "TASKS.SECTION1.TASKS_TO_DO.CARD_TITLE",
        cardInfoItems: [
          {
            title: "TASKS.SECTION1.TASKS_TO_DO.TOTAL",
            value: 0,
            textColorValue: 'text-info'
          },
          {
            title: "TASKS.SECTION1.TASKS_TO_DO.NOT_ASSIGNED",
            value: 0,
            textColorValue: 'text-warning'
          },
          {
            title: "TASKS.SECTION1.TASKS_TO_DO.LATE",
            value: 0,
            textColorValue: 'text-danger'
          }
        ]
      };
    }

    this._dropdownEventsService.setOptions(this.cardTaskToDo.id, this.listFilterTime);
    this._dropdownEventsService.setSelectedOption(this.cardTaskToDo.id, this.listFilterTime[3]);
    this._dropdownEventsService.getSelectedOption(this.cardTaskToDo.id).subscribe((newSelectedOption) => {

      if (this.user) {
        this.getNumberTaskUser(newSelectedOption.value);
      }
      else {
        this.getNumberTaskTodoAll(newSelectedOption.value);
      }
    });
  }

  getNumberTaskTodoAll(dateRange: string) { 

    let params: any = this.getTimeRange(dateRange);
    params.hasCloseDate = true;

    if (this.asset) {
      params.groupByAssetId = this.asset.id;
    }

    if (this.vehicle) {
      params.groupByVehicleId = this.vehicle.id;
    }
    
    this._taskService.getTaskData$(this.tenantId, params)
      .subscribe({
        next: (taskDataResp : TaskDataResp) => {
          
          if (this.isMobile) {
            this.cardTaskToDo.badge = taskDataResp.items[0] && taskDataResp.items[0].late > 0 ? " " : '';
          }
          else {
            this.cardTaskToDo.badge = taskDataResp.items[0] && taskDataResp.items[0].late > 0 ? "BADGES.ATTENTION" : '';
          }

          this.cardTaskToDo.cardInfoItems[0].value = taskDataResp.items[0] ? taskDataResp.items[0].items : 0;
          this.cardTaskToDo.cardInfoItems[1].value = taskDataResp.items[0] ? taskDataResp.items[0].notAssigned : 0;
          this.cardTaskToDo.cardInfoItems[2].value = taskDataResp.items[0] ? taskDataResp.items[0].late : 0;
        },
        error: (error) => {
          Sentry.captureEvent(error);
        }
      }
    );
  }

  getNumberTaskUser(dateRange: string) {
    let params: any = this.getTimeRange(dateRange);

    if (this.user) {
      params.groupByStartUserId = this.user.id;
      params.groupByAssignedUserId = this.user.id;
    }

    this._taskService.getTaskData$(this.tenantId, params)
      .subscribe({
        next: (taskDataResp : TaskDataResp) => {

          this.cardTaskToDo.cardInfoItems[0].value = taskDataResp.items[0] ? taskDataResp.items[0].items : 0;
          this.cardTaskToDo.cardInfoItems[1].value = taskDataResp.items[0] ? taskDataResp.items[0].items - taskDataResp.items[0].late : 0;
          this.cardTaskToDo.cardInfoItems[2].value = taskDataResp.items[0] ? taskDataResp.items[0].toDo : 0;
          this.cardTaskToDo.cardInfoItems[3].value = taskDataResp.items[0] ? taskDataResp.items[0].late : 0;
        },
        error: (error) => {
          Sentry.captureEvent(error);
        }
      }
    );
  }

  private getTimeRange(dateRange: string) {
    let params: any = {
      includeTaskData: true,
    };

    let startDate: string;
    let endDate: string;

    ({ startDate, endDate } = this._filterDateService.getStartEndDateByTypeRange(dateRange));

    params.rangeStartExpirationDate = startDate;
    params.rangeEndExpirationDate = endDate;
    return params;
  }
}
