<div class="card card-booking-usages" *ngIf="booking && vehicle">

    <div class="card-booking-usages-header">

        <div class="avatar-placeholder-sm vehicle-placeholder">
            <img *ngIf="vehicleSrcImage | async as imageUrl" [src]="imageUrl" alt="Vehicle Image">
        </div>

        <div class="card-booking-usages-header-text">
            <div class="d-flex gap-2">
                <div class="card-booking-usages-header-text-title">{{ booking?.vehicle?.displayName }}</div>
                <div *ngIf="bookingUsage" [class]="bookingUsage?.statusClient?.cssClass">
                    {{bookingUsage?.statusClient?.text}}
                </div>
            </div>
            <div class="card-booking-usages-header-text-subtitle">{{ booking?.vehicle?.location?.name }}</div>
        </div>

    </div>


    <div class="card-booking-usages-body" *ngIf="vehicle">
        <div>
            {{ vehicle?.vehicleMake?.name }} {{ vehicle?.vehicleModel?.name }}
        </div>
        <div>
            {{ vehicle?.vehicleVersion?.name }}
        </div>
        <div>
            {{ vehicle?.vehicleType?.name }} • {{ vehicle?.fuelType?.name }}
        </div>
    </div>

    <div *ngIf="bookingUsage">
        <div class="card-booking-usages-items"
            [@expandCollapse]="bookingUsage ? 
            'expanded' : 'collapsed'">

            <div class="card-booking-usages-items-header">
                <div class="avatar-placeholder-xs person-placeholder">
                    <img *ngIf="userSrcImage | async as imageUrl" [src]="imageUrl" alt="User Image">
                </div>
                <div class="card-booking-usages-items-title">
                    <span>
                        {{ 'BOOKINGS.START_USAGE' | translate }}
                    </span>
                     {{ bookingUsage?.startDate | date: 'dd-MM-yyyy • HH:mm' }}
                </div>
            </div>
            <div class="mb-2">
                <span class="color-secondary">
                    {{ 'BOOKINGS.MILEAGE_START' | translate }}
                </span>
                <i class="bi bi-exclamation-triangle text-danger" *ngIf="bookingUsage?.startMileage === null"></i>
            </div>
            <button-insert *ngIf="bookingUsage?.startMileage === null && !bookingUsage?.endDate" (click)="openModalMileage()"></button-insert>
            <div class="d-flex justify-content-between">
                <div>{{ bookingUsage?.startMileage }}</div>
                <button class="btn-edit" *ngIf="bookingUsage?.id && bookingUsage?.startDate && bookingUsage?.startMileage !== null && !bookingUsage?.endDate" (click)="openModalMileage()">
                    {{ 'ACTIONS.EDIT' | translate }}
                </button>
            </div>
        </div>
    
    
        <div class="card-booking-usages-items"
            [@expandCollapse]="bookingUsage ? 
            'expanded' : 'collapsed'">

            <div class="card-booking-usages-items-header">
                <div class="avatar-placeholder-xs person-placeholder">
                    <img *ngIf="userSrcImage | async as imageUrl" [src]="imageUrl" alt="User Image">
                </div>
                <div class="card-booking-usages-items-title">
                    <span>
                        {{ 'BOOKINGS.END_USAGE' | translate }}
                    </span>
                     {{ bookingUsage?.endDate | date: 'dd-MM-yyyy • HH:mm' }}
                </div>
            </div>
            <div class="mb-2">
                <span class="color-secondary">
                    {{ 'BOOKINGS.MILEAGE_END' | translate }}
                </span>
                 <i class="bi bi-exclamation-triangle text-danger" *ngIf="!bookingUsage?.endMileage"></i>
            </div>
            <button-insert *ngIf="!bookingUsage?.endMileage && !bookingUsage?.endDate" (click)="openModalMileage()"></button-insert>
            
            <div class="d-flex justify-content-between" *ngIf="bookingUsage?.endMileage">
                <div>
                    {{ bookingUsage?.endMileage }}
                </div>
                <button class="btn-edit" *ngIf="bookingUsage?.id && bookingUsage?.endMileage && !bookingUsage?.endDate" (click)="openModalMileage()">
                    {{ 'ACTIONS.EDIT' | translate }}
                </button>
            </div>
        </div>

    </div>



    <div class="card-booking-usages-footer">

        <div class="card-booking-usages-footer-content" 
            [@expandCollapse]="isBookingActive && (canStartUsage$ | async) && !(hasReservation$ | async) ? 
            'expanded' : 'collapsed'">

            <div class="d-flex gap-1">
                <span>{{ 'BOOKINGS.COLLECT_BY' | translate }}</span>
                <div>{{ collectBy | date: 'dd-MM-yyyy • HH:mm' }}</div>
            </div>

            <button class="btn-gs-warning mt-2" type="button" (click)="createUsage()">
                {{ 'BUTTONS.CONFIRM_BOOKING' | translate }}
            </button>
        </div>

        <div class="card-booking-usages-footer-content" 
            [@expandCollapse]="isBookingActive && !(canStartUsage$ | async) && !(hasReservation$ | async) ? 
            'expanded' : 'collapsed'">

            <div class="d-flex gap-1">
                <span>{{ 'BOOKINGS.RETURN' | translate }}</span>
                <div>
                    {{ booking?.endDate | date: 'dd-MM-yyyy • HH:mm' }}
                </div>

            </div>

            <button class="btn-gs-warning mt-2" type="button" (click)="endUsageNew()"
                [ngClass]="{'button-animate' : waitClose}">

                {{ 'BUTTONS.END_USE' | translate }}
            </button>
        </div>

        <div class="card-booking-usages-footer-content" 
            [@expandCollapse]="booking?.status.split('_')[0] === 'CLOSED' && booking?.lastReturnedDate ? 
            'expanded' : 'collapsed'">

            <button class="btn-gs-secondary d-flex gap-1 align-items-center justify-content-center mt-2" type="button" [disabled]="true">    
                <i class="bi bi-hand-thumbs-up"></i>
                {{ 'BUTTONS.USE_END' | translate }}
            </button>
        </div>

    </div>


</div>
