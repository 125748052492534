<div class="card-actions-modal">
    <header>        
        <h4>{{'ACTIONS.NEW' | translate}}</h4>
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </header>

    <div *ngFor="let action of actions" class="action-item">
        <div class="action-item-left">
            <i [class]="action.icon"></i>
            <div class="action-item-content" (click)="action.action()">
                <div class="action-item-header">{{action.header | translate}}</div>
                <div class="action-item-description">{{action.description | translate}}</div>
            </div>
        </div>
        <i class="bi bi-chevron-right"></i>
    </div> 

</div>