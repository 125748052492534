import { Injectable } from '@angular/core';
import { TenantService } from '../api-services/tenant.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { UploadImportModalComponent } from "../components/modals/import-modal/upload-import-modal/upload-import-modal.component";
import { Import } from "../models/import";
import { BookingModalComponent } from "../components/modals/booking-modal/booking-modal/booking-modal.component";
import { TaskCreateModalComponent } from "../components/modals/task-modal/task-create-modal/task-create-modal.component";
import { Task } from "../models/task";
import { TasksService } from "../api-services/tasks.service";
import { TaskEditModalComponent } from "../components/modals/task-modal/task-edit-modal/task-edit-modal.component";
import { VehiclesModalComponent } from "../components/modals/vehicles-modal/vehicles-modal.component";
import { AssetsModalComponent } from "../components/modals/assets-modal/assets-modal.component";
import { Asset } from "../models/asset";
import { DatePipe } from "@angular/common";
import { Booking } from "../models/booking";
import { TypeImporters } from "../components/modals/import-modal/upload-import-modal/type-importers";
import { Observable, map, switchMap, of, tap, concatMap, throwError } from "rxjs";
import { HeadquarterModalComponent } from '../components/modals/headquarter-modal/headquarter-modal.component';
import { Headquarter, Location } from '../models/headquarters';
import { LocationModalComponent } from '../components/modals/location-modal/location-modal.component';
import { Tag } from '../models/tag';
import { TagModalComponent } from '../components/modals/tag-modal/tag-modal.component';
import { Make, Model } from '../models/vehicleInfos';
import { VersionModalComponent } from '../components/modals/version-modal/version-modal.component';
import { Vehicle } from '../models/vehicle';
import { authGuardModal } from 'src/app/core/auth.guard';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { PersonModalComponent } from '../components/modals/person-modal/person-modal.component';
import { UserModel } from '../models/users';
import { Me } from '../models/me';
import { FilterTaskModalComponent } from '../components/modals/filter-modal/filter-task-modal/filter-task-modal.component';
import { VersionMakeModelActionsModalComponent } from '../components/modals/version-make-model-actions-modal/version-make-model-actions-modal.component';
import { MakeModalComponent } from '../components/modals/make-modal/make-modal.component';
import { ModelModalComponent } from '../components/modals/model-modal/model-modal.component';
import { VehiclesService } from '../api-services/vehicles.service';
import { DownloadImportModalComponent } from '../components/modals/import-modal/download-import-modal/download-import-modal.component';
import { VehicleSuspensionProgramModalComponent } from '../components/modals/vehicles-modal/vehicle-suspension-program-modal/vehicle-suspension-program-modal.component';
import { FilterBookingModalComponent } from '../components/modals/filter-modal/filter-booking-modal/filter-booking-modal.component';
import { FilterAssetModalComponent } from '../components/modals/filter-modal/filter-asset-modal/filter-asset-modal.component';
import { FilterVehicleModalComponent } from '../components/modals/filter-modal/filter-vehicle-modal/filter-vehicle-modal.component';
import { FringeBenefitModalComponent } from '../components/modals/fringe-benefit-modal/fringe-benefit-modal.component';
import { ModalReportsFilterComponent } from 'src/app/pages/reports/modal-reports-filter/modal-reports-filter.component';
import { TaskFilter } from '../models/tasks/task-filter';
import { FilterVehicleUsagesModalComponent } from '../components/modals/filter-modal/filter-vehicle-usages-modal/filter-vehicle-usages-modal.component';
import { FilterCostModalComponent } from '../components/modals/filter-modal/filter-cost-modal/filter-cost-modal.component';
import { SuppliersService } from '../api-services/suppliers.service';
import { InvoicesService } from '../api-services/invoices.service';
import { SuppliersModalComponent } from '../components/modals/suppliers-modal/suppliers-modal.component';
import { SupplierFilter } from '../models/supplier/supplier-filter';
import { InvoiceFilter } from '../models/invoice/invoice-filter';
import { InvoicesModalComponent } from '../components/modals/invoices-modal/invoices-modal.component';
import { CabinetService } from '../api-services/cabinet.service';
import { Cabinet, CabinetKey } from '../models/cabinet/cabinet';
import { MappingCabinetKeyModalComponent } from '../components/modals/mapping-cabinet-key-modal/mapping-cabinet-key-modal.component';
import { CabinetFilter } from '../models/cabinet/cabinet-filter';
import { CabinetResponse } from '../models/cabinet/cabinet-response';
import { Version } from '../models/version/version';
@Injectable({
  providedIn: 'root'
})
export class ModalUtilsService {

  tenantId = this._tenatService.getTenantId();
  private modalImport: BsModalRef;

  private modalEditTask: BsModalRef;

  constructor(
    private _tenatService: TenantService,
    private _modalService: BsModalService,
    private _vehiclesService: VehiclesService,
    private _taskService: TasksService,
    private _cabinetsService: CabinetService,
    private _suppliersService: SuppliersService,
    private _invoicesService: InvoicesService,
    private _datePipe: DatePipe,
    private _authenticationService: AuthenticationService
  ) { }

  openPersonModal({ user, isUser = false, me }: { user?: UserModel; isUser?: boolean; me?: Me; }): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAutenticated => {
        if (isAutenticated) {
          return this._modalService.show(PersonModalComponent, {
            class: 'modal-md',
            initialState: {
              user : user ?? null,
              isUser,
              me : me ?? null,
            },
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openVehicleModal(vehicle?: Vehicle): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      switchMap(isAuthenticated => {
        if (isAuthenticated) {

          if (vehicle) {
            let params = {
              IncludeMake: true,
              IncludeModel: true,
              IncludeVersion: true,
              IncludeHeadquarter: true,
              IncludeLocation: true,
              IncludeVehicleType: true,
              IncludePurchaseType: true,
              IncludeTags: true,
            }
  
            return this._vehiclesService.getVehicleById$(this.tenantId, vehicle.id, params)
          } else {
            return of(null);
          }
        }
        throw new Error('User not authenticated');
      }),
      switchMap(vehicle => {        
        const modalRef = this._modalService.show(VehiclesModalComponent, {
          class: 'modal-md',
          initialState: {
            vehicle: vehicle ?? null,
          },
          backdrop: 'static',
          animated: true,
        });
        return of(modalRef);
      })
    );
  }

  openBookingModal(booking?: Booking, isFleetManager: boolean = false): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(BookingModalComponent, {
            initialState: {
              isFleetManager: isFleetManager,
              booking: booking ?? null,
            },
            backdrop: 'static',
            class: 'modal-lg',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }
  

  openCreateModalTask(hasTCO: boolean = false, extraInfo?: ModalExtraInformation) : Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      switchMap(isAuthenticated => {
        if (isAuthenticated) {
          return of(this._modalService.show(TaskCreateModalComponent, { 
            initialState: {
              booking: extraInfo?.booking ?? null,
              headquarterId: extraInfo?.booking?.headquarterId ?? null,
              hasTCO: hasTCO,
            },
            backdrop: 'static',
            class: 'modal-lg', 
            animated: true 
          }));
        }
        throw new Error('User not authenticated');
      }),
      map(modal => {
        modal.onHidden.subscribe(() => {
          if (modal.content.taskId) {
            this.openEditTaskModal(modal.content.taskId).subscribe();
          }
        });
        return modal;
      })
    );
  }

  openEditTaskModal(taskId: string): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      switchMap(isAuthenticated => {
        if (isAuthenticated) {
          let params : TaskFilter = {
            includeTaskType: true,
            includeHeadquarter: true,
            includeUser: true,
            includeVehicle: true,
            includeAsset: true,
            includeAssignedUser: true,
            includeStartUser: true,
            includeTaskWatchers: true,
          };
      
          return this._taskService.getTaskId$(this.tenantId, taskId, params).pipe(
            switchMap((response: Task) => {
              if (response) {
                response.expirationDate = this._datePipe.transform(response.expirationDate, 'dd-MM-yyyy')?.toString();
  
                let taskRequest = this._taskService.mapTaskToTaskRequest(response);
  
                this.modalEditTask = this._modalService.show(TaskEditModalComponent, {
                  initialState: {
                    task : response,
                    taskRequest,
                    dropdownId: `dropdown-state-task-${taskId}`,
                  },
                  backdrop: 'static',
                  class: 'modal-lg',
                  animated: true
                });
                return of(this.modalEditTask);
              }

              return of(null);
            })
          );
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openAssetModal(asset?: Asset) : Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(AssetsModalComponent, {
            initialState: {
              asset: asset ?? null
            },
            backdrop: 'static',
            class: 'modal-md',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openSupplierModal(supplierId?: string): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
        switchMap(isAuthenticated => {
            if (isAuthenticated) {
                if (supplierId) {
                  const params: SupplierFilter = {
                    includeTags: true,
                  }
                  return this._suppliersService.getSupplier$(this.tenantId, supplierId, params);
                } else {
                    return of(null);
                }
            }
            throw new Error('User not authenticated');
        }),
        switchMap(supplier => {
            const modalRef = this._modalService.show(SuppliersModalComponent, {
                class: 'modal-md',
                initialState: { supplier },
                backdrop: 'static',
                animated: true
            });
            return of(modalRef);
        })
    );
}

  openInvoiceModal(invoiceId?: string) : Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
        switchMap(isAuthenticated => {
            if (isAuthenticated) {
                if (invoiceId) {
                  const params: InvoiceFilter = {
                    includeTags: true,
                    includeSupplier: true,
                  }
                  return this._invoicesService.getInvoice$(this.tenantId, invoiceId, params);
                } else {
                  return of(null);
                }
            }
            throw new Error('User not authenticated');
        }),
        switchMap(invoice => {
            const modalRef = this._modalService.show(InvoicesModalComponent, {
                class: 'modal-lg',
                initialState: { invoice },
                backdrop: 'static',
                animated: true
            });
            return of(modalRef);
        })
    );
  }

  openCreateImportModal(typeImporters?: TypeImporters) : Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          this.modalImport = this._modalService.show(UploadImportModalComponent, {
            class: 'modal-md',
            initialState: {
              typeImporters: typeImporters ?? null
            },
            backdrop: 'static',
            animated: true,
          });
          let importt : Import;
          this.modalImport.onHide.subscribe(() => {
            importt = this.modalImport.content.import;
          });
          return this.modalImport;
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openDownloadTraceImportModal(typeImporters?: TypeImporters) : Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          let modal = this._modalService.show(DownloadImportModalComponent, {
            class: 'modal-md',
            initialState: {
              typeImporters: typeImporters ?? null
            },
            backdrop: 'static',
            animated: true,
          });
          return modal;
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openModalHeadquarter(headquarter?: Headquarter) : Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(HeadquarterModalComponent, {
            initialState: {
              headquarter: headquarter ?? null
            },
            class: 'modal-md',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openModalLocation(location?: Location) : Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(LocationModalComponent, {
            initialState: {
              location: location ?? null
            },
            class: 'modal-md',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openModalTag(tag?: Tag): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(TagModalComponent, {
            initialState: {
              tag: tag ?? null
            },
            class: 'modal-md',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openModalMakeModelVersionActions(): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(VersionMakeModelActionsModalComponent, {
            class: 'modal-md',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openModalVehicleVersion(version?: Version): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(VersionModalComponent, {
            initialState: {
              version: version ?? null
            },
            class: 'modal-md',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openModalMake(make?: Make): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(MakeModalComponent, {
            initialState: {
              make: make ?? null
            },
            class: 'modal-md',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openModalModel(model?: Model): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(ModelModalComponent, {
            initialState: {
              model: model ?? null
            },
            class: 'modal-md',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openModalFilterTask(id: string) : Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(FilterTaskModalComponent, {
            initialState: {
              id
            },
            class: 'modal-sm',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openModalFilterCost(id: string) : Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(FilterCostModalComponent, {
            initialState: {
              id
            },
            class: 'modal-sm',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openModalFilterBooking() : Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(FilterBookingModalComponent, {
            class: 'modal-sm',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openModalFilterAsset() : Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(FilterAssetModalComponent, {
            class: 'modal-sm',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openModalFilterVehicle() : Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(FilterVehicleModalComponent, {
            class: 'modal-sm',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openModalFilterVehicleUsage() : Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(FilterVehicleUsagesModalComponent, {
            class: 'modal-sm',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openModalVehicleSuspesionProgram(vehicle?: Vehicle): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (isAuthenticated) {
          return this._modalService.show(VehicleSuspensionProgramModalComponent, {
            initialState: {
              vehicle: vehicle ?? null
            },
            class: 'modal-md',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openModalFringeBenefit(bookingId: string): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {
        if (bookingId === null) throw new Error('bookingId is null');

        if (isAuthenticated) {
          return this._modalService.show(FringeBenefitModalComponent, {
            initialState: {
              bookingId,
            },
            class: 'modal-md',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openModalFilterReports(id: string): Observable<BsModalRef> {
    return authGuardModal(this._authenticationService).pipe(
      map(isAuthenticated => {

        if (isAuthenticated) {
          return this._modalService.show(ModalReportsFilterComponent, {
            initialState: {
              id
            },
            class: 'modal-sm',
            backdrop: 'static',
            animated: true,
          });
        }
        throw new Error('User not authenticated');
      })
    );
  }

  openCabinetKeyModal(id: string): Observable<void> {
    return authGuardModal(this._authenticationService).pipe(
      switchMap(isAuthenticated => {
        if (isAuthenticated) {      
          return this._cabinetsService.getCabinetKey$(this.tenantId, id);
        }
        throw new Error('User not authenticated');
      }),
      concatMap((cabinetKey: CabinetKey) => {
        if (cabinetKey) {   
          let cabinetFilter : CabinetFilter = {
            driverCabinetId: cabinetKey.driverCabinetId,
            limit: 1,
          };       
          return this._cabinetsService.listCabinets$(this.tenantId, cabinetFilter).pipe(
            map((cabinetResponse: CabinetResponse) => {
              this._modalService.show(MappingCabinetKeyModalComponent, {
                initialState: {
                  cabinetKey: cabinetKey,
                  cabinet: cabinetResponse.items[0],
                },
                class: 'modal-md',
                backdrop: 'static',
                animated: true,
              });
              return;
            })
          );
        }
        return of(null);
      })
    );
  }
}

export interface ModalExtraInformation {
  booking?: Booking;
}
