<div class="filters mb-3">
    <div class="filters-section-left btn-group">
        <h3 class="table-title">{{ titleTable | translate}}</h3>
    </div>

    <div class="filters-section-right">
        <form [formGroup]="filterForm">
            <div class="input-group text-black-50 d-flex align-items-center border rounded">

              <input type="text" class="from-date form-control border-0" 
                [placeholder]="'START_DATE' | translate" 
                bsDatepicker 
                formControlName="startDate" 
                inputmode='none'
                [bsConfig]="{ 
                    withTimepicker: true,
                    keepDatepickerOpened: true,
                    dateInputFormat: 'DD-MM-YYYY - h:mm a', 
                    isAnimated: true, 
                    adaptivePosition: true 
                  }">
              <i class="bi bi-arrow-right"></i>

              <input type="text" class="to-date form-control border-0" 
                [placeholder]="'END_DATE' | translate" 
                bsDatepicker 
                formControlName="endDate" 
                [bsConfig]="{ 
                    withTimepicker: true,
                    keepDatepickerOpened: true,
                    dateInputFormat: 'DD-MM-YYYY - h:mm a', 
                    isAnimated: true, 
                    adaptivePosition: true 
                  }">
              <i class="bi bi-calendar-check"></i>
            </div>
        </form>

      <button-first-type 
          [cssClass]="'btn-gs-secondary-icon'"
          [iconClass]="'bi bi-funnel'" 
          [buttonText]= "'FILTERR'"
          (click)="onFilterClick()">
      </button-first-type>
      
      <button-first-type 
          [cssClass]="'btn-gs-secondary-icon'"
          [iconClass]="'bi bi-download'" 
          [buttonText]= "'EXPORT' | translate"
          (click)="onClickExport()">
      </button-first-type>
    </div>  
</div>