import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Task, TaskResp, TaskFiles, TaskTypeResp, TaskFilesResp, TaskCommentResp, TaskDataResp, TaskFieldsResp, TaskRequest, TaskComment, TaskCommentRequest, TaskFileRequest } from '../models/task';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { Observable, Subject, catchError, map, shareReplay } from 'rxjs';
import { TaskCost, TaskInvoice } from '../models/task-cost';
import { MappingModel } from '../models/users';
import { DefaultQueryParams } from '../models/common';
import { CommonService } from './common.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { TaskDataFilter, TaskFilter, TaskInvoicesFilter } from '../models/tasks/task-filter';
import { TaskInvoiceResponse } from '../models/tasks/task-response';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  private task$ = new Subject<Task>();

  constructor(
    private _http: HttpClient,
    private _commonService: CommonService,
    private _translateService: TranslateService,
    private _toastrService: ToastrService,
  ) { }

  listTaskTypes$(tenant: string, params?: any): Observable<TaskTypeResp> {
    return this._http.get<TaskTypeResp>(`${VECTORE_API_BASE_URL}/${tenant}/task-types`, 
    { params: this._commonService.buildParams(params) });
  }

  getTaskFields$(tenant: string, query?: { defaultQueryParams?: DefaultQueryParams, taskTypeId?: string }): Observable<TaskFieldsResp> {
    let params = new HttpParams()
    if (query) {
      params = this.setDefaultParams(params, query.defaultQueryParams);
      if (query.taskTypeId) params = params.set('taskTypeId', query.taskTypeId);
    }

    return this._http.get<TaskFieldsResp>(`${VECTORE_API_BASE_URL}/${tenant}/task-fields`, { params });
  }

  createTask$(tenant: string, task: TaskRequest, showConfirmation: boolean = true): Observable<Task> {
    return this._http.post<Task>(`${VECTORE_API_BASE_URL}/${tenant}/tasks`, task,)
    .pipe(
      map(response => {
        if (response.id && showConfirmation) {
          this._toastrService.success(this._translateService.instant('TASKS.MESSAGE.SUCCESS_CREATE_TASK'))
        }
        return response;
      }),
      catchError(error => this._commonService.handleError(error))
    );
  };

  listTasks$(tenant: string, query?: TaskFilter): Observable<TaskResp> {
    return this._http.get<TaskResp>(`${VECTORE_API_BASE_URL}/${tenant}/tasks`, { params: this._commonService.buildParams(query) })
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

  getTaskId$(tenant: string, taskId: string, query?: TaskFilter): Observable<Task> {
    return this._http.get<Task>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}`, { params: this._commonService.buildParams(query) })
    .pipe(
      catchError(error => this._commonService.handleErrorForGet(error)),
      shareReplay(),
    );
  }

  sendEvent(data: Task) {
    this.task$.next(data);
  }

  getEvent() {
    return this.task$.asObservable();
  }

  patchTasks$(tenant: string, taskId: string, taskRequest: TaskRequest): Observable<Task> {
    return this._http.patch<Task>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}`,
      taskRequest,
    ).pipe(
      map(response => {
        if (response?.id) {
          this._toastrService.success(this._translateService.instant('TASKS.MESSAGE.SUCCESS_EDIT_TASK'));
        }
        return response;
      }),
      catchError(error => this._commonService.handleError(error))
    );
  }

  deleteTask$(tenant: string, taskId: string): Observable<HttpResponse<Object>> {
    return this._http.delete(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}`,
    { observe: 'response' })
    .pipe(
      map(response => {
        if (response.status === 204) {
          this._toastrService.info(this._translateService.instant('TASKS.MESSAGE.SUCCESS_DELETE_TASK'));
        }
        return response;
      }),
      catchError(error => this._commonService.handleError(error))
    );
  }

  openTask$(tenant: string, taskId: string): Observable<Task> {
    return this._http.post<Task>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}/open`, {})
    .pipe(
      map(response => {
        if (response.id) {
          this._toastrService.success(this._translateService.instant('TASKS.MESSAGE.SUCCESS_REOPEN_TASK'));
        }
        return response;
      }),
      catchError(error => this._commonService.handleError(error))
    );
  }

  closeTask$(tenant: string, taskId: string): Observable<Task> {
    return this._http.post<Task>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}/close`, {})
    .pipe(
      map(response => {
        if (response.id) {
          this._toastrService.success(this._translateService.instant('TASKS.MESSAGE.SUCCESS_DONE_TASK'));
        }
        return response;
      }),
      catchError(error => this._commonService.handleError(error))
    );
  }

  updateTaskCost$(tenant: string, taskId: string, taskCost: TaskCost): Observable<TaskCost> {
    return this._http.post<TaskCost>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}/update-task-cost`, taskCost)
    .pipe(
      map(response => {
        if (response) {
          this._toastrService.success(this._translateService.instant('COSTS.MESSAGES.UPDATE_SUCCESS'));;
        }
        return response;
      })
    );
  }

  unDeleteTask$(tenantId: string, taskId: string): Observable<Task> {
    return this._http.post<Task>(`${VECTORE_API_BASE_URL}/${tenantId}/tasks/${taskId}/undelete`, {})
    .pipe(
      map(response => {
        if (response.id) {
          this._toastrService.info(this._translateService.instant('TASKS.MESSAGE.SUCCESS_UNDELETE_TASK'))
        }
        return response
      }),
      catchError(error => this._commonService.handleError(error))
    )
  }

  exportTasks$(tenant: string, exportData: MappingModel, query?: TaskFilter): Observable<HttpResponse<Blob>> {
    return this._http.post(`${VECTORE_API_BASE_URL}/${tenant}/export/tasks`, exportData, {
      observe: 'response',
      responseType: 'blob',
      params: this._commonService.buildParams(query) 
    })
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

  createCommentTask$(tenant: string, taskId: string, comment: TaskCommentRequest): Observable<HttpResponse<TaskComment>> {
    return this._http.post<TaskComment>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}/comments`,
    comment,
    { observe: 'response', })
    .pipe(
      map(response => {
        if (response.status === 201) {
          this._toastrService.success(this._translateService.instant('TASKS.MESSAGE.SUCCESS_ADD_COMMENT'));
        }
        return response;
      }),
      catchError(error => this._commonService.handleError(error))
    );
  }

  listCommentsTask$(tenant: string, taskId: string, query?: { defaultQueryParams?: DefaultQueryParams, userId?: string, includeUser?: boolean }): Observable<TaskCommentResp> {
    let params = new HttpParams();

    if (query) {
      params = this.setDefaultParams(params, query.defaultQueryParams);
      if (query.userId) params = params.set('userId', query.userId);
      if (query.includeUser) params = params.set('includeUser', query.includeUser.toString());
    }

    return this._http.get<TaskCommentResp>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}/comments`, { params })
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

  getCommentTaskId$(tenant: string, taskId: string, commentId: string): Observable<TaskCommentResp> {
    return this._http.get<TaskCommentResp>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}/comments/${commentId}`,);
  }

  deleteCommentTask(tenant: string, taskId: string, commentId: string): Observable<HttpResponse<Object>> {
    return this._http.delete(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}/comments/${commentId}`, { observe: 'response' })
    .pipe(
      map(response => {
        if (response.status === 204) {
          this._toastrService.success(this._translateService.instant('TASKS.MESSAGE.SUCCESS_DELETE_COMMENT'));
        }
        return response;
      })
    );
  }

  linkTaskFile$(tenant: string, taskId: string, taskFileRequest: TaskFileRequest): Observable<HttpResponse<TaskFiles>> {
    return this._http.post<TaskFiles>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}/files`, taskFileRequest, 
    { observe: 'response', })
    .pipe(
      shareReplay(1),
      map(response => {
        if (response.status === 200) {
          this._toastrService.success(this._translateService.instant('TASKS.MESSAGE.SUCCESS_LINK_FILE_TASK'));
        }
        return response;
      }),
      catchError(error => this._commonService.handleError(error))
    );
  }

  getTaskFiles$(tenant: string, taskId: string, defaultQueryParams?: DefaultQueryParams): Observable<TaskFilesResp> {
    let params = new HttpParams();
    params = this.setDefaultParams(params, defaultQueryParams);
    return this._http.get<TaskFilesResp>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}/files`, { params })
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

  getTaskFileId$(tenant: string, taskId: string, fileId: string): Observable<TaskFiles> {
    return this._http.get<TaskFiles>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}/files/${fileId}`,);
  }

  updateTaskFile$(tenant: string, taskId: string, fileId: string, nameFile: string): Observable<TaskFiles> {
    return this._http.patch<TaskFiles>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}/files/${fileId}`, nameFile,);
  }

  deleteTaskFile$(tenant: string, taskId: string, fileId: string): Observable<HttpResponse<Object>> {
    return this._http.delete(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}/files/${fileId}`, { observe: 'response' })
    .pipe(
      shareReplay(1),
      map(response => {
        if (response.status === 204) {
          this._toastrService.success(this._translateService.instant('TASKS.MESSAGE.SUCCESS_REMOVE_FILE_TASK'));
        }
        return response;
      }),
      catchError(error => this._commonService.handleError(error))
    );
  }

  downloadTaskFile$(tenant: string, taskId: string, fileId: string): Observable<HttpResponse<any>> {
    return this._http.get(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}/files/${fileId}/content`, 
    { observe: 'response', responseType: 'blob' })
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

  createTaskInvoice$(tenant: string, taskInvoice: TaskInvoice): Observable<TaskInvoice> {
    return this._http.post<TaskInvoice>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/invoices`, taskInvoice)
    .pipe(
      map(response => {
        if (response.id) {
          this._toastrService.success(this._translateService.instant('TASKS.MESSAGE.SUCCESS_LINK_INVOICE_TASK'));
        }
        return response;
      }),
      catchError(error => this._commonService.handleError(error))
    );
  }

  listTaskInvoices$(tenant: string, query?: TaskInvoicesFilter): Observable<TaskInvoiceResponse> {
    return this._http.get<TaskInvoiceResponse>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/invoices`, { params: this._commonService.buildParams(query) });
  }

  getTaskInvoiceId$(tenant: string, taskInvoiceId: string): Observable<TaskInvoice> {
    return this._http.get<TaskInvoice>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/invoices/${taskInvoiceId}`,);
  }

  deleteTaskInvoice$(tenant: string, taskInvoiceId: string): Observable<HttpResponse<Object>> {
    return this._http.delete(`${VECTORE_API_BASE_URL}/${tenant}/tasks/invoices/${taskInvoiceId}`, { observe: 'response' })
    .pipe(
      map(response => {
        if (response.status === 204) {
          this._toastrService.success(this._translateService.instant('TASKS.MESSAGE.SUCCESS_UNLINK_INVOICE_TASK'));
        }
        return response;
      })
    );
  }

  getTaskData$(tenant: string, params?: TaskDataFilter): Observable<TaskDataResp> {
    return this._http.get<TaskDataResp>(`${VECTORE_API_BASE_URL}/${tenant}/task-data`, { params: this._commonService.buildParams(params) });
  }

  setDefaultParams(params: HttpParams, defaultQueryParams: DefaultQueryParams) {
    if (!defaultQueryParams) {
      return params;
    }

    if (defaultQueryParams.start) params = params.set('start', defaultQueryParams.start.toString());
    if (defaultQueryParams.limit) params = params.set('limit', defaultQueryParams.limit.toString());
    if (defaultQueryParams.orderBy) params = params.set('orderBy', defaultQueryParams.orderBy);

    return params;
  }

  getTaskCostData$(
    tenant: string, params?: any): Observable<TaskDataResp> {
    return this._http.get<TaskDataResp>(`${VECTORE_API_BASE_URL}/${tenant}/task-data`, { params: this._commonService.buildParams(params) });
  }

  getTaskCostList(tenant: string, params?: any): Observable<any> {
    return this._http.get<TaskResp>(`${VECTORE_API_BASE_URL}/${tenant}/tasks`, { params: this._commonService.buildParams(params) });
  }

  mapTaskToTaskRequest(task: Task): TaskRequest {
    return {
       taskTypeId: task.taskTypeId ?? null,
       code: task.code ?? null,
       headquarterId: task.headquarterId ?? null,
       vehicleId: task.vehicleId ?? null,
       userId: task.userId ?? null,
       assetId: task.assetId ?? null,
       title: task.title ?? null,
       description: task.description ?? null,
       expirationMileage: task.expirationMileage ?? null,
       expirationDate: task.expirationDate ?? null,
       assignedUserId: task.assignedUserId ?? null,
       extraFields: { ...task.extraFields } ?? null,
       tagIds: task.tagIds ?? null,
       assetUsageStartDate: task.usageStartDate ?? null,
       assetUsageEndDate: task.usageEndDate ?? null,
       usageStartDate: task.usageStartDate ?? null,
       usageEndDate: task.usageEndDate ?? null,
       bookingId: task.bookingId ?? null,
       taskWatcherIds: task.taskWatcherIds ?? null,
    };
  }
}

