<form class="form-comments" [formGroup]="commentsForm">
    <textarea class="form-control" placeholder="{{'TASKS.MODAL_EDIT_TASK.WRITE_SOMETING' | translate}}" id="floatingTextarea"
        formControlName="comment"></textarea>

    <div class="container-submit-comments">
        <button class="btn-gs-primary" type="submit" 
            [disabled]="!commentsForm.valid" (click)="submitComment()">

            {{'ACTIONS.ADD_COMMENT' | translate}}
        </button>
    </div>

</form>

<div class="container-comments">
    <div class="comment" *ngFor="let comment of comments">

        <div class="comment-header">
            <div class="comment-header-left-avatar">
                <div class="avatar-placeholder-sm person-placeholder">
                    <img *ngIf="comment.srcImage | async as imageUrl" [src]="imageUrl" alt="User Image">
                </div>
            </div>
        </div>

        <div class="comment-body">
            
            <div class="comment-body-header">
                {{comment.displayName}}
            </div>

            <div class="comment-body-content">
                <p>{{comment.comment}}</p>
                <div class="comment-time">{{comment.date | date: 'dd-MM-yyyy HH.mm'}}</div>                                            
            </div>

        </div>

    </div>
</div>