<div class="dropdown d-flex align-items-center ">
    <button type="button" 
        [class]="'d-flex align-items-center ' + cssClass"
        [ngClass]="{'gap-2' : iconClass !== ''}" 
        [disabled]="isDisabled"
        id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
        
        <div>{{nameButton | translate}}</div>
        <i [class]="iconClass"></i>
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li *ngFor="let option of options;" role="menuitem" >
            <a class="dropdown-item" (click)="selectOption(option)">{{option.text}}</a>
        </li>
    </ul>
</div>