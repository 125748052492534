import { HttpClient } from '@angular/common/http';
import * as RoutesUrl from 'src/app/shared/constant/app-routes';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, shareReplay, tap, throwError, timeout } from 'rxjs';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { SignupForm } from '../models/signup-form';
import { TokenResp } from '../models/tokenResp';
import { IdpResponse } from '../models/idbResponse';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SentryUtilsService } from '../utilities/sentry-utils.servic';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private idpResponse$: Observable<IdpResponse> | null = null;
  private lastFetchTime: number | null = null;
  private idpCacheTimeout = 300000;
  
  constructor(
    private _httpClient: HttpClient,
    private _router: Router,
    private _toastrService: ToastrService,
    private _sentryUtilsService: SentryUtilsService
  ) { }

  getIdp$(tenantId: string): Observable<IdpResponse> {
    const currentTime = Date.now();

    if (this.idpResponse$ && this.lastFetchTime && (currentTime - this.lastFetchTime < this.idpCacheTimeout)) {
      return this.idpResponse$;
    }

    return this.fetchIdpResponse(tenantId, currentTime);
  }

  private fetchIdpResponse(tenantId: string, currentTime: number): Observable<IdpResponse> {
    const timeoutDuration = 3000;
    return this._httpClient.get<IdpResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/idp`).pipe(
      timeout(timeoutDuration),
      tap(idpResponse => this.cacheIdpResponse(idpResponse, currentTime)),
      catchError(error => this.handleError(error)),
      shareReplay(1)
    );
  }

  private cacheIdpResponse(idpResponse: IdpResponse, currentTime: number): void {
    this.idpResponse$ = of(idpResponse);
    this.lastFetchTime = currentTime;
  }

  private handleError(error: any): Observable<never> {
    if (error.name === 'TimeoutError' || error.status === 0 || error.status === 404 || error.status === 500) {
      this._router.navigate([RoutesUrl.NO_SERVICE]);
    }
    this._toastrService.error(error.error.detail, error.error.title);
    this._sentryUtilsService.sendEventErrorForSentry(error);
    return throwError(() => new Error('Error during fetching IDP'));
  }
  
  getToken$(signupData: SignupForm, tenantId: string): Observable<TokenResp> {
    return this._httpClient.post<TokenResp>(`${VECTORE_API_BASE_URL}/${tenantId}/token`, signupData)
    .pipe(
      shareReplay(1)
    );
  }
  
  getRefreshToken$(refreshToken: string, tenantId: string): Observable<TokenResp> {    
    return this._httpClient.post<TokenResp>(`${VECTORE_API_BASE_URL}/${tenantId}/refresh-token`, { refreshToken })
    .pipe(
      shareReplay(1)
    );
  }  
}  
  