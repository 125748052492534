import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'button-insert',
  templateUrl: './button-insert.component.html',
  styleUrls: ['./button-insert.component.scss']
})
export class ButtonInsertComponent {

}
