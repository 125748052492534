import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {

  @Input() content: string;

  @Output() onConfirm = new EventEmitter<boolean>();

  constructor(public confirmModal: BsModalRef) {}

  emitTrue() {
    this.onConfirm.emit(true);
    this.confirmModal.hide();
  }
}