import { Component, Input } from '@angular/core';

@Component({
  selector: 'badge-booking',
  templateUrl: './badge-booking.component.html',
  styleUrls: ['./badge-booking.component.scss']
})
export class BadgeBookingComponent {
  @Input() number : number;
  @Input() text : string;
  output : string;

  ngOnInit(): void {
    if (this.number === 1) {
      this.output = 'Best';      
    }
  }
}
