<button class="btn-add-user-primary" id="btn-add-user-primary" *ngIf="(user | async) && !isEditMode">

    <div class="d-flex gap-2 align-items-center">
        <div class="avatar-placeholder-sm person-placeholder">
            <img *ngIf="userSrcImage | async as imageUrl" [src]="imageUrl" alt="User Image">
        </div>
    
        <div class="btn-title">{{ (user | async)?.displayName }}</div>
    </div>

    <span class="btn-remove" type="button" (click)="onDelete()">{{'ACTIONS.REMOVE' | translate}}</span>
</button>

<button class="btn-add-user-primary-new" id="btn-add-user-primary" *ngIf="!(user | async) && !isEditMode" (click)="onAddUser()">

    <i class="bi bi-plus"></i>

    <div>{{text | translate}}</div>
</button>

<form class="h-100" [formGroup]="form" *ngIf="isEditMode">
    <input type="text" class="form-control h-100" id="userId" [placeholder]="'TYPE_TO_SEARCH' | translate"
        formControlName="assignedUserName"
        [isAnimated]="true"
        [typeahead]="assignedUserName$"
        [typeaheadAsync]="true"
        [typeaheadMinLength]="0"
        (typeaheadOnSelect)="onSelectUser($event)">
</form>