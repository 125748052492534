import * as Sentry from "@sentry/angular-ivy";
import * as RoutesUrl from 'src/app/shared/constant/app-routes';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { CommunicationService } from 'src/app/shared/utilities/comunication.service';
import { DropdownEventsService } from 'src/app/shared/utilities/dropdown-events.service';
import { TableColumn } from '../dynamic-table/dynamic-table-desktop/table-column.interface';
import { TableRow } from '../dynamic-table/dynamic-table-desktop/table-row.interface';
import { FiltersPage } from 'src/app/shared/models/filter-page';
import { DropdownOption } from '../../dropdown/dropdown-primary/dropdown.interface';
import { FormGroup } from '@angular/forms';
import { MappingModel } from "src/app/shared/models/users";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ModalUtilsService } from "src/app/shared/utilities/modal-utils.service";
import { FilterModalService } from "../../modals/filter-modal/filter-modal.service";
import { ConfirmModalComponent } from "../../modals/confirm-modal/confirm-modal.component";
import { SupplierClient } from "src/app/shared/models/supplier/supplier";
import { InvoiceFilter } from "src/app/shared/models/invoice/invoice-filter";
import { InvoicesService } from "src/app/shared/api-services/invoices.service";
import { Invoice, InvoiceClient } from "src/app/shared/models/invoice/invoice";
import { DatePipe } from "@angular/common";

@Component({
  selector: 'invoice-table',
  templateUrl: './invoice-table.component.html',
  styleUrls: ['./invoice-table.component.scss']
})
export class InvoiceTableComponent implements OnInit, OnDestroy {
  tenantId = this._tenantService.getTenantId();

  @Input() titleTable: string;

  @Input() invoiceFilter: InvoiceFilter = {};
  
  tableColumns: TableColumn[];
  tableRows: TableRow[];
  rowIds: string[] = [];

  filterHeader: string;
  filterTask: DropdownOption;

  fitersTable: FormGroup;
  expirationStartDate: string;
  expirationEndDate: string;

  filtersPage: FiltersPage = {
    firstElement: 0,
    page: 1,
    itemsPerPage: 10,
    listRowToShowPerPage: [5, 10, 20, 30, 50, 100],
    totalRows: 0,
    totalPage: 1,
    orderBy: null,
    maxPagesToShowPagination: 6
  };

  isMobile = false;
  inputFilter: string;

  confirmModal: BsModalRef;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _tenantService: TenantService,
    private _invoicesService: InvoicesService,
    private _filterModalService: FilterModalService,
    private _translateService: TranslateService,
    private _dropdownEventsService: DropdownEventsService,
    private _toastrService: ToastrService,
    private _breakpointObserver: BreakpointObserver,
    private _router: Router,
    private _activeRoute: ActivatedRoute,
    private _cdr: ChangeDetectorRef,
    private _communicationService: CommunicationService,
    private _modaltUtilsService: ModalUtilsService,
    private _modalService: BsModalService,
    private _datePipe: DatePipe,
    public bsModalRef: BsModalRef,
  ) { 
    this._breakpointObserver
      .observe([Breakpoints.Handset])
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        this.isMobile = result.matches;
      });

    this.filtersPage.page = 1;
    this.filtersPage.firstElement = 0;
    this.filtersPage.orderBy = '-Id';
  }

  ngOnInit(): void {
    this.isMobile? this.filtersPage.maxPagesToShowPagination = 3 : this.filtersPage.maxPagesToShowPagination = 6;
    this.applyFilterToTable();
    this.getTable();

    this._communicationService.getEvent()
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: () => {
        this.getInvoices();
      },
      error: (error) => {
        Sentry.captureEvent(error);
      }
    });
  }

  getTable() {
    this.getInvoices();

    this.tableColumns = [];
    if (!this.isMobile) {
      this.tableColumns = [
        {
          field: 'invoiceNumber', 
          isSortable: true,
          header: "INVOICES.invoiceNumber",
        },
        {
          field: 'supplier.name', 
          isSortable: true,
          header: "SUPPLIER",
          value: (supplier : Invoice) => supplier.supplier?.name || ''
        },
        {
          field: 'supplier.vatNumber', 
          isSortable: true,
          header: "SUPPLIERS.vatNumber",
          value: (supplier : Invoice) => supplier.supplier?.vatNumber || ''
        },
        {
          field: 'invoiceDate', 
          isSortable: true,
          header: "INVOICES.invoiceDate",
        },
        {
          field: 'netAmount', 
          isSortable: true,
          header: "INVOICES.netAmount",
          value: (supplier : Invoice) => supplier.netAmount || ''
        },
        {
          field: 'grossAmount', 
          isSortable: true,
          header: "INVOICES.grossAmount",
          value: (supplier : Invoice) => supplier.grossAmount || ''
        },
        
        {
          field: 'actionsDynamic', 
          isSortable: false, 
          header: "ACTIONS.NAME",
          dotOptions: (supplier : SupplierClient) => supplier.actions
        }
      ];
    } else {
      this.tableColumns = [
        {
          field: 'invoiceNumber', 
          isSortable: true,
          header: "INVOICES.invoiceNumber",
        },
        {
          field: 'supplier.name', 
          isSortable: true,
          header: "SUPPLIER",
          value: (supplier : Invoice) => supplier.supplier.name || ''
        },
        {
          field: 'invoiceDate', 
          isSortable: true,
          header: "INVOICES.invoiceDate",
        },
        {
          field: 'netAmount', 
          isSortable: true,
          header: "INVOICES.netAmount",
          value: (supplier : Invoice) => supplier.netAmount || ''
        },
        {
          field: 'grossAmount', 
          isSortable: true,
          header: "INVOICES.grossAmount",
          value: (supplier : Invoice) => supplier.grossAmount || ''
        },
        
        {
          field: 'actionsDynamic', 
          isSortable: false, 
          header: "ACTIONS.NAME",
          dotOptions: (supplier : SupplierClient) => supplier.actions
        }
      ];
    }
    this._cdr.detectChanges();
  }

  getInvoices() {
    this.rowIds.forEach(id => this._dropdownEventsService.removeSubscriptions(id));

    let params : InvoiceFilter = {
      start: this.filtersPage.firstElement,
      limit: this.filtersPage.itemsPerPage,
      orderBy: this.filtersPage.orderBy,
      includeSupplier: true,
      includeTags: true,
      includeDeleted: false,
    }

    this.applyFilter(params);

    this._invoicesService.listInvoices$(this.tenantId, params)
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (response) => {
        this.rowIds = [];

        response.items.forEach((task) => {
          task.invoiceDate = this._datePipe.transform(task.invoiceDate, 'dd-MM-yyyy')?.toString();
        });

        let invoicesClient : InvoiceClient[] = [];
        response.items.forEach(invoice => {
          this.rowIds.push(invoice.id);
          let s : InvoiceClient = invoice;
          s.actions = [
            { text: this._translateService.instant("ACTIONS.EDIT"), value: 'edit'},
            { text: this._translateService.instant("ACTIONS.DELETE"), value: 'delete'}
          ];
          invoicesClient.push(s);
        });

        this.tableRows = [];
        this.tableRows = invoicesClient;

        this.onActionTable();

        this.filtersPage.totalRows = response.count;
        this.filtersPage.totalPage = Math.ceil(this.filtersPage.totalRows / this.filtersPage.itemsPerPage);
      },
      error: error => {
        Sentry.captureEvent(error);
      }
    });
  }

  private applyFilter(params: InvoiceFilter) {
    if (this.fitersTable) {
      if (this.fitersTable.value.headerToFilter && this.fitersTable.value.headerToFilter === 'invoiceNumber') {
        this.inputFilter = this.fitersTable.value.inputFilter;
        params.invoiceNumberContains = this.inputFilter;
      }
    }
  }

  getDataForm($event: FormGroup) {
    this.fitersTable = $event;
    this.getInvoices();
  }

  onActionTable() {    
    this.rowIds.forEach((rowId) => {

      this._dropdownEventsService.getSelectedOptionOnClick(rowId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (option) => {

          if (option && option.value === 'edit') {
            this._modaltUtilsService.openInvoiceModal(rowId)
            .pipe(takeUntil(this.destroy$))
            .subscribe();
          }

          if (option && option.value === 'delete') {
            this._invoicesService.deleteInvoice$(this.tenantId, rowId)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
              next: (response) => {
                if (response.status == 204) {
                  this.getInvoices();
                  this._toastrService.success(this._translateService.instant('INVOICES.MESSAGES.INVOICE_DELETED'));
                }
              },
              error: (error) => {
                Sentry.captureEvent(error);
                this._toastrService.error(error.error.detail, error.error.title);
              }
            });
          }

          if (option && option.value == 'detail') {
            this._router.navigate([`${RoutesUrl.VEHICLES_DETAIL}/${rowId}`], { relativeTo: this._activeRoute });
          }
        }
      );
    });
  }

  onMassiveSelections($event: TableRow[]) {
    this.tableRows = $event;
  }

  onMassiveAction($event: string) {
    if ($event === 'delete_selected') {
      let elementsSelected = this.tableRows.filter(element => element.isSelected);
      this.confirmModal = this._modalService.show(ConfirmModalComponent, {
        class: 'modal-sm',
        animated: true,
        initialState: {
          content: this._translateService.instant('MODAL_DELETE_CONFIRM.MESSAGE', {x: elementsSelected.length}),
        }
      });

      this.confirmModal.content.onConfirm.pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
          for (let index = 0; index < elementsSelected.length; index++) {
            this._invoicesService.deleteInvoice$(this.tenantId, elementsSelected[index].id)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
              next: (response) => {
                if (response.status == 204) {
                  if (index === elementsSelected.length - 1) {
                    this.getInvoices();
                  }
                  this._toastrService.success(this._translateService.instant('INVOICES.MESSAGES.INVOICE_DELETED'));
                }
              }
            });
          }
      });
    }
  }

  onSortColumnHeader(columnField: string) {
    this.filtersPage.orderBy = columnField;
    this.getInvoices();
  }

  onPageChange($newPage: number) {
    this.filtersPage.page = $newPage;
    this.filtersPage.firstElement = ($newPage - 1) * this.filtersPage.itemsPerPage;
    this.getInvoices();
  }

  onPageSizeChange(newSize: number): void {
    this.filtersPage.itemsPerPage = newSize;
    this.filtersPage.page = 1;
    this.filtersPage.firstElement = 0;
    this.filtersPage.orderBy = null;
    this.getInvoices();    
  }

  private applyFilterToTable() {
    this._filterModalService.getForm$('filter-supplier-modal')
    .pipe(takeUntil(this.destroy$))
    .subscribe(form => {
      if (form) {
        this.getInvoices();
      }
    });
  }

  getExport() {
    this._invoicesService.listInvoiceFields$(this.tenantId)
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (response) => {
        let mappings: MappingModel = {
          mappings: [
            { sourceName: 'Code', destName: this._translateService.instant('Code') },
            { sourceName: 'Supplier.Name', destName: this._translateService.instant('Supplier') },
            { sourceName: 'InvoiceNumber', destName: this._translateService.instant('InvoiceNumber') },
            { sourceName: 'InvoiceDate', destName: this._translateService.instant('InvoiceDate') },
            { sourceName: 'NetAmount', destName: this._translateService.instant('NetAmount') },
            { sourceName: 'GrossAmount', destName: this._translateService.instant('GrossAmount') },
            //{ sourceName: 'Tags.0.Name', destName: this._translateService.instant('Tag') },
          ]
        };

        if (response.items && response.items.length > 0) {
          response.items.forEach(field => {
            mappings.mappings.push({ sourceName: `ExtraFields.${field.name}`, destName: this._translateService.instant('ExtraFields.' + field.name) });
          });
        }

        let params : InvoiceFilter = {
          includeSupplier: true,
          includeTags: true,
          includeDeleted: false,
        }

        this.applyFilter(params);

        this._invoicesService.exportInvoices$(this.tenantId, mappings, params)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response) => {
            const filename = 'export_invoice.xlsx';
            const blob = new Blob([response.body], { type: response.body.type });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            link.click();
            window.URL.revokeObjectURL(url);
          },
          error: (error) => {
            Sentry.captureEvent(error);
          }
        });
      },
      error: (error) => {
        Sentry.captureEvent(error);
      }
    });
  }

  ngOnDestroy(): void {
    this._dropdownEventsService.removeSubscriptionsAll();
    this.destroy$.next();
    this.destroy$.complete();
  }
}