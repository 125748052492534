import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { HttpClient } from '@angular/common/http';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { catchError, Observable, tap } from 'rxjs';
import { CabinetEventResponse, CabinetKeysResponse, CabinetReservationResponse, CabinetResponse, CabinetTypesResponse, CabinetUserResponse } from '../models/cabinet/cabinet-response';
import { CabinetEventFilter, CabinetFilter, CabinetKeyFilter, CabinetReservationFilter, CabinetTypeFilter, CabinetUserFilter } from '../models/cabinet/cabinet-filter';
import { CabinetType } from '../models/booking';
import { Cabinet, CabinetEvent, CabinetKey, CabinetReservation, CabinetUser } from '../models/cabinet/cabinet';
import { CabinetKeyRequest, CabinetUserRequest } from '../models/cabinet/cabinet-request';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CabinetService {

  constructor(
    private _httpClient: HttpClient,
    private _commonService: CommonService,
    private _translateService: TranslateService,
    private _toastrService: ToastrService,
  ) { }

  listCabinetTypes$(tenantId: string, params?: CabinetTypeFilter) : Observable<CabinetTypesResponse> {
    return this._httpClient.get<CabinetTypesResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/cabinet-types`, 
    { params: this._commonService.buildParams(params) })
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

  getCabinetType$(tenantId: string, cabinetTypeId: string) : Observable<CabinetType> {
    return this._httpClient.get<CabinetType>(`${VECTORE_API_BASE_URL}/${tenantId}/cabinet-types/${cabinetTypeId}`)
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

  listCabinets$(tenantId: string, params?: CabinetFilter) : Observable<CabinetResponse> {
    return this._httpClient.get<CabinetResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/cabinets`, 
    { params: this._commonService.buildParams(params) })
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

  getCabinet$(tenantId: string, cabinetId: string) : Observable<Cabinet> {
    return this._httpClient.get<Cabinet>(`${VECTORE_API_BASE_URL}/${tenantId}/cabinets/${cabinetId}`)
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

  // Create a new mapping for a cabinet key. Requires CAN_MANAGE_CABINET_KEYS permission.
  createCabinetKey$(tenantId: string, cabinetKeyRequest: CabinetKeyRequest) : Observable<Cabinet> {
    return this._httpClient.post<Cabinet>(`${VECTORE_API_BASE_URL}/${tenantId}/cabinets`, cabinetKeyRequest)
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

  listCabinetKeys$(tenantId: string, params?: CabinetKeyFilter) : Observable<CabinetKeysResponse> {
    return this._httpClient.get<CabinetKeysResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/cabinet-keys`, 
    { params: this._commonService.buildParams(params) })
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

  getCabinetKey$(tenantId: string, cabinetKeyId: string) : Observable<CabinetKey> {
    return this._httpClient.get<CabinetKey>(`${VECTORE_API_BASE_URL}/${tenantId}/cabinet-keys/${cabinetKeyId}`)
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

  patchCabinetKey$(tenantId: string, cabinetKeyId: string, cabinetKeyRequest: CabinetKeyRequest) : Observable<CabinetKey> {
    return this._httpClient.patch<CabinetKey>(`${VECTORE_API_BASE_URL}/${tenantId}/cabinet-keys/${cabinetKeyId}`, cabinetKeyRequest)
    .pipe(
      tap((response) => {
        if (response) {

          this._toastrService.success(this._translateService.instant('CABINET_KEYS.MESSAGES.CABINET_KEY_UPDATED'));
        }
      }),
      catchError(error => this._commonService.handleError(error))
    );
  }

  createCabinetUser$(tenantId: string, cabinetUserRequest: CabinetUserRequest) : Observable<CabinetUser> {
    return this._httpClient.post<CabinetUser>(`${VECTORE_API_BASE_URL}/${tenantId}/cabinet-users`, cabinetUserRequest)
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

  listCabinetUsers$(tenantId: string, params?: CabinetUserFilter) : Observable<CabinetUserResponse> {
    return this._httpClient.get<CabinetUserResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/cabinet-users`, 
    { params: this._commonService.buildParams(params) })
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

  getCabinetUser$(tenantId: string, cabinetUserId: string) : Observable<CabinetUser> {
    return this._httpClient.get<CabinetUser>(`${VECTORE_API_BASE_URL}/${tenantId}/cabinet-users/${cabinetUserId}`)
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

  patchCabinetUser$(tenantId: string, cabinetUserId: string, cabinetUserRequest: CabinetUserRequest) : Observable<CabinetUser> {
    return this._httpClient.patch<CabinetUser>(`${VECTORE_API_BASE_URL}/${tenantId}/cabinet-users/${cabinetUserId}`, cabinetUserRequest)
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

  listCabinetReservations$(tenantId: string, params?: CabinetReservationFilter) : Observable<CabinetReservationResponse> {
    return this._httpClient.get<CabinetReservationResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/cabinet-reservations`, 
    { params: this._commonService.buildParams(params) })
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

  getCabinetReservation$(tenantId: string, cabinetReservationId: string) : Observable<CabinetReservation> {
    return this._httpClient.get<CabinetReservation>(`${VECTORE_API_BASE_URL}/${tenantId}/cabinet-reservations/${cabinetReservationId}`)
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

  listCabinetEvents$(tenantId: string, params?: CabinetEventFilter) : Observable<CabinetEventResponse> {
    return this._httpClient.get<CabinetEventResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/cabinet-events`, 
    { params: this._commonService.buildParams(params) })
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

  getCabinetEvent$(tenantId: string, cabinetEventId: string) : Observable<CabinetEvent> {
    return this._httpClient.get<CabinetEvent>(`${VECTORE_API_BASE_URL}/${tenantId}/cabinet-events/${cabinetEventId}`)
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }
}