<div class="card-filter-task p-3">
    <header class="d-flex justify-content-between align-items-center">
        <h4 class="card-person-modal-title">
            {{ 'FILTER.CREATE_FILTER' | translate }}
        </h4>
            
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </header>

    <content>
        <form class="d-flex flex-column gap-3" [formGroup]="form">

            <div class="form-group">
                <label for="task-type" class="form-label">{{'TYPOLOGY' | translate}}</label>
                <dropdown-primary-vehicle-type 
                    [vehicleTypeId]="form.value.vehicleTypeId ?? null"
                    (onSelectedEvent)="onVehicleTypeSelected($event)">                    
                </dropdown-primary-vehicle-type>
            </div>

            <div class="form-group">
                <label for="license-plate" class="form-label">{{'HEADQUARTER.NAME' | translate}}</label>
                <input type="text" class="form-control h-100" [placeholder]="'TYPE_TO_SEARCH' | translate"
                    formControlName="headquarterName"
                    [isAnimated]="true"
                    [typeahead]="headquarterName$"
                    [typeaheadAsync]="true"
                    [typeaheadMinLength]="0"
                    (typeaheadOnSelect)="onSelectHeadquarter($event)">
            </div>

            <div class="form-group">
                <label for="license-plate" class="form-label">{{'LOCATION.NAME' | translate}}</label>
                <input type="text" class="form-control h-100" [placeholder]="'TYPE_TO_SEARCH' | translate"
                    formControlName="locationName"
                    [isAnimated]="true"
                    [typeahead]="locationName$"
                    [typeaheadAsync]="true"
                    [typeaheadMinLength]="0"
                    (typeaheadOnSelect)="onSelectLocation($event)">
            </div>

        </form>
    </content>

    <footer class="d-flex gap-2 align-items-center justify-content-center mt-4">
        <button type="button" class="btn-gs-secondary" (click)="reset()">{{'ACTIONS.RESET' | translate}}</button>

        <button type="button" class="btn-gs-primary" (click)="apply()">{{'ACTIONS.APPLY' | translate}}</button>
    </footer>
</div>