<div class="badge-gs" [ngClass]="{'badge-gs-best': number === 1}" *ngIf="number">

    <div *ngIf="number > 1; else isTheBest"># {{number}}</div>

    <ng-template #isTheBest>{{output}}</ng-template>

</div>

<div class="badge-gs badge-gs-best" *ngIf="!number">

    {{text}}

</div>