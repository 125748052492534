<div class="card border-0 p-3">
    <vehicle-usages-table-filters
        [titleTable]="titleTable"
        (filterTableChanged)="getTable()" (filterTableHeaderChanged)="getDataForm($event)" (exportEvent)="getExport()">
    </vehicle-usages-table-filters>
    <dynamic-table 
        [columns]="tableColumns" [rows]="tableRows" (filterTasks)="getTable()"
        [totalItems]="filtersPage.totalRows" [itemsPerPage]="filtersPage.itemsPerPage" 
        [maxPagesToShowPagination]="filtersPage.maxPagesToShowPagination" (pageChanged)="onPageChange($event)"
        [listRowToShowPerPage]="filtersPage.listRowToShowPerPage" (pageSizeChanged)="onPageSizeChange($event)"
        (sortColumn)="onSortColumnHeader($event)">
    </dynamic-table>
</div>