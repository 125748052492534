import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { StorageFile } from 'src/app/shared/models/storage';
import { ImportService } from 'src/app/shared/api-services/import.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { DropdownOption } from '../../../dropdown/dropdown-primary/dropdown.interface';
import { Import, ImportRequest, Importer, RelatedField } from 'src/app/shared/models/import';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as RoutesUrl from 'src/app/shared/constant/app-routes';
import { CommunicationService } from 'src/app/shared/utilities/comunication.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { TypeImporters } from './type-importers';
import { ModalUtilsService } from 'src/app/shared/utilities/modal-utils.service';
import { Observable, map, switchMap } from 'rxjs';

@Component({
  selector: 'upload-import-modal',
  templateUrl: './upload-import-modal.component.html',
  styleUrls: ['./upload-import-modal.component.scss']
})
export class UploadImportModalComponent {

  tenantId = this._tenantService.getTenantId();

  @Input() typeImporters: TypeImporters;

  typeImportersOption: DropdownOption[];
  selectedTypeImporter: DropdownOption;

  listImporters: Importer[];
  typeImporter: Importer;

  import: Import;

  file: StorageFile;
  bucketName: string = 'file-imports';

  statusModal = {
    canUplaod : false,
    canImport : false,
  }

  importForm: FormGroup;

  mappings: Observable<RelatedField>;

  constructor(
    private _tenantService: TenantService,
    private _importService: ImportService,
    private _communicationService: CommunicationService,
    private _modalUtilsService: ModalUtilsService,
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
    private _router: Router,
    public bsModalRef: BsModalRef,
  ) { 
    this.importForm = new FormGroup({
      name: new FormControl('', Validators.required),
    });
  }

  ngOnInit(): void {
    this.getListTypeImporters();
  }

  private getListTypeImporters() {
    this._importService.listImporters$(this.tenantId).subscribe({
      next: response => {
        this.listImporters = response.items;
        this.typeImportersOption = response.items.map(importer => {
          return {
            value: importer.name,
            text: importer.name,
          }
        }).sort((a, b) => a.text.localeCompare(b.text));
        this.selectTypeImporter();
      }
    });
  }

  selectTypeImporter() {
    switch (this.typeImporters) {
      case TypeImporters.Versions:
        this.selectedTypeImporter = this.typeImportersOption.find(option => option.value === TypeImporters.Versions);
        this.onSelectedTypeImporter(this.selectedTypeImporter);
        break;
      case TypeImporters.Vehicles:
        this.selectedTypeImporter = this.typeImportersOption.find(option => option.value === TypeImporters.Vehicles);
        this.onSelectedTypeImporter(this.selectedTypeImporter);
        break;
      case TypeImporters.Users:
        this.selectedTypeImporter = this.typeImportersOption.find(option => option.value === TypeImporters.Users);
        this.onSelectedTypeImporter(this.selectedTypeImporter);
        break;
      case TypeImporters.Assets:
        this.selectedTypeImporter = this.typeImportersOption.find(option => option.value === TypeImporters.Assets);
        this.onSelectedTypeImporter(this.selectedTypeImporter);
        break;
      case TypeImporters.Tasks:
        this.selectedTypeImporter = this.typeImportersOption.find(option => option.value === TypeImporters.Tasks);
        this.onSelectedTypeImporter(this.selectedTypeImporter);
        break;
      case TypeImporters.VehicleUsages:
        this.selectedTypeImporter = this.typeImportersOption.find(option => option.value === TypeImporters.VehicleUsages);
        this.onSelectedTypeImporter(this.selectedTypeImporter);
        break;
      default:
        this.selectedTypeImporter = { value: '', text: 'SELECT_OPTION' };
        break;
    }
  }

  onSelectedTypeImporter($event: DropdownOption) {
    this.selectedTypeImporter = $event;
    this.typeImporter = this.listImporters.find(importer => importer.name === $event.value);
    if (this.typeImporter) {
      this.file = null;
      this.statusModal.canUplaod = true;
      this.statusModal.canImport = false;
      this.importForm.reset();

      this.mappings = this._importService.listImporterFields$(this.tenantId, this.typeImporter.name).pipe(
        map(response => {
          return response.reduce((acc, field) => {
            if (field.name.split('_')[0] === 'ExtraFields.') {
              acc[field.name] = this._translateService.instant(field.name);
            } else {
              acc[field.name] = this._translateService.instant(field.name);
            }
            return acc;
          }, {} as RelatedField);
        })
      );
    }
  }

  addPendingFile($event: StorageFile) {
    this.file = $event;
    this.importForm.get('name').setValue(this.file.name);
    this.statusModal.canImport = true;
  }

  createImport() {
    if (this.file) {
      let request : ImportRequest = {
        importerName: this.typeImporter.name,
        name : this.importForm.value.name ?? this.file.name,
        sourceFileId : this.file.id,
        confirmed: false,
      }

      this.mappings.pipe(
        switchMap(mappings => {
          request.mappings = mappings;
          return this._importService.createImport$(this.tenantId, request);
        })
      ).subscribe({
        next: response => {
          this.import = response;
          this._router.navigate([RoutesUrl.IMPORTS]);
          this._toastrService.success(this._translateService.instant('MESSAGES.IMPORT_SUCCESS'));
          this._communicationService.sendEvent();
          this.bsModalRef.hide();
        }
      });
    }
  } 

  openModalDownloadTrace() {
    this.bsModalRef.hide();
    let typeImport = this.selectedTypeImporter.value as TypeImporters;
    setTimeout(() => {
      this._modalUtilsService.openDownloadTraceImportModal(typeImport);    
    }, 200);
  }
}
