import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { Me } from "src/app/shared/models/me";
import { AccessUtilsService } from "src/app/shared/utilities/access-utils.servic";
import { ActionsModalComponent } from "src/app/shared/components/modals/actions-modal/actions-modal.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { VERSION } from "src/environments/version";
import { Subject, Subscription, takeUntil } from "rxjs";
import { MeService } from "src/app/shared/api-services/new-me.service";

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

  tenantId: string = this._tenantService.getTenantId();
  isMobile: boolean;
  
  viewMobileSubscription: Subscription;

  listItemsSidebar: SidebarItem[];

  modalActions: BsModalRef;

  copyright: string;
  version: string;

  destroy$: Subject<void> = new Subject<void>();
  
  constructor(
    private _newMeService: MeService,
    private _tenantService: TenantService,
    private _breakpointObserver: BreakpointObserver,
    private _accessUtilsService: AccessUtilsService,
    private _authenticationService: AuthenticationService,
    private _modalService: BsModalService,
  ) { 
    this.viewMobileSubscription = this._breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
  }
    
  ngOnInit(): void {
    this.getMeInformation();

    let currentYear = new Date().getFullYear();
    this.copyright = `© ${currentYear} Vectore Web App`;
    this.version = `${VERSION}`;
  }

  getMeInformation() {
    this._newMeService.me$
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (response: Me) => {
        if (response) {
          this.listItemsSidebar = this._accessUtilsService.getAllNavs(response);
        }
      },
    });
  }

  openModalActions() {
    this.modalActions = this._modalService.show(ActionsModalComponent, {
      class: 'modal-md',
      animated: true,
    });
  }
    
  logout() {
    this._authenticationService.logout();
  }

  ngOnDestroy(): void {
    if (this.viewMobileSubscription) {
      this.viewMobileSubscription.unsubscribe();
    }
  }
}  

export interface SidebarItem {
  name?: string;
  label?: string;
  icon?: string;
  color?: string;
  routerLink?: string;
  href?: string;
  canExpand?: boolean;
  isExpanded?: boolean;
  children?: SidebarItem[];
}