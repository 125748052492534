import * as Sentry from "@sentry/angular-ivy";
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DropdownOption } from 'src/app/shared/components/dropdown/dropdown-primary/dropdown.interface';
import { FiltersPage } from 'src/app/shared/models/filter-page';
import { Task, TaskData, TaskResp, TaskType } from 'src/app/shared/models/task';
import { TasksService } from 'src/app/shared/api-services/tasks.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DropdownEventsService } from 'src/app/shared/utilities/dropdown-events.service';
import { Subject, isEmpty, switchMap, takeUntil } from 'rxjs';
import { TaskUtilsService } from 'src/app/shared/utilities/task-utils.service';
import { MappingModel } from 'src/app/shared/models/users';
import { TableColumn } from 'src/app/shared/components/table/dynamic-table/dynamic-table-desktop/table-column.interface';
import { TableRow } from 'src/app/shared/components/table/dynamic-table/dynamic-table-desktop/table-row.interface';
import { CommunicationService } from "src/app/shared/utilities/comunication.service";
import { Me } from "src/app/shared/models/me";
import { MeServiceDeprecated } from "src/app/shared/api-services/me.service";
import { ModalUtilsService } from "src/app/shared/utilities/modal-utils.service";
import { FilterModalService } from "../../modals/filter-modal/filter-modal.service";
import { ConfirmModalComponent } from "../../modals/confirm-modal/confirm-modal.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { TaskDataFilter, TaskFilter } from "src/app/shared/models/tasks/task-filter";
import { Router } from "@angular/router";

@Component({
  selector: 'task-table',
  templateUrl: './task-table.component.html',
  styleUrls: ['./task-table.component.scss']
})
export class TaskTableComponent implements OnInit, OnDestroy {

  tenantId: string;
  @Input() tableId: string;

  @Input() titleTable: string;
  @Input() listTableFilterTasks!: DropdownOption[];

  @Input() taskTypeId?: string;
  @Input() vehicleId?: string;
  @Input() userId?: string;
  @Input() assetId?: string;
  @Input() bookingId?: string;

  me?: Me;

  @Input() hasAsset?: boolean;
  @Input() hasUsage?: boolean;

  queryParams : TaskFilter = {
    includeTaskType: true,
    includeHeadquarter: true,
    includeVehicle: true,
    includeUser: true,
    includeAsset: true,
    includeDeleted: true,
    includeAssignedUser: true,
    hasTCO: false
  };

  tableColumns: TableColumn[];
  tableRows: TableRow[];
  
  filtersPage: FiltersPage = {
    firstElement: 0,
    page: 1,
    itemsPerPage: 10,
    listRowToShowPerPage: [5, 10, 20, 30, 50, 100],
    totalRows: 0,
    totalPage: 1,
    maxPagesToShowPagination: 6
  };
  
  filterHeader: string;
  filterTask: DropdownOption;
  typeTable: string;

  isMobile: boolean = false;

  confirmModal: BsModalRef;

  private destroy$: Subject<void> = new Subject<void>(); 

  constructor(
    private _tenantService: TenantService,
    private _taskService: TasksService,
    private _meSerivce: MeServiceDeprecated,
    private _translateService: TranslateService,
    private _datePipe: DatePipe,
    private _taskUtilsService: TaskUtilsService,
    private _dropdownEventsService: DropdownEventsService,
    private _filterTaskModalService: FilterModalService,
    private _modalUtilsService: ModalUtilsService,
    private _breakpointObserver: BreakpointObserver,
    private _cdr: ChangeDetectorRef,
    private _communicationService: CommunicationService,
    private _modalService: BsModalService,
    private _router: Router,
    public bsModalRef: BsModalRef,
  ) { 
    this.tenantId = this._tenantService.getTenantId();
    this._breakpointObserver
      .observe([Breakpoints.Handset])
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        this.isMobile = result.matches;
      });

    this.filtersPage.page = 1;
    this.filtersPage.firstElement = 0;
    this.filtersPage.orderBy = '-Id';
  }

  ngOnInit(): void {
    this.isMobile? this.filtersPage.maxPagesToShowPagination = 3 : this.filtersPage.maxPagesToShowPagination = 6; 
    this.applyFilterFromModal(); 
    
    this._meSerivce.getMe$(this.tenantId)
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (me) => {
        this.me = me;
      }
    });

    this._communicationService.getEvent()
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: () => {
        this.getTable();
      },
      error: (error) => {
        Sentry.captureEvent(error);
      }
    });
  }

  getTable($typeTable?: string) {
    if ($typeTable) {
      this.typeTable = $typeTable;
    }

    if (this.typeTable === 'all_tasks' || this.typeTable === 'assigned_to_you' || this.typeTable === 'created_by_you') {
      this.filtersPage.orderBy = '-Id';    
      
      this.getTasks();

      this.tableColumns = [];
      if (this.isMobile) {
        this.tableColumns = [
          { 
            field: 'title', 
            header: "TITLE",
            isSortable: true,
            cssClass: () => 'cursor-pointer',
            routerLink: () => `/${this._router.url}`,
            queryParams: (task : Task) => ({ modalEditTask: task.id })
          },
          { 
            field: 'actions', 
            header: "ACTIONS.NAME",
            dotOptions: (task: Task) => task.actions
          }
        ];

      }
      else {
        this.tableColumns = [
          { 
            field: 'title', 
            header: "TITLE",
            isSortable: true,
            cssClass: () => 'cursor-pointer',
            routerLink: () => `/${this._router.url}`,
            queryParams: (task : Task) => ({ modalEditTask: task.id })
          },
          { 
            field: 'taskTypeId', 
            header: "TYPOLOGY",
            isSortable: true,
            value: (task : Task) => task.taskType ? this._translateService.instant(task.taskType.name) : ''
          },
          { 
            field: 'expirationDate', 
            header: "BY_THE_DATE",
            isSortable: true,
          },
          { 
            field: 'expirationMileage', 
            header: "TASKS.SECTION5.DYNAMIC_TABLE.TABLE.HEADERS.all_tasks.expirationMileage",
            isSortable: true,
          },
          { 
            field: 'vehicleId', 
            header: "LICENSE_PLATE",
            isSortable: true,
            value: (task : Task) => task.vehicle ? task.vehicle.licensePlate : ''
          },
          { 
            field: 'assetId', 
            header: "ASSET",
            isSortable: true,
            value: (task : Task) => task.asset ? task.asset.displayName : ''
          },
          { 
            field: 'userId', 
            header: 'USER',
            isSortable: true,
            value: (task) => task.user ? task.user.displayName : ''
          },
          { 
            field: 'headquarterId', 
            header: "HEADQUARTER.NAME",
            isSortable: true,
            value: (task : Task) => task.headquarter ? task.headquarter.name : ''
          },
          { 
            field: 'assignedUserId', 
            header: "ASSIGNED_USER",
            isSortable: true,
            value: (task : Task) => task.assignedUser ? task.assignedUser.displayName : ''
          },
          { 
            field: 'status', 
            header: "STATUS",
            value: (task : Task) => task.statusClient?.text,
            cssClass: (task : Task) => task.statusClient?.cssClass,
          },
          { 
            field: 'actionsDynamic', 
            isSortable: false,
            header: "ACTIONS.NAME",
            dotOptions: (task: Task) => task.actions
          },
        ];

      }

    }

    if (this.typeTable === 'by_type') {
      this.filtersPage.orderBy = '-Items';
      this.getNumberTypesTasks();

      this.tableColumns = [
        { 
          field: 'taskTypeId', 
          header: "TASKS.SECTION5.DYNAMIC_TABLE.TABLE.HEADERS.by_type.type",
          isSortable: true,
          value: (taskTypeResp : TaskData) => taskTypeResp.taskType?.name ? this._translateService.instant(taskTypeResp.taskType.name) : ''
        },
        { 
          field: 'items', 
          header: "TASKS.SECTION5.DYNAMIC_TABLE.TABLE.HEADERS.by_type.item",
          isSortable: true,
          value: (taskType : TaskData) => taskType.items ? taskType.items : '0'
        },
        { 
          field: 'toDo', 
          header: "TASKS.SECTION5.DYNAMIC_TABLE.TABLE.HEADERS.by_type.todo",
          isSortable: true,
          value: (taskType : TaskData) => taskType.toDo ? taskType.toDo : '0'
        },
        { 
          field: 'notAssigned', 
          header: "TASKS.SECTION5.DYNAMIC_TABLE.TABLE.HEADERS.by_type.not_assigned",
          isSortable: true,
          value: (taskType : TaskData) => taskType.notAssigned ? taskType.notAssigned : '0'
        },
        { 
          field: 'late', 
          header: "TASKS.SECTION5.DYNAMIC_TABLE.TABLE.HEADERS.by_type.late",
          isSortable: true,
          value: (taskType : TaskData) => taskType.late ? taskType.late : '0'
        },
        { 
          field: 'quota', 
          header: "TASKS.SECTION5.DYNAMIC_TABLE.TABLE.HEADERS.by_type.quota",
          value: (taskType : TaskData) => taskType.percentage ? `${taskType.percentage}%` : '0.00%'
        },
      ];
    }

    if (this.typeTable === 'by_single_task_usage') {

      this.filtersPage.orderBy = '-Id';   
      
      this.getTasks();

      this.tableColumns = [
        { 
          field: 'title', 
          header: "TITLE",
          isSortable: true,
        },
        { 
          field: 'taskTypeId', 
          header: "TYPOLOGY",
          isSortable: true,
          value: (task : Task) => task.taskType ? this._translateService.instant(task.taskType.name) : ''
        },
        { 
          field: 'assetId', 
          header: "ASSET",
          isSortable: true,
          value: (task : Task) => task.asset ? task.asset.displayName : ''
        },
        { 
          field: 'usageStartDate', 
          header: "START",
          isSortable: true,
          cssClass: (task : Task) => task.usageStartDate && task.usageEndDate? 'bg-violet-light badge-table' : 'color-primary-light badge-table',
          value: (task : Task) => task.usageStartDate ? this._datePipe.transform(task.usageStartDate, 'dd-MM-yyyy') : ''
        },
        { 
          field: 'usageEndDate', 
          header: "END",
          isSortable: true,
          cssClass: (task : Task) => task.usageStartDate && task.usageEndDate? 'bg-violet-light badge-table' : '',
          value: (task : Task) => task.usageEndDate ? this._datePipe.transform(task.usageEndDate, 'dd-MM-yyyy') : ''
        },
        { 
          field: 'headquarterId', 
          header: "HEADQUARTER.NAME",
          isSortable: true,
          value: (task : Task) => task.headquarter ? task.headquarter.name : ''
        },
        { 
          field: 'actions', 
          header: "ACTIONS.NAME",
          dotOptions: (task: Task) => task.actions
        },
      ];
    }
    this._cdr.detectChanges();
  }

  getDataForm($event: FormGroup) {
    if ($event.value.headerToFilter && $event.value.headerToFilter === 'Title' && $event.value.inputFilter && $event.value.inputFilter !== '') {
      this.queryParams.titleContains = $event.value.inputFilter;
    } else {
      this.queryParams.titleContains = null;
    }

    if ($event.value.headerToFilter && $event.value.headerToFilter === 'TaskCode') {
      this.queryParams.code = $event.value.inputFilter;
    } else {
      this.queryParams.code = null;
    }

    if ($event.value.startDate) {
      let startDate = new Date($event.value.startDate);
      startDate.setHours(0, 0, 0, 0);
      this.queryParams.rangeExpirationStartDate = this._datePipe.transform(startDate, 'yyyy-MM-ddTHH:mm:ss')?.toString();
    } else {
      this.queryParams.rangeExpirationStartDate = null;
    }

    if ($event.value.endDate) {
      let endDate = new Date($event.value.endDate);
      endDate.setHours(23, 59, 59, 999);
      this.queryParams.rangeExpirationEndDate = this._datePipe.transform(endDate, 'yyyy-MM-ddTHH:mm:ss')?.toString();
    } else {
      this.queryParams.rangeExpirationEndDate = null;
    }

    this.getTable();
  }

  getTasks() {
    this.tableRows?.forEach(row => this._dropdownEventsService.removeSubscriptions(row['customId']));

    this.queryParams.start = this.filtersPage.firstElement;
    this.queryParams.limit = this.filtersPage.itemsPerPage;
    this.queryParams.orderBy = this.filtersPage.orderBy;

    this.queryParams.vehicleId = this.vehicleId ?? null;
    this.queryParams.bookingId = this.bookingId ?? null;  
    this.queryParams.userId = this.userId ?? null;
    this.queryParams.assetId = this.assetId ?? null;
    this.queryParams.taskTypeId = this.taskTypeId ?? null;  

    this.queryParams.hasAsset = this.hasAsset ?? null;
    this.queryParams.hasUsage = this.hasUsage ?? null;
  
    if (this.typeTable === 'assigned_to_you') {
      this.queryParams.assignedUserId = this.me.id;
    } else {
      this.queryParams.assignedUserId = null;
    }
  
    if (this.typeTable === 'created_by_you') {
      this.queryParams.startUserId = this.me.id;
    } else {
      this.queryParams.startUserId = null;
    }
  
    if (this.typeTable === 'all_tasks' && this.userId) {
      this.queryParams.userId = this.userId;
    } else {
      this.queryParams.userId = null;
    }
    
    this._taskService.listTasks$(this.tenantId, this.queryParams)
    .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response : TaskResp) => {
          response.items.forEach((task) => {
            task.expirationDate = this._datePipe.transform(task.expirationDate, 'dd-MM-yyyy')?.toString();
          });
          
          response.items.forEach((task) => {
            task.customId = this.tableId + task.id;
            task = this._taskUtilsService.setStatusBadgeTask(task, task.taskType);
            task = this._taskUtilsService.setActionsTask(task);
          });

          this.tableRows = [];
          this.tableRows = response.items;

          this.onActionTable();

          this.filtersPage.totalRows = response.count;
          this.filtersPage.totalPage = Math.ceil(response.count / this.filtersPage.itemsPerPage);
        },
        error: (error) => {
          Sentry.captureEvent(error);
        }
      });
  }

  getNumberTypesTasks() {
    let query : TaskDataFilter = {
      start: this.filtersPage.firstElement,
      limit: this.filtersPage.itemsPerPage, 
      orderBy: this.filtersPage.orderBy,
      includeTaskType: true,
      includeTaskData: true,
      groupByTaskTypeId : '*',
    };

    if (this.queryParams.rangeExpirationStartDate) {
      query.rangeStartExpirationDate = this.queryParams.rangeExpirationStartDate;
    }

    if (this.queryParams.rangeExpirationEndDate) {
      query.rangeEndExpirationDate = this.queryParams.rangeExpirationEndDate;
    }

    let listTaskType : TaskType[] = [];
    this._taskService.listTaskTypes$(this.tenantId).pipe(
      takeUntil(this.destroy$),
      switchMap((taskTypeResp) => {
        listTaskType = taskTypeResp.items;       

        return this._taskService.getTaskData$(this.tenantId, query)
      })      
    )
    .subscribe({
      next: (taskDataResp) => {
        this.tableRows = [];

        this.filtersPage.totalRows = taskDataResp.count;
        this.filtersPage.totalPage = Math.ceil(taskDataResp.count / this.filtersPage.itemsPerPage);

        const totalTasks = taskDataResp.items.reduce((accumulator, current) => accumulator + current.items, 0);
        listTaskType.forEach((taskType) => {

          let taskData = taskDataResp.items.find(item => item.taskTypeId === taskType.id);
          if (taskData) {
            taskData.percentage = (taskData.items / totalTasks * 100).toFixed(2);
          }
        });
        this.tableRows = taskDataResp.items;
      },
      error: (error) => {
        Sentry.captureEvent(error);
      }
    });

  }

  onActionTable() {
    this.tableRows.forEach((row) => {
      this._dropdownEventsService.getSelectedOptionOnClick(row['customId'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((option: DropdownOption) => {
        
        if (option && option.value === 'cancel') {
          this._taskService.deleteTask$(this.tenantId, row.id)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: () => {
              this.getTable(this.typeTable);
            }
          });
        }

        if (option && option.value === 'uncancel') {
          this._taskService.unDeleteTask$(this.tenantId, row.id)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: () => {
              this.getTable(this.typeTable);
            }
          });
        }

        if (option && option.value === 'edit') {
          this._modalUtilsService.openEditTaskModal(row.id)
          .pipe(takeUntil(this.destroy$))
          .subscribe();
        }

      });
    });
  }

  onMassiveSelections($event: TableRow[]) {
    this.tableRows = $event;
  }

  onMassiveAction($event: string) {
    if ($event === 'delete_selected') {
      let elementsSelected = this.tableRows.filter(element => element.isSelected);
      this.confirmModal = this._modalService.show(ConfirmModalComponent, {
        class: 'modal-sm',
        animated: true,
        initialState: {
          content: this._translateService.instant('MODAL_DELETE_CONFIRM.MESSAGE', {x: elementsSelected.length}),
        }
      });

      this.confirmModal.content.onConfirm.pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
          for (let index = 0; index < elementsSelected.length; index++) {
            this._taskService.deleteTask$(this.tenantId, elementsSelected[index].id)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
              next: (response) => {
                if (response.status == 204) {
                  if (index === elementsSelected.length - 1) {
                    this.getTable();
                  }
                }
              }
            });
          }
      });
    }
  }

  private applyFilterFromModal() {
    this._filterTaskModalService.getForm$('filter-task-modal')
    .pipe(takeUntil(this.destroy$))
    .subscribe(form => {
      if (form) {
        this.taskTypeId = form.value.taskTypeId ?? null;
        this.vehicleId = form.value.vehicleId ?? null;
        this.getTable();
      }
    });
  }

  onPageChange($newPage: number): void {
    this.filtersPage.page = $newPage;
    this.filtersPage.firstElement = ($newPage - 1) * this.filtersPage.itemsPerPage;
    this.getTable();
  }

  onPageSizeChange(newSize: number): void {
    this.filtersPage.itemsPerPage = newSize;
    this.filtersPage.page = 1;
    this.filtersPage.firstElement = 0;
    this.getTable();    
  }

  onSortColumnHeader(columnField: string) {
    this.filtersPage.orderBy = columnField;  
    this.getTable();
  }

  getExport() {
    let params : TaskFilter = {
      taskTypeId: this.taskTypeId ?? null,
    }
    this._taskService.getTaskFields$(this.tenantId, params)
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (response) => {
        let mappings: MappingModel = {
          mappings: [
            { sourceName: 'TaskType.Name', destName: this._translateService.instant('TaskType') },
            { sourceName: 'Code', destName: 'Code' },
            { sourceName: 'Title', destName: this._translateService.instant('Title') },
            { sourceName: 'Description', destName: this._translateService.instant('Description') },
            { sourceName: 'Headquarter.Name', destName: this._translateService.instant('Headquarter') },
            { sourceName: 'User.UserName', destName: this._translateService.instant('UserName') },
            { sourceName: 'Vehicle.LicensePlate', destName: this._translateService.instant('LicensePlate') },
            { sourceName: 'Asset.Code', destName: this._translateService.instant('AssetCode') },
            { sourceName: 'StartUser.UserName', destName: this._translateService.instant('StartUserName') },
            { sourceName: 'StartDate', destName: this._translateService.instant('StartDate') },
            { sourceName: 'EndUser.UserName', destName: this._translateService.instant('EndUserName') },
            { sourceName: 'EndDate', destName: this._translateService.instant('EndDate') },
            { sourceName: 'EndMileage', destName: this._translateService.instant('EndMileage') },
            { sourceName: 'ExpirationMileage', destName: this._translateService.instant('ExpirationMileage') },
            { sourceName: 'ExpirationDate', destName: this._translateService.instant('ExpirationDate') },
            { sourceName: 'AssignedUser.DisplayName', destName: this._translateService.instant('AssignedUserName') },

            { sourceName: 'TaskCost.AccountingDate', destName: this._translateService.instant('AccountingDate') },
            { sourceName: 'TaskCost.NetCost', destName: this._translateService.instant('NetCost') },
            { sourceName: 'TaskCost.GrossCost', destName: this._translateService.instant('GrossCost') },

            { sourceName: 'UsageStartDate', destName: this._translateService.instant('UsageStartDate') },
            { sourceName: 'UsageEndDate', destName: this._translateService.instant('UsageEndDate') },
          ]
        };

        if (response.items && response.items.length > 0) {
          response.items.forEach(field => {
            mappings.mappings.push({ sourceName: `ExtraFields.${field.name}`, destName: this._translateService.instant('ExtraFields.' + field.name) });
          });
        }

        const queryParams : TaskFilter = {
          ...this.queryParams,
          includeStartUser: true,
          includeEndUser: true
        }

        this._taskService.exportTasks$(this.tenantId, mappings, queryParams)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response) => {
            const filename = 'export_task.xlsx';
            const blob = new Blob([response.body], { type: response.body.type });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            link.click();
            window.URL.revokeObjectURL(url);
          },
          error: (error) => {
            Sentry.captureEvent(error);
          }
        });
      },
      error: (error) => {
        Sentry.captureEvent(error);
      }
    });
  }

  ngOnDestroy(): void { 
    this.destroy$.next();
    this.destroy$.complete();
  }
}
