import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, catchError, map, shareReplay, tap } from 'rxjs';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { CommonService } from './common.service';
import { Me, NotificationRequest } from '../models/me';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MeService {
  private userImageCache = new Map<string, Observable<any>>();
  me$: BehaviorSubject<Me> = new BehaviorSubject<Me | null>(null);
  
  constructor(
    private _http: HttpClient,
    private _commonService: CommonService,
    private _domSanitizer: DomSanitizer,
  ) { }

  sendMe(tenantId: string): void {    
    this._http.get<Me>(`${VECTORE_API_BASE_URL}/${tenantId}/me`)
    .pipe(
      shareReplay(1),
      tap(me => {
        this.me$.next(me);
      })
    ).subscribe();
  }

  getMe$(tenantId: string): Observable<Me> {    
    return this._http.get<Me>(`${VECTORE_API_BASE_URL}/${tenantId}/me`)
    .pipe(
      shareReplay(1)
    );
  }

  getMeImage$(tenantId: string, width?: number, height?: number, safe: boolean = true): Observable<SafeUrl | string | null> {
    const cacheKey = `${tenantId}-${width}-${height}`;

    if (!this.userImageCache.has(cacheKey)) {
      const params = {
        width: width,
        height: height
      };
      const requestObservable = this._http.get<any>(`${VECTORE_API_BASE_URL}/${tenantId}/me/image`, { 
        params: this._commonService.buildParams(params),
        responseType: 'blob' as 'json' 
      }).pipe(
        shareReplay(1),
        catchError(error => this._commonService.hanleErrorImage(error))
      );

      this.userImageCache.set(cacheKey, requestObservable);
    }
    return this.userImageCache.get(cacheKey).pipe(
      map(response => {
        if (response) {
          let urlCreator = window.URL || window.webkitURL;
          if (!safe) {
            return urlCreator.createObjectURL(response);
          }
          return this._domSanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(response));
        }
        return null;
      })
    );
  }

  listNotifications$(tenantId: string, notificationRequest?: NotificationRequest): Observable<Notification[]> {
    return this._http.get<Notification[]>(`${VECTORE_API_BASE_URL}/${tenantId}/me/notifications`, {
      params: this._commonService.buildParams(notificationRequest)
    })
    .pipe(
      shareReplay(1),
      catchError(error => this._commonService.handleErrorForGetNotFound(error))
    );
  }

  updateNotification$(tenantId: string, notificationId: string, notificationRequest: NotificationRequest): Observable<Notification> {
    return this._http.patch<Notification>(`${VECTORE_API_BASE_URL}/${tenantId}/me/notifications/${notificationId}`, notificationRequest)
    .pipe(
      shareReplay(1),
      catchError(error => this._commonService.handleError(error))
    );
  }

  markAsReadNotifications$(tenantId: string) : Observable<HttpResponse<Object>> {
    return this._http.post<Object>(`${VECTORE_API_BASE_URL}/${tenantId}/me/notifications/mark-as-read`, null, { observe: 'response' })
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }
}