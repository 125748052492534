<div class="modal-confirm p-4 text-center">
    <p>{{content | translate}}</p>
    
    <button type="button" class="btn btn-default" (click)="emitTrue()">
        {{'ACTIONS.YES' | translate}}
    </button>

    <button type="button" class="btn-gs-primary" (click)="confirmModal.hide()">
        {{'ACTIONS.NO' | translate}}
    </button>
</div>