import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { Invoice } from 'src/app/shared/models/invoice/invoice';
import { InvoiceFilter } from 'src/app/shared/models/invoice/invoice-filter';
import { InvoiceResponse } from 'src/app/shared/models/invoice/invoice-response';
import { TaskInvoice } from 'src/app/shared/models/task-cost';
import { TaskInvoicesFilter } from 'src/app/shared/models/tasks/task-filter';
import { TaskInvoiceResponse } from 'src/app/shared/models/tasks/task-response';
import { InvoicesService } from 'src/app/shared/api-services/invoices.service';
import { TasksService } from 'src/app/shared/api-services/tasks.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';

@Component({
  selector: 'task-link-invoices',
  templateUrl: './task-link-invoices.component.html',
  styleUrls: ['./task-link-invoices.component.scss']
})
export class TaskLinkInvoicesComponent implements OnInit, OnDestroy {
  tenantId: string = this._tenantService.getTenantId();
  @Input() taskId: string;

  invoicesToShow: Invoice[] = [];
  listInvoicesLinkedToTask: TaskInvoice[] = [];

  form: FormGroup;

  private _destroy$ = new Subject<void>();

  constructor(
    private _tenantService: TenantService,
    private _tasksService: TasksService,
    private _invoicesService: InvoicesService,
  ) {
    this.form = new FormGroup({
      invoice: new FormControl(''),
    });
  }

  ngOnInit(): void {
    let params : InvoiceFilter = {
      includeSupplier: true
    }
    this._invoicesService.listInvoices$(this.tenantId, params)
    .pipe(takeUntil(this._destroy$))
    .subscribe((invoices: InvoiceResponse) => {
      this.invoicesToShow = invoices.items;
    });

    this.getInvoicesLinkedToTask();
  }

  private getInvoicesLinkedToTask() {
    let taskInvoicesParams: TaskInvoicesFilter = {
      taskId: this.taskId,
      includeInvoice: true,
      includeSupplier: true
    };
    this._tasksService.listTaskInvoices$(this.tenantId, taskInvoicesParams)
    .pipe(takeUntil(this._destroy$))
    .subscribe((taskInvoices: TaskInvoiceResponse) => {
      this.listInvoicesLinkedToTask = taskInvoices.items;
    });
  }

  addInvoiceToTask($event: Invoice) {
    let taskInvoice: TaskInvoice = {
      invoiceId: $event.id,
      taskId: this.taskId,
    }
    this._tasksService.createTaskInvoice$(this.tenantId, taskInvoice)
    .pipe(takeUntil(this._destroy$))
    .subscribe(() => {
      this.getInvoicesLinkedToTask();
    });
  }

  onDelete(id: string) {
    this._tasksService.deleteTaskInvoice$(this.tenantId, id)
    .pipe(takeUntil(this._destroy$))
    .subscribe(() => {
      this.getInvoicesLinkedToTask();
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
