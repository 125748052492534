import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, catchError, switchMap, throwError } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

@Injectable()
export class VectoreAuthInterceptor implements HttpInterceptor {
  constructor(
    private _authenticationService: AuthenticationService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isApiRequest(request)) {
      return this._authenticationService.getAccessTokenOidc().pipe(
        switchMap(token => this.handleToken(request, token, next)),
        catchError(error => this.handleError(error))
      );
    }
    return next.handle(request);
  }

  private isApiRequest(request: HttpRequest<any>): boolean {
    return request.url.includes('api') && !request.url.includes('idp') && !request.url.includes('token') && !request.url.includes('refresh-token');
  }

  private handleToken(request: HttpRequest<any>, tokenFromOidc: string, next: HttpHandler): Observable<HttpEvent<any>> {
    if (tokenFromOidc) {
      request = this.cloneRequestWithToken(request, tokenFromOidc);
    } else {
      const tokenAuthVectore = this._authenticationService.getAccessTokenVectoreAuth();
      if (tokenAuthVectore) {
        request = this.cloneRequestWithToken(request, tokenAuthVectore);
      } else {
        const tokenVectore = this._authenticationService.getVectoreTokenDirect();
        if (tokenVectore) {
          request = this.cloneRequestWithToken(request, tokenVectore);
        }
      }
    }
    return next.handle(request);
  }

  private cloneRequestWithToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private handleError(error: any): Observable<never> {
    return throwError(() => error);
  }
}