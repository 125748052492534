<h5 class="mt-2 fs-4" *ngIf="documents?.length > 0">{{'OUTPUT_UPLOAD_FILES.TITLE' | translate}}</h5>

<div *ngFor="let document of documents" class="d-flex justify-content-between align-items-center mt-2">

    <div class="d-flex align-items-center" type="button" (click)="downloadFile(document.id, document.name)">
        <i class="bi bi-file-earmark text-gs-primary fs-2"></i>
        <span class="ms-2 text-gs-primary">{{document.name}}</span>
    </div>

    <div>
        <div type="button" (click)="openModal(document)">{{'ACTIONS.DELETE' | translate}}</div>
    </div>

</div>