import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DropdownOption } from '../../../dropdown/dropdown-primary/dropdown.interface';
import { Observable, Observer, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { FilterModalService } from '../filter-modal.service';
import { DropdownPrimaryVehicleTypeComponent } from '../../../dropdown/dropdown-primary/dropdown-primary-vehicle-type/dropdown-primary-vehicle-type.component';
import { HeadquartersService } from 'src/app/shared/api-services/headquarters.service';
import { HeadquarterResponse } from 'src/app/shared/models/headquarters';


@Component({
  selector: 'filter-vehicle-modal',
  templateUrl: './filter-vehicle-modal.component.html',
  styleUrls: ['./filter-vehicle-modal.component.scss']
})
export class FilterVehicleModalComponent implements OnInit{
  id = 'filter-vehicle-modal';
  private tenantId = this._tenantService.getTenantId();
  form: FormGroup;

  @Input() autoCompleteParams: any = {};
  headquarterName$: Observable<string[]>;
  locationName$: Observable<string[]>;

  @ViewChild(DropdownPrimaryVehicleTypeComponent) dropdownPrimaryTaskTypeComponent: DropdownPrimaryVehicleTypeComponent;

  constructor(
    private _tenantService: TenantService,
    private _headquartersService: HeadquartersService,
    private _filterTaskModalService: FilterModalService,
    public bsModalRef: BsModalRef
  ) {
    this.form = new FormGroup({
      vehicleTypeId: new FormControl(null),

      headquarterId: new FormControl(null),
      headquarterName: new FormControl(null),

      locationId: new FormControl(null),
      locationName: new FormControl({ value: null, disabled: true }),
    });
  }
  
  ngOnInit() {
    let form = this._filterTaskModalService.getForm(this.id);
    if (form) {
      this.form = form;
    }
    this.autoComplete();
  }

  private autoComplete() {
    this.headquarterName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['headquarterName'].value);
    }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._headquartersService.listHeadquarters$(this.tenantId, 
        {nameContains: token, limit: 5, ...this.autoCompleteParams})),
      map((response : HeadquarterResponse) => {
        let displayNames : string[] = [];
        response.items.forEach((headquarter) => {
          displayNames.push(headquarter.name);
        });
        return displayNames;
      }),
    );

    this.locationName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['locationName'].value);
    }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._headquartersService.listLocations$(this.tenantId, 
        {nameContains: token, headquarterId: this.form.value.headquarterId, limit: 5, ...this.autoCompleteParams})),
      map((response : HeadquarterResponse) => {
        let displayNames : string[] = [];
        response.items.forEach((headquarter) => {
          displayNames.push(headquarter.name);
        });
        return displayNames;
      }),
    );
  }
   
  onVehicleTypeSelected($event: DropdownOption) {
    this.form.patchValue({ vehicleTypeId: $event.value });
  }

  onSelectHeadquarter(match: TypeaheadMatch) {
    const name = match.value;    
    this._headquartersService.listHeadquarters$(this.tenantId, { name: name }).subscribe({
      next: (response) => {
        if (response.items.length > 0) {
          this.form.patchValue({ 
            headquarterId: response.items[0].id,
            headquarterName: response.items[0].name,
          });
          this.form.controls['locationName'].enable();
        }
      }
    });
  }  

  onSelectLocation(match: TypeaheadMatch) {
    const name = match.value;    
    this._headquartersService.listLocations$(this.tenantId, { name: name }).subscribe({
      next: (response) => {
        if (response.items.length > 0) {
          this.form.patchValue({ 
            locationId: response.items[0].id,
            locationName: response.items[0].name,
          });
        }
      }
    });
  } 

  apply() {
    this._filterTaskModalService.applyFilterFromForm(this.id, this.form);
    this.bsModalRef.hide();
  }

  reset() {
    this.form.reset();
    this.form.controls['locationName'].disable();
    this.dropdownPrimaryTaskTypeComponent.onReset();
    this._filterTaskModalService.applyFilterFromForm(this.id, this.form);
  }
}