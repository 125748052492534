import * as Sentry from "@sentry/angular-ivy";
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DropdownOption } from '../../../dropdown/dropdown-primary/dropdown.interface';
import { Observer, Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { AssetsService } from 'src/app/shared/api-services/assets.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { Asset } from 'src/app/shared/models/asset';
import { TasksService } from 'src/app/shared/api-services/tasks.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { UsersService } from 'src/app/shared/api-services/users.service';
import { VehiclesService } from 'src/app/shared/api-services/vehicles.service';
import { Vehicle } from 'src/app/shared/models/vehicle';
import { UserModel, UserModelResponse } from 'src/app/shared/models/users';
import { TaskFieldsResp, TaskRequest, TaskType } from 'src/app/shared/models/task';
import { TranslateService } from '@ngx-translate/core';
import { Booking } from "src/app/shared/models/booking";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { DateUtilsService } from "src/app/shared/utilities/date-utils.service";
import { ExtraFieldsUtilsService } from "src/app/shared/utilities/extra-fields-utils.servic";
import { TypeExtraField } from "src/app/shared/models/common";
import { CommunicationService } from "src/app/shared/utilities/comunication.service";


@Component({
  selector: 'task-create-modal',
  templateUrl: './task-create-modal.component.html',
  styleUrls: ['./task-create-modal.component.scss']
})
export class TaskCreateModalComponent {
  tenantId: string;
  taskId: string;

  taskRequest : TaskRequest;

  taskTypes: TaskType[];
  taskType: TaskType;

  @Input() booking: Booking;
  @Input() vehicle: Vehicle;
  @Input() asset: Asset;
  @Input() user: UserModel;
  
  @Input() headquarterId: string;

  @Input() hasTCO: boolean;

  @Input() taskTypeSelected: DropdownOption;
  taskTypesOptions: DropdownOption[] = [];

  taskForm: FormGroup;

  assetNames$: Observable<string[]>;
  userNames$: Observable<string[]>;
  vehicleDisplayName$: Observable<string[]>;

  fields: FormlyFieldConfig[];
  typeExtraFields: TypeExtraField[];

  isMobile: boolean = false;

  viewMobileSubscription: Subscription; 

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private _cdr: ChangeDetectorRef,
    private _dateUtilsService: DateUtilsService,
    private _translateService: TranslateService,
    private _tenantService: TenantService,
    private _tasksService: TasksService,
    private _assetsService: AssetsService,
    private _usersService: UsersService,
    private _vehicleService: VehiclesService,
    private _extraFieldsUtilsService: ExtraFieldsUtilsService,
    private _communicationService: CommunicationService,
    public bsModalRef: BsModalRef
  ) 
  {
    this.tenantId = this._tenantService.getTenantId();
    
    this.taskForm = new FormGroup({
      taskTitle: new FormControl(null, Validators.required), 
      taskTypeId: new FormControl(null, Validators.required),
      headquarterId: new FormControl(null, Validators.required),
      assetName: new FormControl(),
      userName: new FormControl(),
      vehicleDisplayName: new FormControl(),
      usageStartDate: new FormControl(),
      usageEndDate: new FormControl(),
    });
  }

  ngOnInit(): void {
    this.viewMobileSubscription = this._breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });   

    this.taskTypeSelected = {
      value: '',
      text: this._translateService.instant('SELECT_OPTION')
    }
    
    this.getTaskTypes();
    this.autoCompletes();
    this.setFields();
  }

  getTaskTypes() {
    this._tasksService.listTaskTypes$(this.tenantId).subscribe({
      next: (taskTypesResp) => {
        this.taskTypes = taskTypesResp.items;
        this.taskTypesOptions  = taskTypesResp.items.map(taskType => ({
          value: taskType.id,
          text: this._translateService.instant(taskType.name)
        }));
      }
    });
  }

  onCreateTask() {
    if (this.taskForm.invalid) {
      return;
    }

    let assetName = this.taskForm.controls['assetName'].value;
    let vehicleDisplayName = this.taskForm.controls['vehicleDisplayName'].value;
    let userName = this.taskForm.controls['userName'].value;
    let allFieldsFromForm = this.taskForm.value;

    this.taskRequest = {
      headquarterId : this.taskForm.controls['headquarterId'].value,
      taskTypeId : this.taskForm.controls['taskTypeId'].value,
      title : this.taskForm.controls['taskTitle'].value,
    }

    if (this.taskForm.controls['usageStartDate'].value) {
      this.taskRequest.usageStartDate = this._dateUtilsService.parseDataToString(this.taskForm.controls['usageStartDate'].value);
    }

    if (this.taskForm.controls['usageEndDate'].value) {
      this.taskRequest.usageEndDate = this._dateUtilsService.parseDataToString(this.taskForm.controls['usageEndDate'].value);
    }

    if (this.booking) {
      this.taskRequest.bookingId = this.booking.id;
    }

    if (this.typeExtraFields.length > 0) {
      this.taskRequest = this._extraFieldsUtilsService.updateRequestFromForm(this.typeExtraFields, this.taskForm.value, this.taskRequest);
      this.taskRequest.extraFields = this._extraFieldsUtilsService.parseBool(this.typeExtraFields, this.taskRequest.extraFields);
    }

    if (!this.taskRequest.extraFields) {
      this.taskRequest.extraFields = {};
    }

    this._assetsService.listAssets$(this.tenantId, {displayName : assetName, limit: 1}).pipe(
      switchMap((assetResp) => {             

        if (assetResp.count > 0 && assetResp.items[0].displayName == assetName) {
          this.taskRequest.assetId = assetResp.items[0].id;
        }

        return this._vehicleService.listVehicles$(this.tenantId, {displayName : vehicleDisplayName, limit: 1});
      }),
      switchMap((vehicleResp) => {

        if (vehicleResp.count > 0 && vehicleResp.items[0].displayName == vehicleDisplayName) {
          this.taskRequest.vehicleId = vehicleResp.items[0].id;
        }

        return this._usersService.listUsers$(this.tenantId, {displayName : userName, limit: 1});
      }),
      switchMap((userResp) => {

        if (userResp.count > 0 && userResp.items[0].displayName == userName) {
          this.taskRequest.userId = userResp.items[0].id;
        }

        return this._tasksService.createTask$(this.tenantId, this.taskRequest);
      }),
    ).subscribe({
      next: (taskResponse) => {
        if (taskResponse?.id) {
          this.taskId = taskResponse.id;
          this._communicationService.sendEvent();        
          this.bsModalRef.hide();
        }
      }
    });
  }

  autoCompletes() {

    this.assetNames$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.taskForm.controls['assetName'].value);
    }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => {
        let taskTypeId = this.taskForm.controls['taskTypeId'].value;
        let headquarterId = this.taskForm.controls['headquarterId'].value;
        return this._assetsService.listAssets$(this.tenantId, 
          {displayNameContains: token, limit: 5, taskTypeId, headquarterId });
      }),
      map((response: any) => {
        let assetNames : string[] = [];
        response.items.forEach((asset: any) => {
          assetNames.push(asset.displayName);
        });
        return assetNames;
      }),
    );

    this.userNames$ = new Observable<string | undefined>((observer: Observer<string | undefined>) => {
      observer.next(this.taskForm.controls['userName'].value);
     }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => {
         let taskTypeId = this.taskForm.controls['taskTypeId'].value;
         let headquarterId = this.taskForm.controls['headquarterId'].value;
         return this._usersService.listUsers$(this.tenantId, 
           {displayNameContains: token, limit: 5, taskTypeId, headquarterId });
      }),
      map((users: UserModelResponse) => {
         let userNames: string[] = [];
         users.items.forEach((user) => {
           userNames.push(user.displayName);
         });
         return userNames;
      })
     );

    this.vehicleDisplayName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.taskForm.controls['vehicleDisplayName'].value);
    }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((displayName: string) => {
        let taskTypeId = this.taskForm.controls['taskTypeId'].value;
        let headquarterId = this.taskForm.controls['headquarterId'].value;
        return this._vehicleService.listVehicles$(this.tenantId, 
          {displayNameContains: displayName, limit: 5, taskTypeId, headquarterId})
      }),
      map((response: any) => {
        let vehiclesLicensePlate : string[] = [];
        response.items.forEach((vehicle: any) => {
          vehiclesLicensePlate.push(vehicle.displayName);
        });
        return vehiclesLicensePlate;
      }),
    );

  }  

  onTaskTypeSelected($newSelectedOption: DropdownOption) {
    if ($newSelectedOption)
    {
      this.taskForm.controls['taskTypeId'].setValue($newSelectedOption.value);

      this.cleanExtraFields();
      this.getExtraFields($newSelectedOption);

      this.taskType = this.taskTypes.find(taskType => taskType.id == $newSelectedOption.value);
    }
  }

  onHeadquarterSelected($newSelectedOption: DropdownOption) {
    if ($newSelectedOption)
    {
      this.taskForm.patchValue({
        headquarterId: $newSelectedOption.value,
        assetName: null,
        userName: null,
        vehicleDisplayName: this.booking?.vehicle?.displayName ?? null,
        usageStartDate: null,
        usageEndDate: null
      });
    }
  }

  setFields() {
    if (this.headquarterId) {
      this.taskForm.controls['taskTypeId'].setValue(this.headquarterId);
    }
  }

  getExtraFields(newSelectedOption: DropdownOption) {
    this._tasksService.getTaskFields$(this.tenantId, {taskTypeId: newSelectedOption.value})
      .subscribe({
        next: (response) => {
          this.typeExtraFields = response.items.map(x => x as TypeExtraField);
          this.typeExtraFields = this.typeExtraFields.filter(field => field.required);

          this.fields = this.typeExtraFields.map(field => this._extraFieldsUtilsService.createFieldConfig(field));
          
          this._cdr.detectChanges();
        },
        error: (error) => {
          Sentry.captureEvent(error);
        }
      });
  }

  cleanFielsForm() {
    Object.keys(this.taskForm.controls).forEach(key => {
      if (!['taskTitle', 'taskTypeId', 'assetName', 'userName', 'vehicleDisplayName', 'usageStartDate', 'usageEndDate'].includes(key)) {
        this.taskForm.removeControl(key);
      }
    });
    this.fields = [];
  }

  cleanExtraFields() {
    Object.keys(this.taskForm.controls).forEach(key => {
      if (this.fields && this.fields.length > 0) {
        this.fields.forEach(field => {
          if (field.key == key) {
            this.taskForm.removeControl(key);
          }
        });
      }
    });
  }
  
  ngOnDestroy(): void {
    this.taskForm.reset();
  }
}