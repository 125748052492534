<div class="card-modal">
    <header class="card-modal-header">
        <h4 class="card-modal-title" *ngIf="this.modalStatus.isCreate">
            {{ 'SUPPLIERS.NEW_SUPPLIER' | translate }}
        </h4>

        <h4 class="card-modal-title" *ngIf="this.modalStatus.isPatch">
            {{ 'SUPPLIERS.EDIT_SUPPLIER' | translate }}
        </h4>
            
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </header>

    <content>
        <form [formGroup]="supplierForm">

            <div class="container-fleet-fields d-flex gap-3 flex-column">
                <div class="form-group">
                    <label for="name" class="form-label">
                        {{ 'SUPPLIERS.NAME_SUPPLIER' | translate }}
                        <span class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control" [ngClass]="{'is-invalid': supplierForm.get('name')?.hasError('required')}" formControlName="name">
                </div>

                <div class="form-group">
                    <label for="code" class="form-label">
                        {{ 'SUPPLIERS.code' | translate }}
                    </label>
                    <input type="text" class="form-control" formControlName="code">
                </div>

                <div class="form-group">
                    <label for="vatNumber" class="form-label">
                        {{ 'SUPPLIERS.vatNumber' | translate }}
                    </label>
                    <input type="text" class="form-control" formControlName="vatNumber">
                </div>

                <div class="form-group">
                    <label for="tag" class="form-label">{{'TAG' | translate}}</label>        
                    <input type="text" class="form-control" [placeholder]="'INSERT_TAG' | translate"
                        formControlName="tag"
                        [isAnimated]="true"
                        [typeahead]="tags$"
                        [typeaheadAsync]="true"
                        [typeaheadMinLength]="0"
                        (typeaheadOnSelect)="onTagSelected($event)">
                    
                    <div class="d-flex flex-wrap gap-2 mt-2">
                        <button type="button" class="btn-sm-gs-full-secondary d-flex align-items-center gap-1" *ngFor="let tag of selectedTags">
                            {{tag.name}}
                            <button type="button" class="btn-close pull-right" aria-label="Close" (click)="removeTag(tag.id)"></button>
                        </button>
                    </div>
                </div>

            </div>

            <div class="container-fleet-fields d-flex gap-3 flex-column mt-4" *ngIf="fields && fields?.length > 0">
                <div class="title">{{'VEHICLES.ADDITIONAL_INFORMATION' | translate}}</div>
                <formly-form [form]="supplierForm" [fields]="fields"></formly-form>
            </div>
            
        </form>
    </content>
    
    <footer>
        <div class="d-flex gap-3 justify-content-center mt-3">
            <div type="button" class="btn-gs-secondary-light" (click)="this.bsModalRef.hide()">
                {{ 'ACTIONS.CANCEL' | translate }}
            </div>
    
            <button type="submit" class="btn-gs-primary " 
                [disabled]="supplierForm.invalid" (click)="onSubmit()">
                {{ 'ACTIONS.SAVE' | translate }}
            </button>
        </div>
    </footer>
</div>