<button class="btn-add-asset" id="btn-add-asset" *ngIf="(asset | async) && !isEditMode">

    <div class="d-flex gap-2 align-items-center">
        <div class="avatar-placeholder-sm asset-placeholder">
            <img *ngIf="assetSrcImage | async as imageUrl" [src]="imageUrl" alt="asset Image">
        </div>
    
        <div class="btn-title">{{ (asset | async)?.displayName }}</div>
    </div>

    <span class="btn-remove" type="button" (click)="onDelete()">{{'ACTIONS.REMOVE' | translate}}</span>
</button>

<button class="btn-add-asset-new" id="btn-add-asset" *ngIf="!(asset | async) && !isEditMode" (click)="onAddAsset()">

    <i class="bi bi-plus"></i>

    <div>{{text | translate}}</div>
</button>

<form class="h-100" [formGroup]="form" *ngIf="isEditMode">
    <input type="text" class="form-control h-100" id="assetId" [placeholder]="'TYPE_TO_SEARCH' | translate"
        formControlName="assetDisplayName"
        [isAnimated]="true"
        [typeahead]="assetDisplayName$"
        [typeaheadAsync]="true"
        [typeaheadMinLength]="0"
        (typeaheadOnSelect)="onSelectAsset($event)">
</form>