import { Injectable } from '@angular/core';
import { DropdownOption } from '../components/dropdown/dropdown-primary/dropdown.interface';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DropdownEventsService {
  
  private options = new Map<string, Subject<DropdownOption[]>>();
  private selectedOption = new Map<string, Subject<DropdownOption>>();

  constructor() {}

  // Metodo per ottenere le opzioni del dropdown
  getOptions(dropdownId: string): Observable<DropdownOption[]> {
    if (!this.options.has(dropdownId)) {
      this.options.set(dropdownId, new BehaviorSubject<DropdownOption[]>([]));
    }
    return this.options.get(dropdownId).asObservable();
  }

  // Metodo per impostare le opzioni del dropdown
  setOptions(dropdownId: string, newOptions: DropdownOption[]): void {
    if (!this.options.has(dropdownId)) {
      this.options.set(dropdownId, new BehaviorSubject<DropdownOption[]>(newOptions));
    } else {
      this.options.get(dropdownId).next(newOptions);
    }
  }

  // Metodo per ottenere l'opzione selezionata
  getSelectedOption(dropdownId: string): Observable<DropdownOption> {
    if (!this.selectedOption.has(dropdownId)) {
      this.selectedOption.set(dropdownId, new BehaviorSubject<DropdownOption>(null));
    }
    return this.selectedOption.get(dropdownId).asObservable();
  }

  getSelectedOptionOnClick(dropdownId: string): Observable<DropdownOption> {
    if (!this.selectedOption.has(dropdownId)) {
      this.selectedOption.set(dropdownId, new Subject<DropdownOption>());
    }
    return this.selectedOption.get(dropdownId).asObservable();
  }

  // Metodo per impostare l'opzione selezionata
  setSelectedOption(dropdownId: string, newSelectedOption: DropdownOption): void {
    if (!this.selectedOption.has(dropdownId)) {
      this.selectedOption.set(dropdownId, new BehaviorSubject<DropdownOption>(newSelectedOption));
    } else {
      this.selectedOption.get(dropdownId).next(newSelectedOption);
    }
  }

  removeSubscriptions(dropdownId: string): void {
    if (this.options.has(dropdownId)) {
      this.options.get(dropdownId).unsubscribe();
      this.options.delete(dropdownId);
    }
    if (this.selectedOption.has(dropdownId)) {
      this.selectedOption.get(dropdownId).unsubscribe();
      this.selectedOption.delete(dropdownId);
    }
  }

  removeSubscriptionsAll(): void {
    this.options.forEach((subject) => subject.unsubscribe());
    this.selectedOption.forEach((subject) => subject.unsubscribe());
    this.options.clear();
    this.selectedOption.clear();
  }
  
}
