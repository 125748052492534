<div class="filters mb-3">
    <div class="filters-section-left btn-group">
      <h3 class="table-title">{{titleTable}}</h3>
      <dropdown-primary
          [(selectedOption)]="filterTask"             
          [options]="listTableFilterTasks"                
          (selectedOptionChange)="onFilterTableChange($event)" 
          [iconClass]="'bi bi-caret-down-fill text-black'"
          [cssClass]="'btn-change-table'">               
      </dropdown-primary>
    </div>
  
    <div class="filters-section-right">
      <form [formGroup]="filterForm" class="d-flex gap-2" [ngClass]="{'flex-wrap': isMobile}">
        <div class="input-group d-flex gap-1" *ngIf="filterTask?.value !== 'by_type'">
  
          <dropdown-primary
            [(selectedOption)]="filterHeader"
            [emitNgOnInit]="false"
            [options]="listTableFilterHeaders"
            (selectedOptionChange)="selectedOption($event)"
            [iconClass]="'bi bi-chevron-down'"
            [cssClass]="'btn-lightgray rounded-1'">
          </dropdown-primary>
  
          <input type="text" class="form-control rounded-1" formControlName="inputFilter">
  
        </div>
        
        <!-- <input-group-date class="mx-3 w-100" name="inputFilter" 
        [intputStartDate]="filterForm.get('startDate')" [inputEndDate]="filterForm.get('endDate')"></input-group-date> -->
        <div class="input-group text-black-50 d-flex align-items-center border rounded">
  
          <input type="text" class="from-date form-control border-0" [placeholder]="'START_DATE' | translate" 
            bsDatepicker formControlName="startDate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
          <i class="bi bi-arrow-right"></i>
  
          <input type="text" class="to-date form-control border-0" [placeholder]="'END_DATE' | translate" 
            bsDatepicker formControlName="endDate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
          <i class="bi bi-calendar-check"></i>
          
        </div>
    
        
        
        <!-- <button-first-type 
          [cssClass]="'btn-gs-secondary'"
          [iconClass]="'bi bi-plus-square'" 
          [buttonText]= "'Azioni massive'">
        </button-first-type> -->
      </form>
      
      <button-first-type 
          [cssClass]="'btn-gs-secondary-icon'"
          [iconClass]="'bi bi-funnel'"
          [buttonText]= "'FILTERR'"
          (click)="onFilterClick()">
      </button-first-type>
      
      <button-first-type *ngIf="filterTask?.value !== 'by_type'"
          [cssClass]="'btn-gs-secondary-icon'"
          [iconClass]="'bi bi-download'" 
          [buttonText]= "'EXPORT' | translate"
          (click)="onClickExport()">
      </button-first-type>

      <dropdown-primary *ngIf="filterTask?.value !== 'by_type'"
            [emitNgOnInit]="false"
            [selectedOption]="{ value: '', text: 'ACTIONS.NAME' | translate }"
            [options]="listMassiveActions"
            [isStaticSelectOption] = "true"
            (selectedOptionChange)="selectMassiveOption($event)"
            [iconClass]="'bi bi-three-dots-vertical'"
            [cssClass]="'btn-gs-primary-icon flex-row-reverse'">
      </dropdown-primary>
    </div>
  </div>