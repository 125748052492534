import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import fieldTypeMappingJson from 'src/assets/configurations/mapping-type/extrafields-configuration.json'
import { TypeExtraField } from '../models/common';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root'
})
export class ExtraFieldsUtilsService {

  constructor(
    private _datePipe: DatePipe,
    private _translate: TranslateService,
  ) { }

  createFieldConfig(field : TypeExtraField, extraFields? : any) : any {
    const fieldTypeMapping: FieldTypeMapping = fieldTypeMappingJson;
    const fieldType = fieldTypeMapping[field.type] || 'input';

    let defaultValue = null;
    if (extraFields && extraFields.hasOwnProperty(field.name)) {
      defaultValue = extraFields[field.name];
    } else if (field.type === 'Boolean') {
      defaultValue = false;
    }
  
    const fieldConfig = {
      key: field.name,
      type: fieldType,
      templateOptions: {
        label: this._translate.instant(field.name),
        required: field.required,
      },
      defaultValue: defaultValue,
    };
  
    return fieldConfig;
  }

  parseDate(listPropsFields : TypeExtraField[], extrafields : any, forView: boolean = true) : any {
    if (listPropsFields && listPropsFields.length > 0 && extrafields) {
      let fieldsNameToParseDate = listPropsFields.filter(x => x.type === 'DateTime');
      for (let key in extrafields) {
        if (fieldsNameToParseDate.find(x => x.name === key) && extrafields[key]) {
          let date = new Date(extrafields[key]);
          if (forView) {
            try {
              extrafields[key] = this._datePipe.transform(date, 'dd-MM-yyyy');
              
            } catch (error) {
              console.log(error);
              continue;
            }
          }
          else {
            extrafields[key] = date;
          }
        }
      }
    }
    return extrafields;
  }

  parseBool(listPropsFields : TypeExtraField[], extrafields : any) : any {
    if (listPropsFields && listPropsFields.length > 0 && extrafields) {
      let fieldsNameToParseBool = listPropsFields.filter(x => x.type === 'Boolean');
      for (let key in extrafields) {
        if (fieldsNameToParseBool.find(x => x.name === key)) {
          extrafields[key] = extrafields[key] ? true : false;
        }
      }
    }
    return extrafields;
  }

  parseBoolName(listPropsFields : TypeExtraField[], extrafields : any) : any {
    if (listPropsFields && listPropsFields.length > 0 && extrafields) {
      let fieldsNameToParseBool = listPropsFields.filter(x => x.type === 'Boolean');
      for (let key in extrafields) {
        if (fieldsNameToParseBool.find(x => x.name === key)) {
          extrafields[key] = extrafields[key] ? this._translate.instant('ACTIVE') : this._translate.instant('INACTIVE');
        }
      }
    }
    return extrafields;
  }

  updateRequestFromForm(typeExtraFields: TypeExtraField[], formValues: any, request: any) {
    if (!typeExtraFields || typeExtraFields.length === 0) {
      return request;
    }

    request.extraFields = request.extraFields? request.extraFields : {};

    typeExtraFields.forEach((extraField: TypeExtraField) => {
      if (formValues[extraField.name]) {
        request.extraFields[extraField.name] = formValues[extraField.name];
      }
      else {
        request.extraFields[extraField.name] = null;
      }
    });
    return request;
  }
}

interface FieldTypeMapping {
  [key: string]: string;
}