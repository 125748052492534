import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from "@sentry/angular-ivy";

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  
  handleError(error: any) {
    Sentry.captureEvent(error.originalError || error);
    throw error;
  }
}