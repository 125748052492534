import { Component, Input } from '@angular/core';
import { SidebarItem } from '../sidebar.component';

@Component({
  selector: 'sidebar-button',
  templateUrl: './sidebar-button.component.html',
  styleUrls: ['./sidebar-button.component.scss']
})
export class SidebarButtonComponent {

  @Input() listItemsSidebar : SidebarItem[];

  toggleChevron(item: any): void {
    if (item.canExpand) {
      item.isExpanded = !item.isExpanded;
    }
  }
}
