<div class="container-overview">

    <card-booking-info class="col" [bookingId]="booking.id" [isFleetManager]="isFleetManager"></card-booking-info>

    <div class="col d-flex flex-column gap-3">

        <card-booking-usages [bookingId]="booking.id">
        </card-booking-usages>

        <card-tasks [booking]="booking"></card-tasks>
        
    </div>

</div>