<button class="btn-add-user-secondary" id="btn" *ngIf="(user | async) && !isEditMode">

    <div class="avatar-placeholder-xs person-placeholder d-flex align-items-center justify-content-center">
        <img *ngIf="userSrcImage | async as imageUrl" [src]="imageUrl" alt="User Image">
    </div>

    <div>{{ (user | async)?.displayName }}</div>

    <button type="button" aria-label="Close" class="btn-close" (click)="onDelete()"></button>
</button>

<button class="btn-add-user-secondary-new" id="btn" *ngIf="!(user | async) && !isEditMode" (click)="onAddUser()">

    <i class="bi bi-plus"></i>

    <div>{{text | translate}}</div>
</button>

<form [formGroup]="form" *ngIf="isEditMode">
    <input type="text" class="form-control" id="assignedUserId" [placeholder]="'TYPE_TO_SEARCH' | translate"
        formControlName="assignedUserName"
        [isAnimated]="true"
        [typeahead]="assignedUserName$"
        [typeaheadAsync]="true"
        [typeaheadMinLength]="0"
        (typeaheadOnSelect)="onSelectAssignedUser($event)">
</form>