<button class="btn-add-vehicle" id="btn-add-vehicle" *ngIf="(vehicle | async) && !isEditMode">

    <div class="d-flex gap-2 align-items-center">
        <div class="avatar-placeholder-sm vehicle-placeholder">
            <img *ngIf="vehicleSrcImage | async as imageUrl" [src]="imageUrl" alt="Vehicle Image">
        </div>
    
        <div class="btn-title">{{ (vehicle | async)?.displayName }}</div>
    </div>

    <span class="btn-remove" type="button" (click)="onDelete()">{{'ACTIONS.REMOVE' | translate}}</span>
</button>

<button class="btn-add-vehicle-new" id="btn-add-vehicle" *ngIf="!(vehicle | async) && !isEditMode" (click)="onAddVehicle()">

    <i class="bi bi-plus"></i>

    <div>{{text | translate}}</div>
</button>

<form class="h-100" [formGroup]="form" *ngIf="isEditMode">
    <input type="text" class="form-control h-100" id="vehicleId" [placeholder]="'TYPE_TO_SEARCH' | translate"
        formControlName="vehicleDisplayName"
        [isAnimated]="true"
        [typeahead]="vehicleDisplayName$"
        [typeaheadAsync]="true"
        [typeaheadMinLength]="0"
        (typeaheadOnSelect)="onSelectVehicle($event)">
</form>