import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InputOption } from 'src/app/shared/components/buttons/button-choose/button-choose.component';
import { VehicleBooking } from 'src/app/shared/models/booking';

@Component({
  selector: 'booking-modal-vehicles-to-show',
  templateUrl: './booking-modal-vehicles-to-show.component.html',
  styleUrls: ['./booking-modal-vehicles-to-show.component.scss']
})
export class BookingModalVehiclesToShowComponent {
  @Input() vehiclesToShow: VehicleBooking[] = [];
  @Output() onChangeEmitVehicleChecked = new EventEmitter<VehicleBooking[]>;

  changeVehicleChecked($inputOption: InputOption) {
    this.vehiclesToShow.forEach((vehicle: VehicleBooking) => {
      vehicle.vehicle.isChecked = false;
    });

    this.vehiclesToShow.find((vehicle: VehicleBooking) => vehicle.vehicle.id === $inputOption.id).vehicle.isChecked = true;
    this.onChangeEmitVehicleChecked.emit(this.vehiclesToShow);
  }
}
