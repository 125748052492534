import { Component, Input } from '@angular/core';
import { TaskResp } from 'src/app/shared/models/task';
import { TasksService } from 'src/app/shared/api-services/tasks.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { ModalExtraInformation, ModalUtilsService } from "src/app/shared/utilities/modal-utils.service";
import { CommunicationService } from 'src/app/shared/utilities/comunication.service';
import { Booking } from 'src/app/shared/models/booking';
import { TaskFilter } from 'src/app/shared/models/tasks/task-filter';

@Component({
  selector: 'card-tasks',
  templateUrl: './card-tasks.component.html',
  styleUrls: ['./card-tasks.component.scss']
})
export class CardTasksComponent {

  tenantId: string;
  @Input() booking: Booking;
  listTask: TaskResp;

  constructor(
    private _tenantService: TenantService,
    private _taskService: TasksService,
    private _modalUtilsService: ModalUtilsService,
    private _communicationService: CommunicationService,
  ) { 
    this.tenantId = this._tenantService.getTenantId();
  }

  ngOnInit(): void {
    this.getTasks();
    this._communicationService.getEvent().subscribe(() => this.getTasks());
  }

  private getTasks() {
    if (this.booking?.id) {
      let params: TaskFilter = {
        bookingId: this.booking.id,
        limit: 3,
        start: 0,
        orderBy: '-Id'
      };
      this._taskService.listTasks$(this.tenantId, params).subscribe({
        next: (taskResp) => {
          this.listTask = taskResp;
        }
      });
    }
  }

  openCreateTaskModal() {
    let extraInfo : ModalExtraInformation = {
      booking: this.booking,
    };
    this._modalUtilsService.openCreateModalTask(false, extraInfo).subscribe();
  }

}
