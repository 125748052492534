import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownOption } from '../../dropdown-primary/dropdown.interface';
import { DropdownEventsService } from 'src/app/shared/utilities/dropdown-events.service';

@Component({
  selector: 'dropdown-orizontal-dots',
  templateUrl: './dropdown-orizontal-dots.component.html',
  styleUrls: ['./dropdown-orizontal-dots.component.scss']
})
export class DropdownOrizontalDotsComponent {
  @Input() options!: DropdownOption[];
  @Input() dropdownId!: string;

  constructor(
    private dropdownEventsService: DropdownEventsService
  ) { }

  selectOption(option: DropdownOption): void {
    if (this.dropdownId) {
      this.dropdownEventsService.setSelectedOption(this.dropdownId, option);
    }
  }
}