<div class="modal-gs p-3 d-flex flex-column gap-3">
    <header class="d-flex justify-content-between align-items-center">
        <h4 class="modal-title fs-5 pull-left">{{'CABINET_KEYS.TITLE' | translate}}</h4>
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </header>

    <div class="modal-gs-body my-4">

        <div class="d-flex flex-column gap-3 mb-4">
            <div class="d-flex justify-content-center gap-2">
                <i class="bi bi-key default-icon bg-icon-azure-light p-1"></i>
                <div class="title-small">{{ cabinet?.driverCabinetName }}</div>
            </div>

            <div class="d-flex justify-content-center align-items-center gap-2 text-center">
                <div class="title-small color-primary">{{ cabinetKey?.driverKeyName }}</div>
                <i class="bi bi-arrow-right color-primary"></i>
                <div class="title-small color-secondary">{{ 'CABINET_KEYS.LINK_VEHICLE' | translate }}</div>
            </div>
        </div>

        <form class="d-flex flex-column gap-3" [formGroup]="form">

            <div class="form-group">
                <label for="name" class="form-label">{{'LICENSE_PLATE' | translate}}</label>
                <input type="text" class="form-control h-100" id="vehicleId" [placeholder]="'TYPE_TO_SEARCH' | translate"
                    formControlName="vehicleDisplayName"
                    [isAnimated]="true"
                    [typeahead]="vehicleDisplayName$"
                    [typeaheadAsync]="true"
                    [typeaheadMinLength]="0"
                    (typeaheadOnSelect)="onSelect($event)">
            </div>

        </form>
    </div>

    <footer class="d-flex gap-2 align-items-center justify-content-center">
        <button type="button" class="btn-gs-secondary" (click)="bsModalRef.hide()">{{'ACTIONS.CANCEL' | translate}}</button>
        <button type="button" class="btn-gs-primary" (click)="update()">{{'ACTIONS.EDIT' | translate}}</button>
    </footer>
</div>