import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

export class CustomTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient, public resources: string[]) {}

  public getTranslation(lang: string): Observable<any> {
    const requests = this.resources.map(resource => {
      return this.http.get(`assets/i18n/${resource}.${lang}.json`);
    });

    return forkJoin(requests).pipe(
      map(response => {
        return response.reduce((a, b) => {
          return Object.assign(a, b);
        });
      })
    );
  }
}