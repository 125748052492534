<div class="filters mb-3">
    <div class="filters-section-left btn-group">
        <h3 class="table-title">{{ titleTable | translate}}</h3>
        <dropdown-primary
            [(selectedOption)]="filterBooking"             
            [options]="listTableFilterBookings"                
            (selectedOptionChange)="onFilterTableChange($event)" 
            [iconClass]="'bi bi-caret-down-fill text-black'"
            [cssClass]="'btn-change-table bg-trasparent border-0 text-black-50'">               
        </dropdown-primary>
    </div>
  
    <div class="filters-section-right">
      <form [formGroup]="filterForm">
        <div class="input-group text-black-50 d-flex align-items-center border rounded">
          <input type="text" class="from-date form-control border-0" [placeholder]="'START_DATE' | translate" bsDatepicker formControlName="startDate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
          <i class="bi bi-arrow-right"></i>
          <input type="text" class="to-date form-control border-0" [placeholder]="'END_DATE' | translate" bsDatepicker formControlName="endDate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
          <i class="bi bi-calendar-check"></i>
        </div>
      </form>

      <button-first-type 
          [cssClass]="'btn-gs-secondary-icon'"
          [iconClass]="'bi bi-funnel'"  
          [buttonText]= "'FILTERR'"
          (click)="onFilterClick()">
      </button-first-type>

      <button-first-type 
        [cssClass]="'btn-gs-secondary-icon'"
        [iconClass]="'bi bi-download'" 
        [buttonText]= "'EXPORT' | translate"
        (click)="onClickExport()">
      </button-first-type>

      <dropdown-primary 
        [emitNgOnInit]="false"
        [selectedOption]="{ value: '', text: 'ACTIONS.NAME' | translate }"
        [options]="listMassiveActions"
        [isStaticSelectOption] = "true"
        (selectedOptionChange)="selectMassiveOption($event)"
        [iconClass]="'bi bi-three-dots-vertical'"
        [cssClass]="'btn-gs-primary-icon flex-row-reverse'">
      </dropdown-primary>
    </div>
  </div>