import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { DropdownOption } from '../dropdown.interface';
import { TasksService } from 'src/app/shared/api-services/tasks.service';
import { TaskFilter, TaskTypeFilter } from 'src/app/shared/models/tasks/task-filter';

@Component({
  selector: 'dropdown-primary-task-type',
  template: `
    <dropdown-primary
      class="w-100"
      [selectedOption]="optionSelected"
      [options]="listOptions"
      (selectedOptionChange)="onSelected($event)"
      [emitNgOnInit]="false"
      [activeScrollbar]="true"
      [iconClass]="'bi bi-chevron-down'"
      [cssClass]="'btn-dropdown'">
    </dropdown-primary>
  `,
})
export class DropdownPrimaryTaskTypeComponent {
  @Input() taskTypeId: string;
  @Input() selectOption: string;
  @Input() filterTaskType: TaskTypeFilter;

  tenantId: string = this._tenantService.getTenantId();

  defaultOption: DropdownOption;

  optionSelected: DropdownOption;
  listOptions: DropdownOption[];

  @Output() onSelectedEvent = new EventEmitter<DropdownOption>();

  constructor(
    private _tenantService: TenantService,
    private _tasksService: TasksService,
    private _translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.defaultOption = {
      value: '',
      text: this.selectOption ??= this._translateService.instant('SELECT_OPTION')
    };
    this.optionSelected = this.defaultOption;
    this.getData();
  }

  ngOnChanges() {
    if (!this.listOptions) {
      this.getData();
    }
  }

  getData() {
    let params : TaskFilter = {
      orderBy: 'Name',
      ...this.filterTaskType
    }
    this._tasksService.listTaskTypes$(this.tenantId, params).subscribe({
      next: response => {
        this.listOptions = response.items.map(item => {
          return <DropdownOption> {
            value: item.id,
            text: item.name,
            extraField: {
              text: item.name
            }
          }
        });

        if (this.taskTypeId) {
          this.optionSelected = this.listOptions.find(
            option => option.value === this.taskTypeId
          );
          this.onSelectedEvent.emit(this.optionSelected);
        } else {
          this.optionSelected = this.defaultOption;
        }
      }
    });
  }

  onSelected(optionSelected$: DropdownOption) {
    this.onSelectedEvent.emit(optionSelected$);
  }

  onReset() {
    this.optionSelected = this.defaultOption;
  }
}
