import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import * as RoutesUrl from 'src/app/shared/constant/app-routes';
import { authGuardFn } from './core/auth.guard';

const routes: Routes = [
  { path: RoutesUrl.HOME, loadChildren: () => import('./pages/homepage/homepage.module').then(m => m.HomepageModule), canActivate: [authGuardFn] },
  { path: RoutesUrl.DASHBOARD, loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [authGuardFn] },

  { path: RoutesUrl.VEHICLES, loadChildren: () => import('./pages/vehicles/veicoli.module').then(m => m.VeicoliModule), canActivate: [authGuardFn] },
  { path: RoutesUrl.VEHICLE_USAGES, loadChildren: () => import('./pages/vehicle-usages/vehicle-usages.module').then(m => m.VehicleUsagesModule), canActivate: [authGuardFn] },

  { path: RoutesUrl.ASSETS, loadChildren: () => import('./pages/assets/assets.module').then(m => m.AssetsModule), canActivate: [authGuardFn] },
  { path: RoutesUrl.PERSONS, loadChildren: () => import('./pages/persons/persons.module').then(m => m.PersonsModule), canActivate: [authGuardFn] },
  { path: RoutesUrl.PERSONS_CREATE, loadChildren: () => import('./pages/persons/persons.module').then(m => m.PersonsModule), canActivate: [authGuardFn] },

  { path: RoutesUrl.COSTS, loadChildren: () => import('./pages/costs/costs.module').then(m => m.CostsModule), canActivate: [authGuardFn] },
  { path: RoutesUrl.TASKS, loadChildren: () => import('./pages/task/task.module').then(m => m.TaskModule), canActivate: [authGuardFn] },
  { path: RoutesUrl.INVOICES, loadChildren: () => import('./pages/invoices/invoices.module').then(m => m.InvoicesModule), canActivate: [authGuardFn] },
  { path: RoutesUrl.SUPPLIERS, loadChildren: () => import('./pages/suppliers/suppliers.module').then(m => m.SuppliersModule), canActivate: [authGuardFn] },
  
  { path: RoutesUrl.FLEETMANAGER_BOOKING, loadChildren: () => import('./pages/bookings/fleetmanager-booking/fleetmanager-booking.module').then(m => m.FleetmanagerBookingModule), canActivate: [authGuardFn] },
  { path: RoutesUrl.MYBOOKINGS, loadChildren: () => import('./pages/bookings/my-bookings/my-bookings.module').then(m => m.MyBookingsModule), canActivate: [authGuardFn] },

  { path: RoutesUrl.REPORTS, loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule), canActivate: [authGuardFn] },
  { path: RoutesUrl.FLEETLOCATION, loadChildren: () => import('./pages/fleetlocation/fleetlocation.module').then(m => m.FleetlocationModule), canActivate: [authGuardFn] },
  
  { path: RoutesUrl.SETTINGS, loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule), canActivate: [authGuardFn] },

  { path: RoutesUrl.IMPORTS, loadChildren: () => import('./pages/imports/imports.module').then(m => m.ImportsModule), canActivate: [authGuardFn] },

  { path: RoutesUrl.ASSISTANCE, loadChildren: () => import('./pages/assistance/assistance.module').then(m => m.AssistanceModule), canActivate: [authGuardFn] },
  { path: RoutesUrl.UPGRADE, loadChildren: () => import('./pages/upgrade/upgrade.module').then(m => m.UpgradeModule), canActivate: [authGuardFn] },
  
  { path: RoutesUrl.REGISTRATION, loadChildren: () => import('./pages/registration/registration.module').then(m => m.RegistrationModule), canActivate: [authGuardFn] },

  { path: RoutesUrl.LOGIN, loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  { path: RoutesUrl.NO_SERVICE, loadChildren: () => import('./pages/no-service/no-service.module').then(m => m.NoServiceModule) },

  { path: '**', redirectTo: RoutesUrl.HOME },   
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
