<div class="card-task-duplicate">

    <div class="d-flex gap-2 align-items-center">       
        <i class="bg-icon-primary-light bi-arrow-repeat default-icon p-1"></i>
        <div class="fw-500" [innerHTML]="'TASKS.MODAL_EDIT_TASK.DUPLICATE_TASK' | translate | dynamicHighlight:'color-primary'"></div>
    </div>

    <dropdown-static-primary 
      [nameButton]="'DUPLICATE'"
      [cssClass]="'btn-gs-primary'"
      [iconClass]="'bi-caret-down-fill'"
      [options]="options"
      (selectedOptionChange)="onSelectedOptionChange($event)"
      >
    </dropdown-static-primary>
    
</div>