import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HeadquartersService } from 'src/app/shared/api-services/headquarters.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { DropdownOption } from '../dropdown.interface';

@Component({
  selector: 'dropdown-primary-location',
  template: `
    <dropdown-primary
      class="w-100"
      [isDisabled]="isDisabled"
      [selectedOption]="optionSelected"
      [options]="listOptions"
      (selectedOptionChange)="onSelected($event)"
      [emitNgOnInit]="false"
      [activeScrollbar]="true"
      [iconClass]="'bi bi-chevron-down'"
      [cssClass]="'btn-dropdown'">
    </dropdown-primary>
  `,
})
export class DropdownPrimaryLocationComponent {
  @Input() headquarterId: string;
  @Input() locationId: string;

  tenantId: string = this._tenantService.getTenantId();

  defaultOption: DropdownOption = {
    value: '',
    text: this._translateService.instant('SELECT_OPTION')
  };

  optionSelected: DropdownOption;
  listOptions: DropdownOption[];  

  isDisabled: boolean = true;

  @Output() onSelectedEvent = new EventEmitter<DropdownOption>();

  constructor(
    private _tenantService: TenantService,
    private _headquartersService: HeadquartersService,
    private _translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.optionSelected = this.defaultOption;    
  }

  ngOnChanges() {
    this.isDisabled =! this.headquarterId;
    this.getData();
  }

  getData() {
    let params : any = {
      headquarterId : this.headquarterId
    }
    if (this.headquarterId && this.headquarterId !== '') {
      this._headquartersService.listLocations$(this.tenantId, params).subscribe({
        next: (response) => {
          this.listOptions = response.items.map(location => ({
            value: location.id,
            text: this._translateService.instant(location.name)
          }));

          if (this.locationId) {
            this.optionSelected = this.listOptions
              .find(option => option.value === this.locationId);

            this.onSelectedEvent.emit(this.optionSelected);
          }
        }
      });
    }
  }

  onSelected(optionSelected$: DropdownOption) {
    this.onSelectedEvent.emit(optionSelected$);
  }
}