import * as Sentry from "@sentry/angular-ivy";
import * as RoutesUrl from 'src/app/shared/constant/app-routes';
import { Injectable } from '@angular/core';
import { AuthOptions, LoginResponse, LogoutAuthOptions, OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable, catchError, map, of, switchMap, takeUntil, tap, throwError } from 'rxjs';
import { TenantService } from '../../shared/api-services/tenant.service';
import { TokenResp } from '../../shared/models/tokenResp';
import { TokenService } from '../../shared/api-services/token.service';
import { AuthService } from '../../shared/api-services/auth.service';
import { SignupForm } from '../../shared/models/signup-form';
import { Router } from "@angular/router";
import { IdpResponse } from "src/app/shared/models/idbResponse";
import { ToastrService } from "ngx-toastr";
import { MeService } from "src/app/shared/api-services/new-me.service";
import { TranslateService } from "@ngx-translate/core";


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  tenantId: string = this._tenantService.getTenantId();
  private tokenSubject = new BehaviorSubject<TokenResp | null>(null);
  token$ = this.tokenSubject.asObservable();

  countSameLink = 0;
  previousUrl: string | undefined;
  saveUrl: {
    url: string;
    queryParams: any;
  } | null;

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private _authService: AuthService,
    private _tenantService: TenantService,
    private _translateService: TranslateService,
    private _newMeService: MeService,
    private _tokenService: TokenService,
    private _loginService: AuthService,
    private _toastrService: ToastrService,
    public router: Router,
  ) {}

  private checkAuthenticationOidc(): Observable<LoginResponse> {
    return this.oidcSecurityService.checkAuth()
    .pipe(
      catchError((err) => {
        this.router.navigate([RoutesUrl.LOGIN]);
        console.error(err);
        return of(null);
      })
    );
  }

  private checkAuthenticationVectoreAuth(): Observable<boolean> {
    return this._authService.getIdp$(this.tenantId).pipe(
      switchMap((idp: IdpResponse) => {
        if (idp.vectoreAuth) {
          let token = this._tokenService.getToken();
          let refreshToken = this._tokenService.getRefreshToken();
          if (!token || !refreshToken) {
            return of(false);
          }

          let expirationToken = this._tokenService.getExpirationToken();
          let expirationTokenDate = new Date(expirationToken);
          let now = new Date();
          if (expirationTokenDate > now) {
            return of(true);
          } else {
            return this.getRefreshTokenVectoreAuth(refreshToken);
          }
        } else {
          return of(false);
        }
      }),
      catchError(error => {
        if (error.status === 0 || error.status === 500) {
          this.router.navigate([RoutesUrl.NO_SERVICE]);
        }
        this._toastrService.error(error.error.detail, error.error.title);
        Sentry.captureEvent(error);
        return throwError(() => new Error('Error during authentication'));
      })
    );
  }

  checkAuthentication() : Observable<boolean> {
    if (this.getVectoreTokenDirect()) {
      this._newMeService.sendMe(this.tenantId);
      return of(true);
    }

    return this._authService.getIdp$(this.tenantId).pipe(
      switchMap((idpResponse) => {
        if (idpResponse.vectoreAuth) {
          return this.checkAuthenticationVectoreAuth().pipe(
            switchMap((vectoreAuthenticated) => {
              return of(vectoreAuthenticated);
            })
          );
        } else {
          return this.checkAuthenticationOidc().pipe(
            switchMap((oidcAuthResult) => {
              return of(oidcAuthResult?.isAuthenticated ?? false);
            }),
            catchError(error => {
              console.error(error);
              if (error.status === 400 || error.status === 401) {
                this._toastrService.error(this._translateService.instant('INFO_MESSAGES.ERROR_AUTH'));
                return of(false);
              } 
              if (error.status === 0 || error.status === 500) {
                this.router.navigate([RoutesUrl.NO_SERVICE]);
              }
              if (error.status === 404) {
                return of(true);
              }
              this._toastrService.error(error.error.detail, error.error.title);
              this.router.navigate([RoutesUrl.LOGIN]);
              return of(false);
            })
          );
        }
      }),
      tap((isAuthenticated) => {
        if (isAuthenticated) {
          this._newMeService.sendMe(this.tenantId);
        }
      }),
    );
  }

  checkLoop() {
    let getLink = this.router.url;
    if (getLink === this.previousUrl) {
      this.countSameLink++;
    } else {
      this.countSameLink = 0;
      this.previousUrl = getLink;
    }

    if (this.countSameLink > 10) {
      this.logout();
      this.router.navigate([RoutesUrl.LOGIN]);
      this._toastrService.error('Too many redirects. Please, try again later.', 'Error');
    }
  }

  loginOidc() {

    this._authService.getIdp$(this.tenantId).subscribe(
      (response: IdpResponse) => {
        if (response.loginParameters) {
          // add custom parameters to the logout request
          // format string is 'key1=value1&key2=value2'
          const authOptions: AuthOptions = { customParams: this.parseCustomParams(response.loginParameters) };
          this.oidcSecurityService.authorize(null, authOptions);
        }
        else {
          this.oidcSecurityService.authorize();
        }
      }
    );
  }

  logout() {
    if (this.getVectoreTokenDirect()) {
      this.logoutVectoreAuth(); 
    }

    this._authService.getIdp$(this.tenantId).subscribe(
      (response: IdpResponse) => {
        if (response.vectoreAuth) {
          this.logoutVectoreAuth();        
        }
        else {
          this.logoutOidc(response);
        }
      }
    );
  }

  private logoutOidc(idpResponse: IdpResponse) {
    if (idpResponse.logoutParameters) {
      // add custom parameters to the logout request
      // format string is 'key1=value1&key2=value2'
      const logoutAuthOptions: LogoutAuthOptions = { customParams: this.parseCustomParams(idpResponse.logoutParameters) };
      this.oidcSecurityService.logoff(null, logoutAuthOptions).pipe(
        catchError((error) => {
          console.error(error);
          Sentry.withScope(scope => {
            scope.setExtra("tenantId", this.tenantId);
            scope.setLevel("warning");
            Sentry.captureEvent(error);
          });
          return of(null);
        })
      ).subscribe();

    } else {

      this.oidcSecurityService.logoffAndRevokeTokens().pipe(
        catchError((error) => {
          this.logoutVectoreAuth();
          console.error(error);
          Sentry.withScope(scope => {
            scope.setExtra("tenantId", this.tenantId);
            scope.setLevel("warning");
            Sentry.captureEvent(error);
          });
          return of(null);
        })
      ).subscribe(); 

    }
  }

  loginVectoreAuth(signupData: SignupForm) {
    this._loginService.getToken$(signupData, this.tenantId).subscribe({
      next: (response: TokenResp) => {
        this.tokenSubject.next(response);
        this._tokenService.setTokenModel(response);
        this.router.navigate([RoutesUrl.HOME]);
      },
      error: (error) => {
        if (error.status !== 401) {
          this.router.navigate([RoutesUrl.NO_SERVICE]);
          Sentry.captureEvent(error);
        }
        
        this._toastrService.error(error.error.detail, error.error.title);
      }
    });
  }

  loginDirectToken(token: string) {
    this._tokenService.setVectoreToken(token);
    this.router.navigate([RoutesUrl.HOME]);
    this._newMeService.sendMe(this.tenantId);
  }

  private logoutVectoreAuth() {
    this._tokenService.clearAll();
    this.router.navigate([RoutesUrl.LOGIN]);
  }

  getVectoreTokenDirect() : string {
    return this._tokenService.getVectoreToken();
  }

  getAccessTokenVectoreAuth(): string {
    return this._tokenService.getToken();
  }

  getAccessTokenOidc() : Observable<string> {
    return this.oidcSecurityService.getAccessToken();
  }

  private getRefreshTokenVectoreAuth(refreshToken: string): Observable<boolean> {
    return this._loginService.getRefreshToken$(refreshToken, this.tenantId).pipe(
      tap((response: TokenResp) => {
        this.tokenSubject.next(response);
        this._tokenService.setTokenModel(response);
      }),
      map(() => true),
      catchError(error => {
        this._toastrService.error(error.error.detail, error.error.title);
        Sentry.captureEvent(error);
        this.router.navigate([RoutesUrl.LOGIN]);
        return of(false);
      })
    );
  }

  private parseCustomParams(paramsString: string): { [key: string]: string } {
    const customParams: { [key: string]: string } = {};
    const paramsArray = paramsString.split('&');
    paramsArray.forEach(param => {
       const [key, value] = param.split('=');
       customParams[key] = value;
    });
    return customParams;
   }
}
