import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs';
import { DropdownOption } from 'src/app/shared/components/dropdown/dropdown-primary/dropdown.interface';
import { Task } from 'src/app/shared/models/task';
import { TasksService } from 'src/app/shared/api-services/tasks.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { TaskUtilsService } from 'src/app/shared/utilities/task-utils.service';

@Component({
  selector: 'dropdown-status-task',
  template: `
    <dropdown-primary 
      [(selectedOption)]="taskAction"
      [emitNgOnInit]="false"
      [options]="listTaskActions"
      (selectedOptionChange)="onSelectStatusTask($event)"
      [iconClass]="'bi bi-chevron-down'"
      [cssClass]="'btn-status-task ' + task.statusClient?.cssClass">
    </dropdown-primary>
  `,
})
export class DropdownStatusTaskComponent {

  tenantId = this._tenantService.getTenantId();
  @Input() task: Task;
  taskEditForm: FormGroup;

  taskAction: DropdownOption;
  listTaskActions: DropdownOption[];

  constructor(
    private _tenantService: TenantService,
    private _taskService: TasksService,
    private _taskUtilsService: TaskUtilsService,
    private _translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.listTaskActions = this._translateService.instant('TASKS.TASK_ACTIONS');
    this.task = this._taskUtilsService.setStatusDropdownTask(this.task);
    this.setStateTaskAction();
  }

  setStateTaskAction() {
    //Task to do
    if (!this.task.endDate) {
      let taskAction = JSON.parse(JSON.stringify(this.listTaskActions[0]));
      this.taskAction = taskAction;
    }

    //Task done - closed
    if (this.task.endDate) {
      let taskAction = JSON.parse(JSON.stringify(this.listTaskActions[1]));
      this.taskAction = taskAction;
    }

    //Task canceled
    if (this.task.deleted) {
      let taskAction = JSON.parse(JSON.stringify(this.listTaskActions[2]));
      this.taskAction = taskAction;
    }
  }

  onSelectStatusTask($event: DropdownOption) {
      
    if ($event.value === 'done' && !this.task.endDate) {
      this._taskService.closeTask$(this.tenantId, this.task.id).subscribe({
        next: (task) => {
          if (task.endDate) {
            this.task.endUserId = task.endUserId;
            this.task.endDate = task.endDate;
            this.task = this._taskUtilsService.setStatusDropdownTask(this.task);
          }
        }
      });
    }

    if ($event.value === 'cancel' && !this.task.deleted) {
      this._taskService.deleteTask$(this.tenantId, this.task.id).subscribe({
        next: (response) => {
          if (response.status === 204) {
            this.task.deleted = true;
            this.task = this._taskUtilsService.setStatusDropdownTask(this.task);
          }
        }
      });
    }

    if ($event.value === 'to_do' && this.task.endDate && !this.task.deleted) {
      this._taskService.openTask$(this.tenantId, this.task.id).subscribe({
        next: (response) => {
          if (!response.endDate) {
            this.task.endDate = response.endDate;
            this.task.endUserId = response.endUserId;
            this.task = this._taskUtilsService.setStatusDropdownTask(this.task);
          }
        }
      });
    }

    if ($event.value === 'to_do' && !this.task.endDate && this.task.deleted) {
      this._taskService.unDeleteTask$(this.tenantId, this.task.id).subscribe({
        next: (response) => {
          if (!response.deleted) {
            this.task.deleted = response.deleted;
            this.task =this._taskUtilsService.setStatusDropdownTask(this.task);
          }
        }
      });
    }

    if ($event.value === 'to_do' && this.task.endDate && this.task.deleted) {
      this._taskService.unDeleteTask$(this.tenantId, this.task.id).pipe(
        switchMap((response) => {
          if (!response.deleted) {
            this.task.deleted = response.deleted;
            this.task = this._taskUtilsService.setStatusDropdownTask(this.task);
          }
          return this._taskService.openTask$(this.tenantId, this.task.id);
        })
      ).subscribe({
        next: (response) => {
          if (!response.endDate) {
            this.task.endDate = response.endDate;
            this.task.endUserId = response.endUserId;
            this.task = this._taskUtilsService.setStatusDropdownTask(this.task);
          }
        }
      });
    }
  }
}
