<sidebar *ngIf="router.url !== '/login' && router.url !== '/registration' && router.url !== '/no-service'" (mouseenter)="isHovering=true" (mouseleave)="isHovering=false"></sidebar>

<div class="container-router"
    [ngClass]="{'container-router': router.url !== '/login' && router.url !== '/registration' && router.url !== '/no-service', 'mobile-layout': isMobile}">

    <div class="container-fluid px-0">
        
        <navbar *ngIf="router.url !== '/login' && router.url !== '/registration' && router.url !== '/no-service'"></navbar>
        
        <router-outlet [ngClass]="{'router-outlet': (router.url !== '/login' && router.url !== '/registration')}"></router-outlet>
        
    </div>
</div>