
import * as Sentry from "@sentry/angular-ivy";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {  SafeUrl } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, filter, takeUntil } from 'rxjs';
import { Me } from 'src/app/shared/models/me';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import * as RoutesUrl from 'src/app/shared/constant/app-routes';
import { BsModalRef } from "ngx-bootstrap/modal";
import { CommunicationService } from "src/app/shared/utilities/comunication.service";
import { AccessUtilsService } from "src/app/shared/utilities/access-utils.servic";
import { ModalUtilsService } from "src/app/shared/utilities/modal-utils.service";
import { MeService } from "src/app/shared/api-services/new-me.service";

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {  
  tenantId: string = this._tenantService.getTenantId();
  namePage: string;

  me: Me;
  
  srcImage: Observable<SafeUrl>;
  displayName: string;
  
  isMobile: boolean = false;
  
  listItemsSidebar: NavbarMobile[];

  modalPerson: BsModalRef;

  hasNews: boolean;
  
  destroy$: Subject<void> = new Subject<void>();
  
  constructor(
    private _tenantService: TenantService,
    private _newMeService: MeService,
    private _translateService: TranslateService,
    private _communicationService: CommunicationService,
    private _router: Router,
    private _breakpointObserver: BreakpointObserver,
    private _authenticationService: AuthenticationService,
    private _accessUtilsService: AccessUtilsService,
    private _modalUtilsService: ModalUtilsService
  ) 
  {
    this._breakpointObserver
    .observe([Breakpoints.Handset])
    .pipe(takeUntil(this.destroy$))
    .subscribe(result => {
      this.isMobile = result.matches;
    });
  }
    
  ngOnInit(): void {
    this.getUrl();

    this._communicationService.getEvetToUpdateNavbar()
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: () => {
        this.getMeInformation();
      }
    });

    this.getMeInformation();
  }

  private getUrl() {
    this.setPageName(this._router.url);
    this._router.events.pipe(
      takeUntil(this.destroy$),
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      const navigationEnd = event as NavigationEnd;
      this.setPageName(navigationEnd.urlAfterRedirects);
    });
  }

  getMeInformation() {
    this._newMeService.me$
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (response: Me) => {
        if (response) {
          this.me = response;
          this.srcImage = this._newMeService.getMeImage$(this.tenantId, 32, 32, true);
          this.displayName = response?.displayName;
          this.listItemsSidebar = this._accessUtilsService.getAllNavs(response, this.isMobile);
        }
      }
    });

  }
  
  toggleChevron(item: NavbarMobile): void {
    if (item.canExpand) {
      item.isExpanded = !item.isExpanded;
    }
  }
  
  logout() {
    this._authenticationService.logout();
  }
  
  editProfileModal() {
    this._modalUtilsService.openPersonModal({ me: this.me }).subscribe();
  }
  
  setPageName(url: string): void {
    switch (true) {
      case url.includes(RoutesUrl.HOME):
      this.namePage = this._translateService.instant('NAVBAR.HOMEPAGE');
      break;
      case url.includes(RoutesUrl.DASHBOARD):
      this.namePage = this._translateService.instant('NAVBAR.DASHBOARD');
      break;
      case url.includes(RoutesUrl.VEHICLES):
      this.namePage = this._translateService.instant('VEHICLESS');
      break;
      case url.includes(RoutesUrl.ASSETS):
      this.namePage = this._translateService.instant('ASSET');
      break;
      case url.includes(RoutesUrl.PERSONS):
      this.namePage = this._translateService.instant('PEOPLE_ORGANIZATION');
      break;
      case url.includes(RoutesUrl.COSTS):
      this.namePage = this._translateService.instant('COSTS.NAME');
      break;
      case url.includes(RoutesUrl.TASKS):
      this.namePage = this._translateService.instant('TASK');
      break;
      case url.includes(RoutesUrl.FLEETMANAGER_BOOKING):
      this.namePage = this._translateService.instant('FLEET_MANAGEMENT_BOOKING');
      break;
      case url.includes(RoutesUrl.MYBOOKINGS):
      this.namePage = this._translateService.instant('MY_BOOKINGS');
      break;
      case url.includes(RoutesUrl.IMPORTS):
      this.namePage = this._translateService.instant('IMPORTS');
      break;
      case url.includes(RoutesUrl.REPORTS):
      this.namePage = this._translateService.instant('REPORT');
      break;
      case url.includes(RoutesUrl.FLEETLOCATION):
      this.namePage = this._translateService.instant('FLEET_LOCATION');
      break;
      case url.includes(RoutesUrl.SETTINGS):
      this.namePage = this._translateService.instant('NAVBAR.SETTINGS');
      break;
      default:
      this.namePage = this._translateService.instant('NAVBAR.HOMEPAGE');
      break;
    }
  }
  
  navigateToImports() {
    this._router.navigate([RoutesUrl.IMPORTS]);
  }

  collapseNavbar(): void {
    const navbar = document.getElementById('navbarScroll');
    if (navbar?.classList.contains('show')) {
      navbar.classList.remove('show');
    }
  }

  markButtonNews(news: boolean) {
    this.hasNews = news;
  }
  
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export interface NavbarMobile {
  label?: string;
  icon?: string;
  routerLink?: string;
  canExpand?: boolean;
  isExpanded?: boolean;
  children?: NavbarMobile[];
}