import { Injectable } from '@angular/core';
import { TokenResp } from '../models/tokenResp';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private tokenKey = 'token';
  private vectoreTokenKey = 'vectoreToken';
  private refreshTokenKey = 'refreshToken';
  private expirationToken = 'expirationToken';
  private tokenType = 'tokenType';
  private scope = 'scope';

  setTokenModel(tokenResp: TokenResp) {
    this.setToken(tokenResp.accessToken);
    this.setRefreshToken(tokenResp.refreshToken);
    this.setDateExpirationToken(tokenResp);
    this.setTokenType(tokenResp.tokenType);
    this.setScope(tokenResp.scope);
  }
  
  getToken(): string | null {
    return sessionStorage.getItem(this.tokenKey);
  }

  getVectoreToken(): string | null {
    return sessionStorage.getItem(this.vectoreTokenKey);
  }
  
  setToken(token: string): void {
    sessionStorage.setItem(this.tokenKey, token);
  }

  setVectoreToken(vectoreToken: string): void {
    sessionStorage.setItem(this.vectoreTokenKey, vectoreToken);
  }
  
  getRefreshToken(): string | null {
    return sessionStorage.getItem(this.refreshTokenKey);
  }
  
  setRefreshToken(refreshToken: string): void {
    sessionStorage.setItem(this.refreshTokenKey, refreshToken);
  }

  getExpirationToken(): string | null {
    return sessionStorage.getItem(this.expirationToken);
  }

  setExpirationToken(expirationToken: string): void {
    sessionStorage.setItem(this.expirationToken, expirationToken);
  }

  getTokenType(): string | null {
    return sessionStorage.getItem(this.tokenType);
  }

  setTokenType(tokenType: string): void {
    sessionStorage.setItem(this.tokenType, tokenType);
  }

  getScope(): string | null {
    return sessionStorage.getItem(this.scope);
  }

  setScope(scope: string): void {
    sessionStorage.setItem(this.scope, scope);
  }
  
  clearAll(): void {
    window.sessionStorage.clear();
  }

  private setDateExpirationToken(response: TokenResp) {
    let tokenExpirationDate = new Date();
    tokenExpirationDate.setSeconds(tokenExpirationDate.getSeconds() + response.expiresIn);
    this.setExpirationToken(tokenExpirationDate.toString());
  }
}
