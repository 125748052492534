import * as Sentry from "@sentry/angular-ivy";
import { Component, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BookingUsage, BookingUsageResp } from 'src/app/shared/models/booking';
import { BookingUsageMileageModalComponent } from '../../modals/booking-usage-mileage-modal/booking-usage-mileage-modal.component';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { BookingService } from 'src/app/shared/api-services/booking.service';
import { CommunicationService } from "src/app/shared/utilities/comunication.service";
import { UsersService } from "src/app/shared/api-services/users.service";

@Component({
  selector: 'card-booking-usages',
  templateUrl: './card-booking-usages.component.html',
  styleUrls: ['./card-booking-usages.component.scss']
})
export class CardBookingUsagesComponent {

  tenantId: string = this._tenantService.getTenantId();
  listBookingUsage : BookingUsageResp;
  @Input() bookingId: string;

  modalUsageMileage: BsModalRef;

  constructor(
    private _modalService: BsModalService,
    private _tenantService: TenantService,
    private _bookingService: BookingService,
    private _usersService: UsersService,
    private _comunicationService: CommunicationService,
  ) {}

  ngOnInit() {
    let params = {
      start: 0,
      limit: 2,
      orderby: '-Id',
    };

    this._comunicationService.getEvent().subscribe({
      next: () => {
        this.getBookingUsages(params);
      }
    });

    this.getBookingUsages(params); 
  }

  private getBookingUsages(params: { start: number; limit: number; orderby: string; }) {
    this._bookingService.getBookingUsages$(this.tenantId, this.bookingId, params).subscribe({
      next: (bookingUsagesResponse: BookingUsageResp) => {
        this.listBookingUsage = bookingUsagesResponse;

        this.listBookingUsage.items.forEach(bookingUsage => {
          if (bookingUsage.user) {
            bookingUsage.user.srcImage = this._usersService.getUserImage$(this.tenantId, bookingUsage.userId, 20, 20);
          }
        })
      }
    });
  }

  openModalMileage(bookingUsage : BookingUsage) {
    this.modalUsageMileage = this._modalService.show(BookingUsageMileageModalComponent, {
      class: 'modal-sm',
      animated: true,
      initialState: {
        bookingUsage: bookingUsage,
        bookingId: this.bookingId
      }
    });
  }
}