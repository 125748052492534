import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StorageService } from 'src/app/shared/api-services/storage.service';
import { StorageFile } from 'src/app/shared/models/storage';

@Component({
  selector: 'input-upload-files',
  templateUrl: './input-upload-files.component.html',
  styleUrls: ['./input-upload-files.component.scss']
})
export class InputUploadFilesComponent {

  selectedFile: File;
  fileId: string;

  @Input() tenantId: string;
  @Input() bucketName : string;

  @Output() fileInfo = new EventEmitter<StorageFile>();

  constructor(
    private _storageService: StorageService,
  ) { }

  uploadStorageFile(event?: Event, file?: File | null) {
      
    let formFile = new FormData();
    
    if (event instanceof Event) {
      
      const target = event.target as HTMLInputElement;
      this.selectedFile = target.files?.[0] || null;

      formFile = new FormData();
      formFile.append('formFile', this.selectedFile as Blob);
      
    } 
    else {

      this.selectedFile = file;
      formFile = new FormData();
      formFile.append('formFile', this.selectedFile as Blob);

    }     

    this._storageService.addFile$(this.bucketName, this.tenantId, formFile)
      .subscribe({
        next: (storageFileResponse) => {
          if (storageFileResponse.status === 200) {
            this.fileId = storageFileResponse.body.id;
            this.fileInfo.emit({id : this.fileId, name: this.selectedFile.name});
          }
        }
      });
  }

  handleDrop(event: DragEvent) {
    this.preventDefault(event);

    if (event.dataTransfer && event.dataTransfer.items && event.dataTransfer.items[0]) {

      const file = event.dataTransfer.items[0].getAsFile();
      if (file) {
        this.uploadStorageFile(null, file);
      }
    }    
  }

  preventDefault(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }
}
