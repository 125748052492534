import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { Observable, catchError, of, shareReplay, tap } from 'rxjs';
import { Booking, BookingDataResp, BookingFieldsResp, BookingResp, BookingType, BookingTypesResp, BookingUsage, BookingUsageResp, ReservationResp, VehicleBookingResp } from '../models/booking';
import { CommonService } from './common.service';
import { MappingModel } from '../models/users';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BookingRequest, BookingUsageRequest } from '../models/booking/booking-request';

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  
  constructor(
    private _http: HttpClient,
    private _commonService: CommonService,
    private _translateService: TranslateService,
    private _toasterService: ToastrService,
  ) { }

  getBookingTypes$(tenantId: string, params?: any): Observable<BookingTypesResp> 
  {
    return this._http.get<BookingTypesResp>(`${VECTORE_API_BASE_URL}/${tenantId}/booking-types`, { params: this._commonService.buildParams(params) });
  }
  
  getBoookingTypeById$(tenantId: string, bookingTypeId: string, params?: any): Observable<BookingType> {
    return this._http.get<BookingType>(`${VECTORE_API_BASE_URL}/${tenantId}/booking-types/${bookingTypeId}`, { params: this._commonService.buildParams(params) });
  }

  getBookingFields$(tenantId: string, params?: any): Observable<BookingFieldsResp> {
    return this._http.get<BookingFieldsResp>(`${VECTORE_API_BASE_URL}/${tenantId}/booking-fields`, { params: this._commonService.buildParams(params) });
  }

  createBooking$(tenantId: string, booking: BookingRequest): Observable<Booking> {
    return this._http.post<Booking>(`${VECTORE_API_BASE_URL}/${tenantId}/bookings`, booking )
    .pipe(
      tap(response => {
        if (response.id) {
          this._toasterService.success(this._translateService.instant('BOOKINGS.MESSAGES.SUCCESS_CREATE_BOOKING'));
        }
      }),
      catchError(error => this._commonService.handleError(error))
    );
  }

  listBookings$(tenantId: string, params?: any): Observable<BookingResp> {
    return this._http.get<BookingResp>(`${VECTORE_API_BASE_URL}/${tenantId}/bookings`, 
    { params: this._commonService.buildParams(params) })
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

  getBookingById$(tenantId: string, bookingId: string, params?: any): Observable<Booking> {
    return this._http.get<Booking>(`${VECTORE_API_BASE_URL}/${tenantId}/bookings/${bookingId}`, 
    { params: this._commonService.buildParams(params) })
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

  patchBooking$(tenantId: string, bookingId: string, booking: BookingRequest): Observable<Booking> {  
    return this._http.patch<Booking>(`${VECTORE_API_BASE_URL}/${tenantId}/bookings/${bookingId}`, booking )
    .pipe(
      shareReplay(1),
      tap(response => {
        if (response.id) {
          this._toasterService.success(this._translateService.instant('BOOKINGS.MESSAGES.SUCCESS_EDIT_BOOKING'));
        }
      }),
      catchError(error => this._commonService.handleError(error))
    );;
  }

  deleteBooking$(tenantId: string, bookingId: string): Observable<HttpResponse<Object>>  {
    return this._http.delete(`${VECTORE_API_BASE_URL}/${tenantId}/bookings/${bookingId}`, { observe: 'response' });
  }

  approveBooking$(tenantId: string, bookingId: string): Observable<HttpResponse<Object>>  {
    return this._http.post(`${VECTORE_API_BASE_URL}/${tenantId}/bookings/${bookingId}/approve`, null, { observe: 'response' });
  }

  unapproveBooking$(tenantId: string, bookingId: string): Observable<HttpResponse<Object>>  {
    return this._http.post(`${VECTORE_API_BASE_URL}/${tenantId}/bookings/${bookingId}/unapprove`, null, { observe: 'response' });
  }

  undeleteBooking$(tenantId: string, bookingId: string): Observable<HttpResponse<Object>>  {
    return this._http.post(`${VECTORE_API_BASE_URL}/${tenantId}/bookings/${bookingId}/undelete`, null, { observe: 'response' });
  }

  exportBookings$(tenantId: string, exportData: MappingModel, params?: any): Observable<HttpResponse<Blob>>  {
    return this._http.post(`${VECTORE_API_BASE_URL}/${tenantId}/export/bookings`, exportData, 
    { observe: 'response', responseType: 'blob', params: this._commonService.buildParams(params) })
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

  getVehiclesForBooking$(tenantId: string, bookingId: string, params?: any): Observable<VehicleBookingResp>  {
    return this._http.get<VehicleBookingResp>(`${VECTORE_API_BASE_URL}/${tenantId}/bookings/${bookingId}/vehicles`, 
    { params: this._commonService.buildParams(params) });
  }

  createBookingUsage$(tenantId: string, bookingId: string, bookingUsageRequest: BookingUsageRequest): Observable<BookingUsage>  {
    return this._http.post<BookingUsage>(`${VECTORE_API_BASE_URL}/${tenantId}/bookings/${bookingId}/usages`, bookingUsageRequest )
    .pipe(
      shareReplay(1),
      tap(response => {
        if (response.id) {
          this._toasterService.success(this._translateService.instant("BOOKINGS.MESSAGES.BOOKING_USAGE_CREATED"));
        }
      }),
      catchError(error => this._commonService.handleError(error))
    );
  }

  getBookingUsages$(tenantId: string, bookingId: string, params?: any): Observable<BookingUsageResp>  {
    return this._http.get<BookingUsageResp>(`${VECTORE_API_BASE_URL}/${tenantId}/bookings/${bookingId}/usages`, 
    { params: this._commonService.buildParams(params) })
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

  getBookingUsageById$(tenantId: string, bookingId: string, bookingUsageId: string, params?: any): Observable<BookingUsage>  {
    return this._http.get<BookingUsage>(`${VECTORE_API_BASE_URL}/${tenantId}/bookings/${bookingId}/usages/${bookingUsageId}`, 
    { params: this._commonService.buildParams(params) });
  } 
  
  patchBookingUsage$(tenantId: string, bookingId: string, bookingUsageId: string, bookingUsageRequest: BookingUsageRequest): Observable<BookingUsage>  {
    return this._http.patch<BookingUsage>(`${VECTORE_API_BASE_URL}/${tenantId}/bookings/${bookingId}/usages/${bookingUsageId}`, bookingUsageRequest )
    .pipe(
      shareReplay(1),
      tap(response => {
        if (response.id) {
          this._toasterService.success(this._translateService.instant("BOOKINGS.MESSAGES.BOOKING_USAGE_MILEAGE_UPDATED"));
        }
      }),
      catchError(error => this._commonService.handleError(error))
    );
  }

  closeBookingUsage$(tenantId: string, bookingId: string, bookingUsageId: string): Observable<BookingUsage>  {
    return this._http.post<BookingUsage>(`${VECTORE_API_BASE_URL}/${tenantId}/bookings/${bookingId}/usages/${bookingUsageId}/close`, null)
    .pipe(
      shareReplay(1),
      tap(response => {
        if (response.id) {
          this._toasterService.success(this._translateService.instant("BOOKINGS.MESSAGES.BOOKING_USAGE_CLOSED"));
        }
      }),
      catchError(error => this._commonService.handleError(error))
    );
  }

  getReservations$(tenantId: string, bookingId: string, params?: any): Observable<ReservationResp>  {
    return this._http.get<ReservationResp>(`${VECTORE_API_BASE_URL}/${tenantId}/bookings/${bookingId}/reservations`, 
      { params: this._commonService.buildParams(params) })
      .pipe(
        shareReplay(1),
        catchError(error => this._commonService.handleError(error))
      );
  }
  
  getBookingsData$(tenantId: string, params?: any): Observable<BookingDataResp>  {
    return this._http.get<BookingDataResp>(`${VECTORE_API_BASE_URL}/${tenantId}/booking-data`, { params: this._commonService.buildParams(params) });
  }
}
