import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of, switchMap, tap } from 'rxjs';
import { ModalUtilsService } from '../../shared/utilities/modal-utils.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root'
})
export class CentralQueryParamsService {

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _modalUtilsService: ModalUtilsService,
    private _router: Router,
  ) {
    this.subscribeToQueryParams();
  }

  private subscribeToQueryParams() {
    this._activatedRoute.queryParams.subscribe(params => {
      const taskId = params['modalEditTask'] as string;
      if (taskId) {
        this.openEditTaskModal(taskId);
      }

      const bookingId = params['modalFringeBenefit'] as string;
      if (bookingId) {
        this.openEditFringeBenefitModal(bookingId);
      }

      const createModalTask = params['createModalTask'] as string;
      if (createModalTask) {
        this.openCreateTaskModal();
      }
    });
  }

  private openCreateTaskModal() {
    let subscription = this._modalUtilsService.openCreateModalTask().pipe(
      switchMap(modal => this.handleModalEvent(modal, 'createModalTask'))
    ).subscribe({
      complete: () => {
        subscription.unsubscribe();
      }
    });
  }

  private openEditTaskModal(taskId: string) {
    let subscription = this._modalUtilsService.openEditTaskModal(taskId).pipe(
      switchMap(modal => this.handleModalEvent(modal, 'modalEditTask'))
    ).subscribe({
      complete: () => {
        subscription.unsubscribe();
      }
    });
  }

  private openEditFringeBenefitModal(bookingId: string) {
    let subscription = this._modalUtilsService.openModalFringeBenefit(bookingId).pipe(
      switchMap(modal => this.handleModalEvent(modal, 'modalFringeBenefit'))
    ).subscribe({
      complete: () => {
        subscription.unsubscribe();
      }
    });
  }

  private handleModalEvent(modal: BsModalRef<any>, key: string) {
    if (!modal) {
      return of(null);
    }
    return modal.onHide.pipe(tap(() => {
      this._router.navigate([], {
        queryParams: { [key]: null },
        queryParamsHandling: 'merge'
      });
    }));
  }
}
