<div class="modal-gs p-3 d-flex flex-column gap-3">
    <header class="d-flex justify-content-between align-items-center">
        <h4 class="modal-title fs-5 pull-left" *ngIf="modalStatus.isCreate">{{'TAGS.NEW' | translate}}</h4>
        <h4 class="modal-title fs-5 pull-left" *ngIf="modalStatus.isPatch">{{'TAGS.EDIT' | translate}}</h4>
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </header>

    <div class="modal-gs-body">
        <form class="d-flex flex-column gap-3" [formGroup]="form">

            <div class="form-group">
                <label for="name" class="form-label">{{'NAME' | translate}}</label>
                <input type="text" class="form-control" formControlName="name">
            </div>

            <button type="button" class="btn-gs-danger-noborder width-fit-content" (click)="deleteTag()" *ngIf="modalStatus.isPatch">
                {{'ACTIONS.DELETE' | translate}}
            </button>

        </form>
    </div>

    <footer class="d-flex gap-2 align-items-center justify-content-center">
        <button type="button" class="btn-gs-secondary" (click)="bsModalRef.hide()">{{'ACTIONS.CANCEL' | translate}}</button>

        <button type="button" class="btn-gs-primary" *ngIf="modalStatus.isCreate" (click)="createTag()">{{'ACTIONS.NEW' | translate}}</button>
        <button type="button" class="btn-gs-primary" *ngIf="modalStatus.isPatch" (click)="updateTag()">{{'ACTIONS.EDIT' | translate}}</button>
    </footer>
</div>