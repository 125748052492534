import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dynamicHighlight' })
export class DynamicHighlightPipe implements PipeTransform {
  transform(value: string, cssClass: string): any {
    if (!value) return value;

    // Utilizza la classe CSS fornita per sostituire il testo marcato
    return value.replace(/\[(.*?)\]/g, `<span class="${cssClass}">$1</span>`);
  }
}