import * as Sentry from "@sentry/angular-ivy";
import * as RoutesUrl from 'src/app/shared/constant/app-routes';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DropdownOption } from '../../../dropdown/dropdown-primary/dropdown.interface';
import { EMPTY, Observable, Observer, Subscription, debounceTime, distinctUntilChanged, map, of, switchMap } from 'rxjs';
import { FormControl, FormGroup,  Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { UsersService } from 'src/app/shared/api-services/users.service';
import { BookingService } from 'src/app/shared/api-services/booking.service';
import { DropdownEventsService } from 'src/app/shared/utilities/dropdown-events.service';
import { UserModelResponse } from 'src/app/shared/models/users';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Booking, VehicleBooking } from 'src/app/shared/models/booking';
import { ToastrService } from 'ngx-toastr';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { DateUtilsService } from 'src/app/shared/utilities/date-utils.service';
import { MeServiceDeprecated } from 'src/app/shared/api-services/me.service';
import { Router } from '@angular/router';
import { DatePipe } from "@angular/common";
import { CommunicationService } from "src/app/shared/utilities/comunication.service";
import { TranslateService } from "@ngx-translate/core";
import { ExtraFieldsUtilsService } from "src/app/shared/utilities/extra-fields-utils.servic";
import { TypeExtraField } from "src/app/shared/models/common";
import { SentryUtilsService } from "src/app/shared/utilities/sentry-utils.servic";
import { BookingRequest } from "src/app/shared/models/booking/booking-request";

@Component({
  selector: 'booking-modal',
  templateUrl: './booking-modal.component.html',
  styleUrls: ['./booking-modal.component.scss']
})
export class BookingModalComponent {

  tenantId: string;
  userId: string;
  @Input() booking: Booking;
  bookingRequest: BookingRequest = {};

  @Input() isFleetManager: boolean = true;

  headquarterListOptions: DropdownOption[];
  headquarterSelected: DropdownOption;

  driverListOptions: DropdownOption[];
  driverSelected: DropdownOption;

  dropdownTypeBookingId: string;

  driverNames$: Observable<string[]>;

  bookingCreateForm: FormGroup;  
  fields: FormlyFieldConfig[];

  closeUntakenAfterMinutes: string;
  subscriptions: Subscription[] = [];

  @ViewChild(BsDatepickerDirective) bsDatePicker: BsDatepickerDirective;

  isMobile: boolean = false;
  vehiclesToShow: VehicleBooking[] = [];

  modalStaus = {
    isBookingCreated: false,
    isStepOne: true,
    isStepTwo: false,
  }

  startDateUTC: string;
  endDateUTC: string;

  typeExtraFields: TypeExtraField[];
  extraFields: any;  
  hideMessageChangeVehicle: boolean = false;

  dropdownEventSubscriptions: Subscription[] = [];

  @Output() updateTable = new EventEmitter();

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private _communicationService: CommunicationService,
    private _dateUtilsService: DateUtilsService,
    private _datePipe: DatePipe,
    private _toasterService: ToastrService,
    private _dropdownEventsService: DropdownEventsService,
    private _tenantService: TenantService,
    private _meService: MeServiceDeprecated,
    private _bookingService: BookingService,
    private _usersService: UsersService,
    private _extraFieldsUtilsService: ExtraFieldsUtilsService,
    private _translateService: TranslateService,
    private _sentryUtilsService: SentryUtilsService,
    public bsModalRef: BsModalRef,
    public router: Router,
  ) {
    this.tenantId = this._tenantService.getTenantId();

    this.bookingCreateForm = new FormGroup({
      titleBooking : new FormControl(null, Validators.required),
      headquarterId : new FormControl(null, Validators.required),
      locationId : new FormControl(null, Validators.required),
      bookingTypeId : new FormControl(null, Validators.required),
      startDateBooking : new FormControl(null),
      startTimeBooking : new FormControl(null),
      endDateBooking : new FormControl(null),
      endTimeBooking : new FormControl(null),
    });
  }

  ngOnInit(): void {
    let subscribe = this._breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });

    this.cleanFielsForm();

    this.subscriptions.push(subscribe);
    
    this.dropdownTypeBookingId = 'dropdownTypeBookingId-' + this.booking?.id;
    this.getBookingTypes();
    this.autoCompletes();

    this.dropdownSubscriptions();

    if (this.isFleetManager) {
      this.bookingCreateForm.addControl('driverName', new FormControl('', Validators.required));
    }
    else {
      this.getMe();
    }

    if (this.booking) {
      this.modalStaus.isBookingCreated = true;

      this.userId = this.booking.userId;

      this.bookingCreateForm.controls['titleBooking'].setValue(this.booking.title);

      if (this.isFleetManager) {
        this.bookingCreateForm.controls['driverName'].setValue(this.booking.user.displayName);
      }

      let startDate = new Date(this.booking.startDate);
      this.bookingCreateForm.controls['startDateBooking'].setValue(startDate);
      
      let startTime = this._dateUtilsService.parseTimeToString(this.booking.startDate);
      this.bookingCreateForm.controls['startTimeBooking'].setValue(startTime);

      let endDate = new Date(this.booking.endDate);
      this.bookingCreateForm.controls['endDateBooking'].setValue(endDate);

      let endTime = this._dateUtilsService.parseTimeToString(this.booking.endDate);
      this.bookingCreateForm.controls['endTimeBooking'].setValue(endTime);

      // this.validateDates();

      this.extraFields = this.booking.extraFields;
    }
    else {
      this.setStartDateNow();
    }

    // this.bookingCreateForm.get('startDateBooking')!.valueChanges.subscribe(() => this.validateDates());
    // this.bookingCreateForm.get('startTimeBooking')!.valueChanges.subscribe(() => this.validateDates());
    // this.bookingCreateForm.get('endDateBooking')!.valueChanges.subscribe(() => this.validateDates());
    // this.bookingCreateForm.get('endTimeBooking')!.valueChanges.subscribe(() => this.validateDates());
  }

  private setStartDateNow() {
    let now = new Date();
    now.setMinutes(now.getMinutes() + 1);
    let nowDate = now.toString();
    let startTime = this._dateUtilsService.parseTimeToString(nowDate);
    this.bookingCreateForm.controls['startTimeBooking'].setValue(startTime);
    this.bookingCreateForm.controls['startDateBooking'].setValue(now);
    this.bookingCreateForm.controls['endDateBooking'].setValue(now);
  }

  getMe() {
    this._meService.getMe$(this.tenantId).subscribe({
      next: (meResp) => {
        this.userId = meResp.id;
      },
      error: (error) => {
        this._sentryUtilsService.sendEventErrorForSentry(error, "Get me in booking create modal");
      }
    });
  }

  dropdownSubscriptions() {
    let subscription = this._dropdownEventsService.getSelectedOption(this.dropdownTypeBookingId)
      .subscribe((newSelectedOption: DropdownOption) => {
        if (newSelectedOption) {
          this.bookingCreateForm.controls['bookingTypeId'].setValue(newSelectedOption.value);
          this.getExtraFields(newSelectedOption);

          this._bookingService.getBookingTypes$
        }
      });

    this.subscriptions.push(subscription);
  }

  getBookingTypes() {
    this._bookingService.getBookingTypes$(this.tenantId).subscribe({
      next: (bookingTypesResp) => {
        let listOptions: DropdownOption[] = bookingTypesResp.items.map((bookingType) => {
          return {
            value: bookingType.id,
            text: bookingType.name,
            extraField: {
              maxDurationMinutes: bookingType.maxDurationMinutes,
              checkAvailabilityBeforeMinutes: bookingType.checkAvailabilityBeforeMinutes,
              closeUntakenAfterMinutes: bookingType.closeUntakenAfterMinutes,
            }
          };
        });

        this._dropdownEventsService.setOptions(this.dropdownTypeBookingId, listOptions);

        let bookingType = listOptions[0];
        if (this.booking) {
          bookingType = listOptions.find((option) => option.value == this.booking.bookingTypeId);
        }

        this._dropdownEventsService.setSelectedOption(this.dropdownTypeBookingId, bookingType);
        let subscription = this._dropdownEventsService.getSelectedOptionOnClick(this.dropdownTypeBookingId).subscribe((newSelectedOption: DropdownOption) => {
          if (newSelectedOption)
          {
            this.bookingCreateForm.controls['bookingTypeId'].setValue(newSelectedOption.value);
            this.closeUntakenAfterMinutes = newSelectedOption.extraField?.closeUntakenAfterMinutes;
          }
        });
        this.dropdownEventSubscriptions.push(subscription);
      },
      error: (error) => {
        this._sentryUtilsService.sendEventErrorForSentry(error, "Get Booking type in booking create modal");
      }
    });

    if (this.booking && this.booking.bookingType) {
      this.bookingCreateForm.controls['bookingTypeId'].setValue(this.booking.bookingTypeId);
      this._dropdownEventsService.setSelectedOption(this.dropdownTypeBookingId, {value: this.booking.bookingTypeId, text: this.booking.bookingType.name});
    }
  }

  getExtraFields(newSelectedOption: DropdownOption) {
    this._bookingService.getBookingFields$(this.tenantId, {includeBookingTypeId: newSelectedOption.value})
      .subscribe({
        next: (response) => {
          this.typeExtraFields = response.items.map(x => x as TypeExtraField);
          this.booking = this.booking? this.booking : {};
          this.booking.extraFields = this.booking.extraFields ? this.booking.extraFields : {};
          this.booking.extraFields = this._extraFieldsUtilsService.parseDate(this.typeExtraFields, this.booking.extraFields, false);
          this.fields = this.typeExtraFields.map(field =>
            this._extraFieldsUtilsService.createFieldConfig(field, this.booking.extraFields));
        },
        error: (error) => {
          Sentry.captureEvent(error);
        }
      });
  }

  autoCompletes() {
    this.driverNames$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.bookingCreateForm.controls['driverName'].value);
    }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._usersService.listUsers$(this.tenantId, {displayNameContains: token, limit: 5})),
      map((users : UserModelResponse) => {
        let userNames : string[] = [];
        users.items.forEach((user) => {
          userNames.push(user.displayName);
        });
        return userNames;
      }),
    );
  }

  onHeadquartersSelected($event: DropdownOption) {
    this.bookingCreateForm.controls['headquarterId'].setValue($event.value);
    this.bookingCreateForm.controls['locationId'].setValue(null);
  }

  onLocationSelected($event: DropdownOption) {
    this.bookingCreateForm.controls['locationId'].setValue($event.value);
  }

  validateDates(): void {
    let startDate = this.bookingCreateForm.get('startDateBooking')!.value;
    let startTime = this.bookingCreateForm.get('startTimeBooking')!.value;
    let endDate = this.bookingCreateForm.get('endDateBooking')!.value;
    let endTime = this.bookingCreateForm.get('endTimeBooking')!.value;

    if (startDate && startTime && endDate && endTime) {
      let startDateBooking = this._dateUtilsService.combineDateAndTimeToIsoString(startDate, startTime);
      let endDateBooking = this._dateUtilsService.combineDateAndTimeToIsoString(endDate, endTime);

      if (startDateBooking && endDateBooking && startDateBooking > endDateBooking) {
        // Se le date non sono valide, imposta un errore sul formGroup
        this.bookingCreateForm.get('endDateBooking')!.setErrors({ invalidDateRange: true });
        this._toasterService.error(this._translateService.instant('BOOKINGS.MESSAGES.ERROR_STARTDATE_BOOKING'));

        this.bookingCreateForm.updateValueAndValidity();
        Sentry.withScope(scope => {
          scope.setLevel("debug");
          scope.setExtra("tenantId", this.tenantId);
          scope.setExtra("startDateBooking", startDateBooking);
          scope.setExtra("endDateBooking", endDateBooking);
          Sentry.captureMessage("Invalid date range");
        });

      } else {
        // Se le date sono valide, rimuovi l'errore se esiste
        if (this.bookingCreateForm.get('endDateBooking')!.hasError('invalidDateRange')) {
          this.bookingCreateForm.get('endDateBooking')!.setErrors(null);
        }
      }
    }

    if (startDate && startTime) {
      let startDateBooking = this._dateUtilsService.combineDateAndTimeToIsoString(startDate, startTime);
      let now = new Date().setSeconds(0,0);
      let nowUtc = new Date(now).toISOString();
      if (startDateBooking && startDateBooking < nowUtc) {
        this.bookingCreateForm.get('startDateBooking')!.setErrors({ invalidDateRange: true });
        this._toasterService.error(this._translateService.instant('BOOKINGS.MESSAGES.ERROR_STARTDATE_BOOKING_TIME'));
        this.bookingCreateForm.updateValueAndValidity();
      } else {
        if (this.bookingCreateForm.get('startDateBooking')!.hasError('invalidDateRange')) {
          this.bookingCreateForm.get('startDateBooking')!.setErrors(null);
        }
      }
    }
  }

  createRequestFromForm() {
    let bookingForm = this.bookingCreateForm.value;

    this.bookingRequest = this._extraFieldsUtilsService.updateRequestFromForm(this.typeExtraFields, bookingForm, this.bookingRequest);

    if (this.bookingRequest.extraFields) {
      this.bookingRequest.extraFields = this._extraFieldsUtilsService.parseBool(this.typeExtraFields, this.bookingRequest.extraFields);
    }

    if (bookingForm.bookingTypeId) {
      this.bookingRequest.bookingTypeId = bookingForm.bookingTypeId;
    } else {
      this._toasterService.error(this._translateService.instant('BOOKINGS.MESSAGES.ERROR_BOOKING_TYPE'));
      throw new Error('Booking type is required');
    }

    if (bookingForm.locationId) {
      this.bookingRequest.locationId = bookingForm.locationId;
    } else {
      this._toasterService.error(this._translateService.instant('BOOKINGS.MESSAGES.ERROR_LOCATION'));
    }

    if (bookingForm.startDateBooking && bookingForm.startTimeBooking) {
      this.startDateUTC = this._dateUtilsService.combineDateAndTime(bookingForm.startDateBooking, bookingForm.startTimeBooking);
    }

    if (bookingForm.endDateBooking && bookingForm.endTimeBooking) {
      this.endDateUTC = this._dateUtilsService.combineDateAndTime(bookingForm.endDateBooking, bookingForm.endTimeBooking);
    }

    this.bookingRequest.startDate = this.startDateUTC;
    this.bookingRequest.endDate = this.endDateUTC;

    if (bookingForm.titleBooking?.length > 0) {
      this.bookingRequest.title = bookingForm.titleBooking;
    }
    else {
      this._toasterService.error(this._translateService.instant('BOOKINGS.MESSAGES.ERROR_TITLE_BOOKING'));
    }

    return bookingForm;
  }

  createBooking() {
    this.createRequestFromForm();

      of(this.userId).pipe(
        switchMap((userId) => {
          if (this.isFleetManager) {
            return this._usersService.listUsers$(this.tenantId, {displayName:  this.bookingCreateForm.value.driverName})
              .pipe(
                map(users => users.items.length > 0 ? users.items[0].id : null)
              );
          } else {
            return of(userId);
          }
        }),
        switchMap((userId) => {
          if (userId) {
            this.bookingRequest.userId = userId;
            return this._bookingService.createBooking$(this.tenantId, this.bookingRequest);
          } else {
            this._toasterService.error(this._translateService.instant('INFO_MESSAGES.NO_USER_FOUND'));
            return EMPTY;
          }
        })
      ).subscribe({
          next: (bookingResponse : Booking) => {

            this.booking = bookingResponse;

            if (bookingResponse.id) {
              this.modalStaus.isStepOne = false;
              this.modalStaus.isStepTwo = true;
              this.modalStaus.isBookingCreated = true;
              this._communicationService.sendEvent();
            }
            this.getBookingType(bookingResponse);
          },
          error: (error) => {
            this._toasterService.error(error.error.detail, error.error.title);
            this._sentryUtilsService.sendEventErrorForSentry(error, "Create booking step 1 in booking create modal");
          }
        });
  }

  patchBooking() {
    this.createRequestFromForm();

      of(this.userId).pipe(
        switchMap((userId) => {
          if (this.isFleetManager) {
            return this._usersService.listUsers$(this.tenantId, {displayName: this.bookingCreateForm.value.driverName})
              .pipe(
                map(users => users.items.length > 0 ? users.items[0].id : null)
              );
          } else {
            return of(userId);
          }
        }),
        switchMap((userId) => {
          if (userId) {
            this.bookingRequest.userId = userId;
            return this._bookingService.patchBooking$(this.tenantId, this.booking.id, this.bookingRequest);
          } else {
            this._toasterService.error(this._translateService.instant('MESSAGES.NO_USER_FOUND'));
            return EMPTY;
          }
        })
      ).subscribe({
          next: (bookingResponse : Booking) => {

            if (bookingResponse?.id) {
              this.booking = bookingResponse;

              if (bookingResponse.id) {
                this.modalStaus.isStepOne = false;
                this.modalStaus.isStepTwo = true;
                this.modalStaus.isBookingCreated = true;
                this._communicationService.sendEvent();
              }
              this.getBookingType(bookingResponse);
            }
          }
        });
  }

  private getBookingType(bookingResponse: Booking) {
    this._bookingService.getBoookingTypeById$(this.tenantId, bookingResponse.bookingTypeId).subscribe({
      next: response => {
        if (!response.enableAlternatives) {
          this.getVehiclesForBooking(bookingResponse);
        }
        else {
          this.bsModalRef.hide();
        }
      },
      error: (error) => {
        this._toasterService.error(error.error.detail, error.error.title);
        this._sentryUtilsService.sendEventErrorForSentry(error, "Get booking type in booking create modal");
      }
    });
  }

  private getVehiclesForBooking(booking: Booking) {
    let params = {
      includeMake: true,
      includeModel: true,
      includeVersion: true,
      includeVehicleType: true,
      includeFuelType: true,
      includePurchaseType: true,
      includeHeadquarter: true,
      includeLocation: true,
      includeTags: true,
    };

    this._bookingService.getVehiclesForBooking$(this.tenantId, booking.id, params).subscribe({
      next: (vehiclesResp) => {
        this.vehiclesToShow = vehiclesResp.items;
      },
      error: (error) => {
        this._sentryUtilsService.sendEventErrorForSentry(error, "Get vehicles for step 2 in booking create modal");
      }
    });
  }

  addVehicleWithPatchBooking() {
    let foundVehicle = this.vehiclesToShow.find((vehicle) => vehicle.vehicle && vehicle.vehicle.isChecked);
    let vehicleId = foundVehicle ? foundVehicle.vehicle.id : null;

    if (vehicleId) {

      if (!this.bookingRequest) {
        this.bookingRequest = {
          startDate: this.booking.startDate,
          endDate: this.booking.endDate,
          userId: this.booking.userId,
          locationId: this.booking.locationId,
          bookingTypeId: this.booking.bookingTypeId,
          title: this.booking.title,
          extraFields: this.booking.extraFields,
        };
      }

      this.bookingRequest.vehicleId = vehicleId;
  
      this._bookingService.patchBooking$(this.tenantId, this.booking.id, this.bookingRequest).subscribe({
        next: (bookingResponse : Booking) => {
          if (bookingResponse.vehicleId != null)
          {
            this._toasterService.info(this._translateService.instant('BOOKINGS.MESSAGES.SUCCESS_VEHICLE_BOOKING'));
            this._communicationService.sendEvent();
            this.hideModal();
          }

          if (bookingResponse.vehicleId == null)
          {
            this._toasterService.error(this._translateService.instant('BOOKINGS.MESSAGES.VEHICLE_SELECTED_NOT_AVAILABLE'));
          }
        },
        error: (error) => {
          this._toasterService.error(error.error.detail, error.error.title);
          this._sentryUtilsService.sendEventErrorForSentry(error, "Patch booking for add vehicle in step 2 in booking create modal");
        }
      });

    }

    else {
      this._toasterService.error(this._translateService.instant('BOOKINGS.MESSAGES.NO_VEHICLES_SELECTED'));
      this.hideModal();
    }

  }

  private hideModal() {
    this.dropdownEventSubscriptions.forEach((subscription) => subscription.unsubscribe());
    this.bsModalRef.hide();
  }

  deleteBooking() {
    this._bookingService.deleteBooking$(this.tenantId, this.booking.id).subscribe({
      next: (response) => {
        if (response.status === 204) {
          this._toasterService.info(this._translateService.instant('BOOKINGS.MESSAGES.SUCCESS_BOOKING_DELETED'));
          this.modalStaus.isBookingCreated = false;
          this.hideModal();
          this.router.navigate([`${RoutesUrl.FLEETMANAGER_BOOKING}`]);
          this._communicationService.sendEvent();
        }
      },
      error: (error) => {
        this._toasterService.error(error.error.detail, error.error.title);
        this._sentryUtilsService.sendEventErrorForSentry(error, "Delete booking create modal");
      }
    });
  }

  backToStep1() {
    this.modalStaus.isStepOne = true;
    this.modalStaus.isStepTwo = false;
  }

  goToStep2() {
    this.modalStaus.isStepOne = false;
    this.modalStaus.isStepTwo = true;
  }

  showVehicles() {
    this.hideMessageChangeVehicle = true;

    this.getVehiclesForBooking(this.booking);
  }

  changeVehicleChecked($vehicleBooking: VehicleBooking[]) {
    this.vehiclesToShow = $vehicleBooking;
  }

  cleanFielsForm() {
    Object.keys(this.bookingCreateForm.controls).forEach(key => {
      if (!['titleBooking', 'headquarterId', 'locationId', 'bookingTypeId', 'driverName', 'startDateBooking', 'startTimeBooking', 'endDateBooking', 'endTimeBooking'].includes(key)) {
        this.bookingCreateForm.removeControl(key);
      }
    });
    this.fields = [];
  }

  closeModal() {
    this.hideModal();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
