import * as Sentry from "@sentry/angular-ivy";
import * as RoutesUrl from 'src/app/shared/constant/app-routes';
import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserModel } from 'src/app/shared/models/users';
import { TasksService } from 'src/app/shared/api-services/tasks.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { UsersService } from 'src/app/shared/api-services/users.service';
import { VehiclesService } from 'src/app/shared/api-services/vehicles.service';
import { DropdownEventsService } from 'src/app/shared/utilities/dropdown-events.service';
import { TaskUtilsService } from 'src/app/shared/utilities/task-utils.service';
import { CardShowData } from '../../cards/card-show-data/card-show-data.interface';
import { FilterDateService } from 'src/app/shared/utilities/filter-date.service';
import { Asset } from 'src/app/shared/models/asset';
import { Vehicle } from 'src/app/shared/models/vehicle';
import { DatePipe } from '@angular/common';
import { Params, Router } from "@angular/router";
import { CentralQueryParamsService } from "src/app/core/services/central-query-params.service";
import { TaskFilter } from "src/app/shared/models/tasks/task-filter";

@Component({
  selector: 'tabs-tasks-expiring',
  templateUrl: './tabs-tasks-expiring.component.html',
  styleUrls: ['./tabs-tasks-expiring.component.scss']
})
export class TabsTasksExpiringComponent {

  tenantId: string = this._tenantService.getTenantId();

  @Input() user: UserModel;
  @Input() asset: Asset;
  @Input() vehicle: Vehicle;
  @Input() cardTaskToDo: CardShowData;

  listTaskExpirationDate: ListItemsTab[] = [];
  listTaskExpirationMileage: ListItemsTab[] = [];

  RoutesUrl = RoutesUrl;

  constructor(
    private _tenantService: TenantService,
    private _tasksService: TasksService,
    private _taskUtilsService: TaskUtilsService,
    private _usersService: UsersService,
    private _vehiclesService: VehiclesService,
    private _translateService: TranslateService,
    private _dropdownEventsService: DropdownEventsService,
    private _filterDateService: FilterDateService,
    private _datePipe: DatePipe,
    private _router: Router,
    private _centralQueryParams: CentralQueryParamsService
  ) { }

  ngOnInit() {
    this.getTasksExpirationDate();
    this.getTaskExpirationMileage();
  }

  getTasksExpirationDate() {
    let params : TaskFilter = {
      hasExpirationDate: true,
      hasEndDate: false,
      hasVehicle: true,
      includeVehicle: true,
      includeAssignedUser: true,
      limit: 6,
      orderBy: "ExpirationDate",
    };

    this.addParams(params);

    if (this.cardTaskToDo) {
      this._dropdownEventsService.getSelectedOption(this.cardTaskToDo.id).subscribe((newSelectedOption) => {
        let startDate: string;
        let endDate: string;
  
        ({ startDate, endDate } = this._filterDateService.getStartEndDateByTypeRange(newSelectedOption.value));
  
        params.rangeExpirationStartDate = startDate;
        params.rangeExpirationEndDate = endDate;
  
        this.getTasks(params, (tasks) => this.listTaskExpirationDate = tasks, 'expirationDate');
      });
    }
  } 

  getTaskExpirationMileage() {
    let params : TaskFilter = {
      hasExpirationMileage: true,
      hasEndDate: false,
      hasVehicle: true,
      includeVehicle: true,
      includeAssignedUser: true,
      includeTaskType: true,
      limit: 6,
    };

    this.addParams(params);
    this.getTasks(params, (tasks) => this.listTaskExpirationMileage = tasks, 'expirationMileageString');
  }

  private addParams(params: TaskFilter) {
    if (this.user) {
      params.startUserId = this.user.id;
      params.assignedUserId = this.user.id;
    }
    if (this.vehicle) {
      params.vehicleId = this.vehicle.id;
    }

    if (this.asset) {
      params.assetId = this.asset.id;
    }
  }

  private getTasks(params: TaskFilter, updateListCallback: (tasks: ListItemsTab[]) => void, sideTitleKey: string) {
    this._tasksService.listTasks$(this.tenantId, params).subscribe({
      next: (resp) => {
        let tasksExpirationDate = resp;
        tasksExpirationDate.items.forEach(task => {
          this._taskUtilsService.setStatusBadgeTask(task, task.taskType);
          if (task.expirationDate) {
            task.expirationDate = this._datePipe.transform(task.expirationDate, 'dd MMM yyyy');
          }
  
          if (task.expirationMileage) {
            task.expirationMileageString = task.expirationMileage + 'km';
          }
        });
  
        let tasks : ListItemsTab[] = tasksExpirationDate.items.map(task => {
          let sideTitle = task.hasOwnProperty(sideTitleKey) ? task[sideTitleKey] : '';
          return <ListItemsTab> {
            title: task.title,
            link: `/${this._router.url}`,
            queryParams: { modalEditTask: task.id },
            sideTitle: sideTitle,
            cssImageVehicle: 'vehicle-placeholder',
            srcImageVehicle: this._vehiclesService.getVehicleImage$(this.tenantId, task.vehicleId, 48, 48),
            content: task.vehicle?.displayName ?? this._translateService.instant('VEHICLE_IS_DELETED'),
            contentClassCss: task.vehicle?.displayName ? '' : 'color-danger',
  
            srcImageUser: this._usersService.getUserImage$(this.tenantId, task.assignedUserId, 16, 16),
            userDisplayName: task.assignedUser?.displayName,
            userDisplayNameClassCss: task.assignedUser?.displayName ? 'task-assigned' : 'task-to-assign',
            taskStatusClient: task.statusClient
          };
        });
        updateListCallback(tasks);
      },
      error: (error) => {
        if (error.status !== 404) {
          Sentry.captureEvent(error);
        }
      }
    });
  }
  
}

export interface ListItemsTab {
  title?: string;
  link?: string;
  queryParams?: Params;
  sideTitle?: string;
  srcImageVehicle?: any;
  cssImageVehicle?: string;

  content?: string;
  contentClassCss?: string;

  srcImageUser?: any;
  userDisplayName?: string;
  userDisplayNameClassCss?: string;
  taskStatusClient? : { text: string, tooltip: string, cssClass?: string };
}