<div [class]="'card ' + cardShowData?.backgroundColor">

    <div class="card-gs-headers d-flex justify-content-between align-items-center">
        <div class="card-headers-left d-flex justify-content-between align-items-center">
            <i [class]="cardShowData?.iconType + ' ' + cardShowData?.iconBg + ' default-icon p-1'"></i>
            <div class="card-title text-bolder m-1">{{ cardShowData?.cardTitle | translate }}</div>
            <span *ngIf="cardShowData?.badge" class="badge rounded-pill bg-danger fs-7 fw-normal">{{ cardShowData?.badge | translate }}</span>
        </div>
        <div class="card-headers-right">
            <dropdown-dynamic *ngIf="cardShowData?.id"
                [dropdownId]="cardShowData?.id" 
                [cssClass]="'btn-time-actions'" 
                [iconClass]="'bi bi-caret-down-fill text-black'">            
            </dropdown-dynamic>
        </div>
    </div>
    
    <div class="card-gs-body">
        <div *ngIf="cardShowData?.cardInfoItems && cardShowData?.cardInfoItems?.length; else noItemsTemplate"
            class="d-flex gap-3 flex-wrap justify-content-between">

            <div class="col card-body-item" *ngFor="let cardInfoItem of cardShowData?.cardInfoItems">
                <div [class]="'card-body-item-title text-nowrap ' + cardInfoItem.textColorTitle">
                    {{ cardInfoItem.title | translate }}
                </div> 
                <div class="card-body-item-content d-flex">
                    <div [class]="'value pe-2 ' + cardInfoItem?.textColorValue">{{cardInfoItem?.value}}</div>
                    <div [class]="'percent ' + cardInfoItem?.textColorPercent">{{cardInfoItem?.percent}}</div>
                </div>
             </div>

        </div>

        <ng-template #noItemsTemplate>
            <no-items></no-items>
        </ng-template>

        <ng-content></ng-content>

    </div>
</div>