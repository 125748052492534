import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BookingUsage } from "src/app/shared/models/booking";
import { BookingUsageRequest } from 'src/app/shared/models/booking/booking-request';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BookingService } from "src/app/shared/api-services/booking.service";
import { TenantService } from "src/app/shared/api-services/tenant.service";
import { SentryUtilsService } from "src/app/shared/utilities/sentry-utils.servic";
import { Vehicle } from 'src/app/shared/models/vehicle';

@Component({
  selector: 'booking-usage-mileage-modal',
  templateUrl: './booking-usage-mileage-modal.component.html',
  styleUrls: ['./booking-usage-mileage-modal.component.scss']
})
export class BookingUsageMileageModalComponent {

  tenantId: string;
  @Input() bookingUsage: BookingUsage;
  @Input() bookingId: string;
  @Input() vehicle: Vehicle;
  bookingUsageForm: FormGroup;
  bookingUsageRequest: BookingUsageRequest;

  @Output() onUpdate = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    private _tenantService: TenantService,
    private _bookingService: BookingService,
    private _sentryUtilsService: SentryUtilsService,
  ) { 
    this.tenantId = this._tenantService.getTenantId();
  }

  ngOnInit() {
    this.bookingUsageForm = new FormGroup({
      startMileage: new FormControl(this.bookingUsage?.startMileage, Validators.required),
      endMileage: new FormControl(this.bookingUsage?.endMileage),
    });

    this.bookingUsageForm.get('endMileage')!.valueChanges.subscribe(() => this.validateEndMileage());
    this.bookingUsageForm.get('startMileage')!.valueChanges.subscribe(() => this.validateStartMileage());
  }

  validateStartMileage() {
    let mileageVehicle = this.vehicle?.mileage;
    let startMileage = this.bookingUsageForm.get('startMileage')?.value;

    if (mileageVehicle && startMileage && mileageVehicle > startMileage) {
      this.bookingUsageForm.get('startMileage')?.setErrors({ 'invalid': true });
    }
  }

  validateEndMileage() {
    let startMileage = this.bookingUsageForm.get('startMileage')?.value;
    let endMileage = this.bookingUsageForm.get('endMileage')?.value;

    if (startMileage && endMileage && startMileage > endMileage) {
      this.bookingUsageForm.get('endMileage')?.setErrors({ 'invalid': true });
    }
  }

  onSubmit() {

    if (this.bookingUsageForm.invalid) {
      return;
    }

    this.bookingUsageRequest = {
      startMileage: this.bookingUsageForm.value.startMileage,
      endMileage: this.bookingUsageForm.value.endMileage,
    };

    this._bookingService.patchBookingUsage$(this.tenantId, this.bookingId, this.bookingUsage.id, this.bookingUsageRequest)
      .subscribe({
        next: (bookingUsage) => {

          if (bookingUsage?.startMileage == this.bookingUsageRequest.startMileage && bookingUsage?.endMileage == this.bookingUsageRequest.endMileage) {
            this.onUpdate.emit();
            this.bsModalRef.hide();
          } else {
            let extraInfo = {
              bookingUsage: bookingUsage,
              bookingUsageRequest: this.bookingUsageRequest,
              bookingId: this.bookingId,
              bookingUsageId: this.bookingUsage.id,
              tenantId: this.tenantId,
              bookingUsageForm: this.bookingUsageForm.value,
            }
            this._sentryUtilsService.sendEventErrorForSentry('', extraInfo);
          }

        },
      });
  }
}
