import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Headquarter } from 'src/app/shared/models/headquarters';
import { VehicleType } from 'src/app/shared/models/vehicle';

@Injectable({
  providedIn: 'root'
})
export class FilterModalService {

  private listForm = new Map<string, FormGroup>;
  private sourceForm = new Map<string, Subject<FormGroup>>();
  
  private listFilter = new Map<string, GenericFilter>();
  private sourceFilter = new Map<string, Subject<GenericFilter>>();

  getForm(id: string): FormGroup | null {
    if (this.listForm.has(id)) {
      return this.listForm.get(id);
    }
    return null;
  }

  getForm$(id: string): Observable<FormGroup> {
    if (!this.sourceForm.has(id)) {
      this.sourceForm.set(id, new BehaviorSubject<FormGroup>(null));
    }
    return this.sourceForm.get(id).asObservable();
  }

  applyFilterFromForm(id: string, form: FormGroup) {
    this.listForm.set(id, form);
    const filterSubject = this.sourceForm.get(id);
    if (filterSubject) {
      filterSubject.next(form);
    }
  }

  getFilter(id: string): GenericFilter | null {
    if (this.listFilter.has(id)) {
      return this.listFilter.get(id);
    }
    return null;
  }

  getFilter$(id: string): Observable<GenericFilter> {
    if (!this.sourceFilter.has(id)) {
      this.sourceFilter.set(id, new BehaviorSubject<GenericFilter>(null));
    }
    return this.sourceFilter.get(id).asObservable();
  }

  applyFilter(id: string, filter: GenericFilter) {
    this.listFilter.set(id, filter);
    const filterSubject = this.sourceFilter.get(id);
    if (filterSubject) {
      filterSubject.next(filter);
    }
  }
}

export interface GenericFilter {
  startMileage?: number;
  endMileage?: number;

  startCost?: number;
  endCost?: number;

  startDate?: Date;
  endDate?: Date;

  headquarter?: Headquarter;
  vehicleType?: VehicleType;
}
