export const HOME = 'homepage';
export const DASHBOARD = 'dashboard';

export const VEHICLES = 'vehicles';
export const VEHICLES_CREATE = 'create-vehicle';
export const VEHICLES_DETAIL = 'detail-vehicle';

export const VEHICLE_USAGES = 'vehicles/vehicle-usages';

export const ASSETS = 'page-assets';
export const ASSETS_CREATE = 'create-asset';
export const ASSETS_DETAIL = 'detail-asset';

export const PERSONS = 'persons';
export const PERSONS_CREATE = 'create-person';
export const PERSONS_DETAIL = 'detail-person';

export const TASKS = 'activity/tasks';
export const COSTS = 'activity/costs';

export const INVOICES = 'activity/invoices';
export const INVOICES_CREATE = 'activity/create-invoice';
export const INVOICES_DETAIL = 'activity/detail-invoice';

export const SUPPLIERS = 'activity/suppliers';
export const SUPPLIERS_CREATE = 'activity/create-supplier';
export const SUPPLIERS_DETAIL = 'activity/detail-supplier';

export const FLEETMANAGER_BOOKING = 'bookings/fleetmanager-booking';
export const MYBOOKINGS = 'bookings/my-bookings';
export const BOOKING_DETAIL = 'booking-detail';

export const REPORTS = 'reports';
export const REPORT_COSTS = 'report-costs';
export const REPORT_EFFICIENCY = 'report-efficiency';
export const REPORT_FINES = 'report-fines';
export const REPORT_GREEN = 'report-green';
export const REPORT_SUPPLIES = 'report-supplies';
export const REPORT_TCO = 'report-tco';
export const REPORT_VEHICLE_USAGES = 'report-vehicle-usages';

export const FLEETLOCATION = 'fleet-location';

export const SETTINGS = 'settings';
export const HEADQUARTER_AND_LOCATION = 'headquarter-location';
export const VEHICLE_VERSIONS = 'vehicle-versions';
export const TAGS = 'tags';
export const CAR_POLICY = 'car-policy';

export const CABINETS = 'cabinets';
export const MAPPING_CABINET_KEYS = 'mapping-cabinet-key';

export const IMPORTS = 'imports';
export const IMPORTS_DETAIL = 'detail-import';

export const ASSISTANCE = 'assistance';
export const UPGRADE = 'upgrade';

export const NOT_FOUND = 'not-found';
export const REGISTRATION = 'registration';
export const LOGIN = 'login';
export const NO_SERVICE = 'no-service';