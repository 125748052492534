import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { RangeDate } from '../models/common';

@Injectable({
  providedIn: 'root'
})
export class DateUtilsService {

  constructor(private _datePipe: DatePipe) { }

  parseDate(rangeDate: RangeDate) {
    let startDate: Date;
    if (rangeDate?.startDate) {
      startDate = new Date(rangeDate.startDate);
      startDate.setHours(0, 0, 0, 0);
    }

    let endDate: Date;
    if (rangeDate?.endDate) {
      endDate = new Date(rangeDate.endDate);
      endDate.setHours(23, 59, 59, 999);
    }
    return { startDate, endDate };
  }

  convertDateUtcToLocal(date: Date): Date {
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
  }

  parseDateStringToDate(dateString: string): Date {
    let date;
    try {
      date = this.parseDateFromString(dateString);
      
    } catch (error) {
      date = new Date(dateString);
    }

    return date;
  }

  parseDateFromString(dateString: string): Date {
    const [day, month, year] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day); // I mesi sono 0-indexed in JavaScript
  }

  parseDateFromStringInverse(dateString: string): Date {
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day); // I mesi sono 0-indexed in JavaScript
  }

  isDateValid(date: string): boolean {
    return !isNaN(Date.parse(date));
  }

  parseDataToString(date: string): string {
    try {
      return this._datePipe.transform(date, 'yyyy-MM-dd');
    } catch (error) {
      let taskExpirationDate = this.parseDateFromString(date);
      return this._datePipe.transform(taskExpirationDate, 'yyyy-MM-dd');
    }
  }

  parseDataToStringInverse(date: string): string {
    try {
      return this._datePipe.transform(date, 'dd-MM-yyyy');
    } catch (error) {
      let taskExpirationDate = this.parseDateFromStringInverse(date);
      return this._datePipe.transform(taskExpirationDate, 'dd-MM-yyyy');
    }
  }

  parseTimeToString(date: string): string {
    try {
      return this._datePipe.transform(date, 'HH:mm');
    } catch (error) {
      let taskExpirationDate = this.parseDateFromString(date);
      return this._datePipe.transform(taskExpirationDate, 'HH:mm');
    }
  }

  parseDateToTimeString(date: Date): string {
    return this._datePipe.transform(date, 'HH:mm');
  }

  combineDateAndTime(dateString: string, timeString: string): string {
    let date = new Date(dateString);
    date.setHours(0, 0, 0, 0);

    let [hours, minutes] = timeString.split(":").map(Number);
    date.setHours(hours, minutes, 0, 0);
    return date.toISOString();
  }

  combineDateAndTimeToIsoString(dateString: string, timeString: string): string {
    let date;
    try {
      date = this.parseDateFromString(dateString);
      
    } catch (error) {
      date = new Date(dateString);
    }

    // Imposta le ore di date a 0
    date.setHours(0, 0, 0, 0);

    // Dividi la stringa di tempo in ore e minuti
    let [hours, minutes] = timeString.split(":").map(Number);

    // Aggiungi ore e minuti all'oggetto Date
    date.setHours(hours, minutes, 0, 0);

    // Converti in UTC e formatta come ISO string
    // return this._datePipe.transform(date, 'yyyy-MM-ddTHH:mm:ss') + 'Z';
    return date.toISOString();
  }

  getPreviousMonthToNextYear(date = new Date()) {
    date.setHours(0, 0, 0, 0);
  
    // Imposta la data all'inizio del mese precedente
    let startMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
  
    // Imposta la data alla fine dello stesso mese un anno dopo
    let endMonth = new Date(date.getFullYear() + 1, date.getMonth(), 0); // Il giorno 0 del prossimo mese restituisce l'ultimo giorno del mese corrente

    startMonth.setDate(startMonth.getDate() + 1);
    endMonth.setDate(endMonth.getDate() + 1);
  
    let startDate = startMonth.toISOString().split('T')[0];
    let endDate = endMonth.toISOString().split('T')[0];
  
    return { startDate, endDate };
  }

  generateMonthYearDateArray(startDate: string, endDate: string): Date[] {
    const start = new Date(startDate);
    const end = new Date(endDate);
    let current = new Date(start);
    const monthYearDateArray: Date[] = [];
      
    while (current <= end) {
      monthYearDateArray.push(new Date(current));
      current.setMonth(current.getMonth() + 1);
    }
      
    return monthYearDateArray;
  }

  formatMonthYear(date: Date): string {
    const browserLang = navigator.language;
    const monthFormatter = new Intl.DateTimeFormat(browserLang, { month: 'short' });
    const month = monthFormatter.format(date).replace(/\.$/, ''); 
    const year = date.getFullYear().toString().slice(2);
    
    return `${month} ${year}`;
  }

  formatDateForAPI(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
    
    return `${year}-${month}`;
  }
}
