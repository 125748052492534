import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { UserModel } from '../models/users';
import { Vehicle } from '../models/vehicle';

@Injectable({
  providedIn: 'root'
})
export class VehicleUtilsService {
  constructor(
    private _datePipe: DatePipe,
    private _translate: TranslateService) { }

  setActionsVehicls (vehicle: Vehicle) : Vehicle {
    vehicle.actions = [
      { text: this._translate.instant("ACTIONS.DETAIL"), value: 'detail'},
      { text: this._translate.instant("ACTIONS.EDIT"), value: 'edit'},
      { text: this._translate.instant("ACTIONS.DELETE"), value: 'delete'}
    ];

    return vehicle;
  }

  setStatusVehicle (vehicle: Vehicle) : Vehicle {
    vehicle.statusClient = { text: '', tooltip: '', cssClass: ''};

    let now = new Date();

    if (vehicle.disabledFrom === null && vehicle.disabledTo === null) {
      vehicle.statusClient.text = this._translate.instant("ACTIVE");
      vehicle.statusClient.cssClass = ' bg-success';
    }

    let dateFrom : Date;
    if (vehicle.disabledFrom) {
      dateFrom = new Date(vehicle.disabledFrom);
    }
    
    let dateTo : Date;
    if (vehicle.disabledTo) {
      dateTo = new Date(vehicle.disabledTo);
    }

    if (dateFrom < now && (dateTo > now || !dateTo)) {
      vehicle.statusClient.text = this._translate.instant("SUSPENDED2");
      vehicle.statusClient.cssClass = ' bg-danger';
    } else {
      vehicle.statusClient.text = this._translate.instant("ACTIVE");
      vehicle.statusClient.cssClass = ' bg-success';
    }



    vehicle.statusClient.cssClass += ' custom-badge';

    return vehicle;
  } 

  calculateDepreciation(vehicle: Vehicle) : number {
    if (!vehicle.fleetEntryValue || !vehicle.fleetEntryDate) {
      return 0;
    }

    let currentDate = new Date();
    const k = 0.254;

    let fleetEntryDate = new Date(vehicle.fleetEntryDate);
    let diffInMilliseconds = currentDate.getTime() - fleetEntryDate.getTime();

    const years = diffInMilliseconds / (1000 * 365 * 60 * 60 * 24);

    return vehicle.fleetEntryValue * (1 - Math.exp(-k * years));
  }
}