import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Booking, BookingUsage } from 'src/app/shared/models/booking';
import { BookingUsageRequest } from 'src/app/shared/models/booking/booking-request';
import { BookingService } from 'src/app/shared/api-services/booking.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { BookingUsageMileageModalComponent } from "../../modals/booking-usage-mileage-modal/booking-usage-mileage-modal.component";
import { TenantService } from "src/app/shared/api-services/tenant.service";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { DatePipe } from "@angular/common";
import { BookingUtilsService } from "src/app/shared/utilities/booking-utils.service";
import { VehiclesService } from "src/app/shared/api-services/vehicles.service";
import { Observable, Subject, filter, forkJoin, interval, map, of, share, shareReplay, switchMap, takeUntil, tap } from "rxjs";
import { CommunicationService } from "src/app/shared/utilities/comunication.service";
import { UsersService } from 'src/app/shared/api-services/users.service';
import { SafeUrl } from '@angular/platform-browser';
import { Vehicle } from 'src/app/shared/models/vehicle';
import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';

@Component({
  selector: 'card-booking-usage-actions',
  templateUrl: './card-booking-usage-actions.component.html',
  styleUrls: ['./card-booking-usage-actions.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state('collapsed', style({
        height: '0px',
        overflow: 'hidden',
        opacity: 0,
        margin: '0',
        padding: '0'
      })),
      state('expanded', style({
        height: '*', 
        opacity: 1,
        margin: '*',
        padding: '*'
      })),
      transition('expanded <=> collapsed', animate('300ms ease-in-out')),
    ]),
  ],
})
export class CardBookingUsageActionsComponent {

  tenantId: string;
  @Input() bookingId: string;

  booking$: Observable<Booking>;
  bookingUsage$: Observable<BookingUsage>;
  vehicle: Vehicle;

  canStartUsage$: Observable<boolean>;
  isBookingActive: boolean = false;
  hasReservation$: Observable<boolean>; 

  booking: Booking;
  bookingUsage: BookingUsage;

  vehicleSrcImage: Observable<SafeUrl>;
  userSrcImage: Observable<SafeUrl>;

  bookingUsageRequest: BookingUsageRequest = {};
  
  collectBy: string|number;
  byTheDate: Date;

  modalUsageMileage: BsModalRef;
  confirmModalEndUsage: BsModalRef<ConfirmModalComponent>;
  
  @Input() isFleetManager: boolean = false;

  waitClose: boolean = false;

  destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _datePipe: DatePipe,
    private _modalService: BsModalService,
    private _tenantService: TenantService,
    private _bookingService: BookingService,
    private _vehiclesService: VehiclesService,
    private _usersService: UsersService,
    private _bookingUtilsService: BookingUtilsService,
    private _comunicationService: CommunicationService,
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
  ) {
    this.tenantId = this._tenantService.getTenantId();
   }

  ngOnInit() {
    this.booking$ = this.getBooking$();
    this.bookingUsage$ = this.getBookingUsage$();
    this.setStatusBadge();
    
    this.booking$.pipe(
      tap((booking) => {
        this.booking = booking;
        this.setUntakenAfterMinutes();
        this.getVehicleImage();
        this.getUserImage();
        this.getVehicle()?.subscribe();
        this.loopToCheckWhenCanStartUsage();
        this.loopToCheckWhenCloseBooking();
      }),
      takeUntil(this.destroy$),
    ).subscribe();

    this.canStartUsage$ = this.checkCanStartUsage$();  
    this.hasReservation$ = this.checkReservations$();
  }

  private loopToCheckWhenCanStartUsage() {

    let subscription = interval(1500).pipe(
      takeUntil(this.destroy$),
      switchMap(() => {
        let now = new Date();
        now.setSeconds(now.getSeconds() + 2);
  
        // console.log(this.byTheDate);
        // console.log(now);
        if (this.booking.status === 'OK' && this.byTheDate <= now) {
          return this._bookingService.getBookingById$(this.tenantId, this.booking.id)
            .pipe(
              takeUntil(this.destroy$),
            );
        } else {
          return of(null); 
        }
      }),
        filter(booking => booking?.status === 'OK_ACTIVE'),
    ).subscribe((booking) => {
        if (booking) {
          this.canStartUsage$ = this.checkCanStartUsage$();
          this.booking$ = this.getBooking$();
          subscription.unsubscribe();
        }
    });
  }

  private loopToCheckWhenCloseBooking() {

    let subscription = interval(1500).pipe(
      takeUntil(this.destroy$),
      switchMap(() => {
        let now = new Date();
        now.setSeconds(now.getSeconds() + 2);
  
        let bytheDate: Date;
        if (this.booking.endDate) {
          bytheDate = new Date(this.booking.endDate);
        }
  
        // console.log(bytheDate.toString());
        // console.log(now);
        if (this.booking.status.split('_')[0] !== 'CLOSED' && bytheDate <= now) {
          return this._bookingService.getBookingById$(this.tenantId, this.booking.id)
            .pipe(
              takeUntil(this.destroy$),
            );
        } else {
          return of(null); 
        }
      }),
      filter(booking => booking?.status?.split('_')[0] === 'CLOSED'),
   ).subscribe((booking) => {
      if (booking) { 
        this.canStartUsage$ = this.checkCanStartUsage$();
        this.booking$ = this.getBooking$();
        subscription.unsubscribe();
      }
   });
  }

  private getBooking$() {
    let queryParams = {
      includeUser: true,
      includeVehicle: true,
      includeLocation: true,
      includeHeadquarter: true,
      includeBookingType: true,
      includeInsertedByUser: true,
    };

    return this._bookingService.getBookingById$(this.tenantId, this.bookingId, queryParams).pipe(
      tap(response => {
        this.booking = response;
        this.isBookingActive = this.checkBookingStatus();
      })
    );
  }

  private getVehicleImage() {
    if (!this.booking?.vehicleId) {
      return;
    }
    this.vehicleSrcImage = this._vehiclesService.getVehicleImage$(this.tenantId, this.booking.vehicleId, 48, 48);
  }

  private getVehicle() : Observable<Vehicle> {
    if (!this.booking?.vehicleId) {
      console.warn('Vehicle Id is null');
      return null;
    }

    let query = {
      includeMake: true,
      includeModel: true,
      includeVersion: true,
      includeVehicleType: true,
      includeFuelType: true,
      includePurchaseType: true,
      includeHeadquarter: true,
      includeLocation: true
    };

    return this._vehiclesService.getVehicleById$(this.tenantId, this.booking.vehicleId, query)
    .pipe(
      tap(response => {
        this.vehicle = response;
      }),
      takeUntil(this.destroy$)
    );
  }

  getUserImage() : Observable<SafeUrl> {
    if (!this.bookingUsage?.userId) {
      return of(null);
    }
    return this._usersService.getUserImage$(this.tenantId, this.bookingUsage?.userId, 20, 20);    
  }

  checkCanStartUsage$() : Observable<boolean> {
    return this._bookingService.getBookingUsages$(this.tenantId, this.bookingId)
    .pipe(
      map(response => {
        if (response?.count === 0) {
          return true;
        }
        else {
          return response.items.every(usage => usage.endDate !== null);
        }
      })
    )
  }

  checkReservations$() : Observable<boolean> {
    let params = {
      orderBy: 'Id',
    };
    return this._bookingService.getReservations$(this.tenantId, this.bookingId, params)
    .pipe(
      shareReplay(1),
      map (reservationResp => {
        return reservationResp.count > 0;
      })
    )
  }

  getBookingUsage$() : Observable<BookingUsage> {
    let params = {
      orderBy: 'Id',
    };
    return this._bookingService.getBookingUsages$(this.tenantId, this.bookingId, params)
    .pipe(
      map (bookingUsageResp => {
        return this.bookingUsage = bookingUsageResp.items[bookingUsageResp.count - 1] ?? null;
      })
    );
  }

  setStatusBadge() {
    forkJoin({
      bookingUsage: this.bookingUsage$,
      booking: this.booking$,
    })
    .pipe(
      takeUntil(this.destroy$),
    )
    .subscribe({
      next: ({ bookingUsage, booking }) => {
        if (!bookingUsage || !booking) {
          return;
        }
        let bookingUsageResult = this._bookingUtilsService.setStatusBadgeBookingUsage(bookingUsage, booking);
        this.bookingUsage$ = of(bookingUsageResult);
      }
    });
  }

  checkBookingStatus() : boolean {
    if (this.booking?.status === 'OK_ACTIVE' || this.booking?.status === 'OK_DELAYED') {
      return true;
    }
    return false;
  }

  private setUntakenAfterMinutes() {
    if (!this.booking.bookingType) {
      throw new Error('Booking type not found');
    }
    
    let closeUntakenAfterMinutes = this.booking.bookingType.closeUntakenAfterMinutes;

    const startDate = new Date(this.booking.startDate);
    if (startDate && !isNaN(startDate.getTime())) {

      if (closeUntakenAfterMinutes != null) {
        this.collectBy = startDate.setMinutes(startDate.getMinutes() + closeUntakenAfterMinutes);
      }
      else {
        let endDate = new Date(this.booking.endDate);
        this.collectBy = endDate.setMinutes(endDate.getMinutes());
      }

      let byTheDateUtc = new Date(this.collectBy);
      this.collectBy = this._datePipe.transform(byTheDateUtc, 'yyyy-MM-ddTHH:mm:ss');
    }


    let byTheDate = new Date(this.booking.startDate);
    let checkAvailabilityBeforeMinutes = this.booking.bookingType.checkAvailabilityBeforeMinutes ?? 0;

    if (byTheDate && !isNaN(byTheDate.getTime()) && checkAvailabilityBeforeMinutes !== null) {
      let byTheDateNumber = byTheDate.setMinutes(byTheDate.getMinutes() - checkAvailabilityBeforeMinutes);
      this.byTheDate = new Date(byTheDateNumber);
    }
  }

  createUsage() {
    this._bookingService.createBookingUsage$(this.tenantId, this.booking.id, this.bookingUsageRequest)
    .pipe(
      takeUntil(this.destroy$),
    )
    .subscribe({
      complete: () => {
        this.bookingUsage$ = this.getBookingUsage$();
        this.canStartUsage$ = this.checkCanStartUsage$();
        this._comunicationService.sendEvent();
      }
    });
  }

  endUsageNew() {
    this.confirmModalEndUsage = this._modalService.show(ConfirmModalComponent, {
      class: 'modal-sm',
      animated: true,
      initialState: {
        content: 'BOOKINGS.MESSAGES.CONFIRM_END_USAGE'
      }
    });

    this.confirmModalEndUsage.content.onConfirm
    .pipe(
      takeUntil(this.destroy$),
      switchMap((haveEndUsage : Boolean) => {
        if (haveEndUsage) {
          return this._bookingService.closeBookingUsage$(this.tenantId, this.booking.id, this.bookingUsage.id);
        }
        else {
          return of(null);
        }
      })
    )
    .subscribe((bookingUsage) => {
      if (bookingUsage) {
        this.bookingUsage = bookingUsage;
        this._comunicationService.sendEvent();
        this.waitClose = true;
        
        let interval = setInterval(() => {
          this.getBooking$()
          .pipe(
            takeUntil(this.destroy$),
          )
          .subscribe((booking) => {
            if (booking.status !== 'OK_ACTIVE' || (booking.lastReturnedDate && booking.lastUsageId === bookingUsage.id)) {
              this.waitClose = false;
              this.booking = booking;
              this.booking$ = of (booking);
              this.bookingUsage$ = this.getBookingUsage$();
              this.canStartUsage$ = this.checkCanStartUsage$();
              clearInterval(interval);
            }
          });
        }, 1500);

      }
    });

    
  }

  openModalMileage() {
    this.modalUsageMileage = this._modalService.show(BookingUsageMileageModalComponent, {
      class: 'modal-sm',
      animated: true,
      initialState: {
        bookingUsage: this.bookingUsage,
        bookingId: this.booking.id,
        vehicle: this.vehicle,
      }
    });

    this.modalUsageMileage.content.onUpdate
    .pipe(
      takeUntil(this.destroy$),
    )
    .subscribe(() => {
      this.bookingUsage$ = this.getBookingUsage$();
    });
  }
  
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
