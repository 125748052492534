<div class="card-light card-task-usage-date">
    <div class="h-100">

        <h3 class="title-task-usage">{{'TASKS.MODAL_EDIT_TASK.TITLE_TASK_USAGE' | translate}}</h3>
    
        <div class="container-no-task-date" *ngIf="!startDateUsage && !endDateUsage && !isEditMode">
            <svg width="64" height="40" viewBox="0 0 64 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_6612_3364)">
                <rect width="64" height="40" fill="white" fill-opacity="0.01"/>
                <path d="M32 39.7031C49.6731 39.7031 64 36.5923 64 32.755C64 28.9177 49.6731 25.807 32 25.807C14.3269 25.807 0 28.9177 0 32.755C0 36.5923 14.3269 39.7031 32 39.7031Z" fill="#F5F5F5"/>
                <path d="M55 13.6653L44.854 2.24866C44.367 1.47048 43.656 1 42.907 1H21.093C20.344 1 19.633 1.47048 19.146 2.24767L9 13.6663V22.8367H55V13.6653Z" stroke="#CED4DA"/>
                <path d="M41.613 16.8128C41.613 15.2197 42.607 13.9046 43.84 13.9036H55V31.9059C55 34.0132 53.68 35.7402 52.05 35.7402H11.95C10.32 35.7402 9 34.0122 9 31.9059V13.9036H20.16C21.393 13.9036 22.387 15.2167 22.387 16.8098V16.8317C22.387 18.4248 23.392 19.7111 24.624 19.7111H39.376C40.608 19.7111 41.613 18.4128 41.613 16.8198V16.8128Z" fill="#FAFAFA" stroke="#CED4DA"/>
                </g>
                <defs>
                <clipPath id="clip0_6612_3364">
                <rect width="64" height="40" fill="white"/>
                </clipPath>
                </defs>
            </svg> 
            <div class="no-task-date">{{'NO_DATE_INSERTED' | translate}}</div>
        </div>
    
        <div class="container-task-date mb-3" *ngIf="startDateUsage || endDateUsage || isEditMode">
            <div *ngIf="isEditMode">
    
                <form class="d-flex gap-2 flex-column" [formGroup]="form">
    
                    <div class="form-group">        
                        <label for="startDateUsage" class="form-label">{{'START_DATE' | translate}}</label>                        
                        <input-date-picker formControlName="startDateUsage" ></input-date-picker>        
                    </div>
        
                    <div class="form-group">        
                        <label for="endDateUsage" class="form-label">{{'END_DATE' | translate}}</label>
                        <input-date-picker formControlName="endDateUsage"></input-date-picker>                        
                    </div>
    
                </form>
    
            </div>
    
            <div *ngIf="!isEditMode && (startDateUsage || endDateUsage)">
                <span>{{'FROM' | translate}}</span> {{startDateUsage | date: 'dd-MM-yyyy'}} <span *ngIf="endDateUsage">{{'TO' | translate | lowercase}}</span> {{endDateUsage | date: 'dd-MM-yyyy'}}
            </div>
    
        </div>
        
    </div>

    <div class="card-light-footer d-flex gap-2">
        <button class="btn-gs-primary-noborder mt-4" 
            *ngIf="!isEditMode" (click)="editMode()">{{'ACTIONS.EDIT' | translate}}</button>

        <button class="btn-gs-secondary" (click)="cancel()" *ngIf="isEditMode">{{'ACTIONS.CANCEL' | translate}}</button>
        <button class="btn-gs-primary" (click)="onSumit()" *ngIf="isEditMode" [disabled]="form.invalid">{{'ACTIONS.SAVE' | translate}}</button>
    </div>
</div>
