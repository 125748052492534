import { Component, Input, Type } from '@angular/core';
import { CardShowData } from './card-show-data.interface';

@Component({
  selector: 'card-show-data',
  templateUrl: './card-show-data.component.html',
  styleUrls: ['./card-show-data.component.scss']
})
export class CardShowDataComponent {
  @Input() cardShowData: CardShowData;  
  @Input() isMobile: boolean = false;
  @Input() dynamicComponent: Type<any>;
}