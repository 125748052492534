import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { Observable, catchError, tap } from 'rxjs';
import { CommonService } from './common.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private _http: HttpClient,
    private _commonService: CommonService,
    private _translateService: TranslateService,
    private _toastrService: ToastrService,
  ) { }
  
  addFile$(bucketName: string, tenantId: string, formFile: FormData) : Observable<HttpResponse<any>> {
    const apiUrl = `${VECTORE_API_BASE_URL}/${tenantId}/storage/${bucketName}/files`;
    
    return this._http.post<any>(apiUrl, formFile, { observe: 'response',  })
    .pipe(
      tap((response) => {
        if (response.status === 200) {
          let message = this._translateService.instant('INPUT_UPLOAD_FILES.SUCCESS_FILE_UPLOAD');
          this._toastrService.success(message);
        }
      }),
      catchError(error => this._commonService.handleErrorDownload(error))
    );
  }

  getVehicleImg$(tenantId: string, vehicleId: string): Observable<Blob> {
    const apiUrl = `${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/image`;    
    
    return this._http.get(apiUrl, { responseType: 'blob',  });
  }
  
}
