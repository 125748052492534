<div class="card-import-modal">
    <header>        
        <h4>{{'MODAL_DOWNLOAD_TRACE_IMPORT.TITLE' | translate}}</h4>
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </header>

    <content class="d-flex flex-column gap-2">
        <div class="form-group">
            <label class="mb-2">{{'TYPE_IMPORT' | translate}}</label>
            <dropdown-primary
                [emitNgOnInit]="false"
                [activeScrollbar]="true"
                [selectedOption]="selectedTypeImporter"
                [options]="typeImportersOption"
                (selectedOptionChange)="onSelectedTypeImporter($event)">
            </dropdown-primary>
        </div>

        <div class="form-group" *ngIf="typeImporter?.name === 'versions' || typeImporter?.name === 'vehicles'">
            <label class="mb-2">{{'VehicleType' | translate}}</label>
            <dropdown-primary-vehicle-type
                (onSelectedEvent)="onFilterSelected($event, 'VehicleType')">
            </dropdown-primary-vehicle-type>
        </div>

        <div class="form-group" *ngIf="typeImporter?.name === 'vehicles'">
            <label class="mb-2">{{'PurchaseType' | translate}}</label>
            <dropdown-primary-purchase-type
                (onSelectedEvent)="onFilterSelected($event, 'PurchaseType')">
            </dropdown-primary-purchase-type>
        </div>

        <div class="form-group" *ngIf="typeImporter?.name === 'assets'">
            <label class="mb-2">{{'Assets' | translate}}</label>
            <dropdown-primary-asset-type
                (onSelectedEvent)="onFilterSelected($event)">
            </dropdown-primary-asset-type>
        </div>

        <div class="form-group"  *ngIf="typeImporter?.name === 'tasks'">
            <label class="mb-2">{{'Task' | translate}}</label>
            <dropdown-primary-task-type
                (onSelectedEvent)="onFilterSelected($event)">
            </dropdown-primary-task-type>
        </div>

        <div class="container-upload">
            <button-first-type
                [cssClass]="'btn-gs-primary w-100'"
                [buttonText]="'MODAL_DOWNLOAD_TRACE_IMPORT.BUTTON' | translate"
                [isDisable]="!canDownload"
                (click)="downloadTrace()">
            </button-first-type>
        </div>
    </content>
</div>