import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'button-first-type',
  templateUrl: './button-first-type.component.html',
  styleUrls: ['./button-first-type.component.scss']
})
export class ButtonFirstTypeComponent {
  @Input() buttonText!: string;
  @Input() iconClass!: string;
  @Input() cssClass!: string;

  @Input() dataBsToggle!: string;
  @Input() dataBsTarget!: string;

  @Input() isDisable: boolean = false;

}
