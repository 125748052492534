import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as RoutesUrl from 'src/app/shared/constant/app-routes';
import { Me } from '../models/me';
import { SidebarItem } from 'src/app/core/components/sidebar/sidebar.component';
import pageToShowConfiguration from 'src/assets/configurations/user-area-to-show-configuration.json';
import { ToastrService } from 'ngx-toastr';
import { TenantService } from '../api-services/tenant.service';
import { MeServiceDeprecated } from '../api-services/me.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AccessUtilsService {

  private tenantId: string = this._tenantService.getTenantId();

  constructor(
    private _tenantService: TenantService,
    private _meService: MeServiceDeprecated,
    private _translateService: TranslateService,
    private _toastService: ToastrService
  ) { }

  getAllNavs(me : Me, isMobile: boolean = false) : SidebarItem[] {

    let listNavs : SidebarItem[] = [
      { name: 'dashboard', label: this._translateService.instant('DASHBOARD.NAME'), color: 'color-primary', icon: 'bi bi-grid', routerLink: RoutesUrl.DASHBOARD },
      { 
        name: 'vehicles', 
        label: this._translateService.instant('VEHICLESS'), 
        icon: 'bi bi-truck',
        canExpand: true,
        isExpanded: false,
        children: [
          { name: 'vehicles', label: this._translateService.instant('VEHICLESS'), icon: 'bi bi-truck', routerLink: RoutesUrl.VEHICLES },
          { name: 'vehicle-usages', label: this._translateService.instant('VEHICLE_USAGES.NAME'), icon: 'bi bi-truck', routerLink: RoutesUrl.VEHICLE_USAGES },
        ]
       },
      { name: 'page-assets', label: this._translateService.instant('ASSET'), icon: 'bi bi-layers', routerLink: RoutesUrl.ASSETS },
      { name: 'persons', label: this._translateService.instant('PERSONS.NAME'), icon: 'bi bi-people', routerLink: RoutesUrl.PERSONS },
      { 
        name: 'activity', 
        label: this._translateService.instant('ACTIVITY'), 
        icon: 'icon-task-vectore', 
        canExpand: true,
        isExpanded: false,
        children: [
          { name: 'tasks', label: this._translateService.instant('TASK'), icon: 'icon-task-vectore', routerLink: RoutesUrl.TASKS },
          { name: 'costs', label: this._translateService.instant('COSTS.NAME'), icon: 'bi bi-wallet2', routerLink: RoutesUrl.COSTS },
          { name: 'invoices', label: this._translateService.instant('INVOICES.NAME'), icon: 'bi bi-receipt-cutoff', routerLink: RoutesUrl.INVOICES },
          { name: 'suppliers', label: this._translateService.instant('SUPPLIERS.NAME'), icon: 'bi bi-shop-window', routerLink: RoutesUrl.SUPPLIERS },
        ]
      },
      {
        name: 'bookings',
        label: this._translateService.instant('BOOKINGS.NAME'),
        icon: 'bi bi-bookmarks',
        canExpand: true,
        isExpanded: false,
        children: [
          { name: 'fleetmanager-booking', label: this._translateService.instant('BOOKINGS.NAME'), routerLink: RoutesUrl.FLEETMANAGER_BOOKING },
          { name: 'my-bookings', label: this._translateService.instant('YOUR_BOOKINGS'), routerLink: RoutesUrl.MYBOOKINGS },
        ]
      },
      { name: 'reports', label: this._translateService.instant('REPORTS.NAME'), icon: 'bi bi-bar-chart', routerLink: RoutesUrl.REPORTS },
      { name: 'fleet-location', label: this._translateService.instant('FLEET_LOCATION'), icon: 'bi bi-geo-alt', routerLink: RoutesUrl.FLEETLOCATION },
      { name: 'settings', label: this._translateService.instant('SETTINGS.NAME'), icon: 'bi bi-gear', routerLink: RoutesUrl.SETTINGS },
      { name: 'assistance', label: this._translateService.instant('ASSISTANCE'), icon: 'bi bi-headset', color: 'assistance', href: 'https://www.vectore.it/contatti/' },
    ];

    if (isMobile) {
      listNavs.unshift({ name: 'homepage', label: this._translateService.instant('HOMEPAGE.NAME'), color: 'color-danger', icon: 'bi bi-house', routerLink: RoutesUrl.HOME });
    }

    if (me?.groupIds?.length === 0 || me === null) {
      return [
        { name: 'homepage', label: this._translateService.instant('HOMEPAGE.NAME'), color: 'color-danger', icon: 'bi bi-ban', routerLink: RoutesUrl.HOME },
      ]; 
    }

    const navConfig: PageConfig = pageToShowConfiguration as any;

    let navToShow: string[] = [];
    me.groupIds.forEach(role => {

      if (navConfig[role]) {
        navToShow.push(...navConfig[role]);
      }
    });

    let filteredNavs = listNavs.filter(nav => 
      navToShow.some(item => {
        if (item.includes('/')) {
          let [parent, child] = item.split('/');
          return nav.name === parent && nav.children?.some(sub => sub.name === child);
        }
        return item === nav.name;
      })
    );

    filteredNavs.forEach(nav => {
      if (nav.children) {
        nav.children = nav.children.filter(child => 
          navToShow.includes(`${nav.name}/${child.name}`)
        );
      }
    });

    return filteredNavs;
  }

  getAllAcessPages(me: Me): string[] {

    const pageConfig: PageConfig = pageToShowConfiguration as any;

    let pageToShow : string[] = [];
    me.groupIds.forEach(role => {
      if (pageConfig[role]) {
        pageToShow.push(...pageConfig[role]);
      }
    });

    if (me.groupIds.length === 0) {
      this._toastService.error(this._translateService.instant('INFO_MESSAGES.NO_GROUPS', { timeout: 0 }));
      return ['homepage'];
    }
    return pageToShow;
  }

  checkAccess(sectorName: string): Observable<boolean> {
    return this._meService.getMe$(this.tenantId).pipe(
      map(me => {
        let listSector = this.getAllAcessPages(me);
        return listSector.includes(sectorName);
      })
   );
  }
  
}
interface PageConfig {
  [key: string]: string[];
}
