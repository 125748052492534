import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Booking, BookingUsage } from '../models/booking';
import { TranslateService } from '@ngx-translate/core';
import { VehicleUsage, VehicleUsageClient } from '../models/vehicle-usages/vehicle-usages';

@Injectable({
  providedIn: 'root'
})
export class BookingUtilsService {
  constructor(
    private _datePipe: DatePipe,
    private _translateService: TranslateService
    ) { }

  setTitleBooking (booking: Booking) : Booking {
    if (booking.title === null || booking.title === undefined || booking.title === '') {
      booking.title = this._datePipe.transform(booking.startDate, 'd MMM yyyy • HH:mm');
    }
    return booking;
  }

  setDateBooking (booking: Booking) : Booking { 

    booking.availabilityDate = this._datePipe.transform(booking.availabilityDate, 'd MMM yyyy HH:mm');
    booking.untakenDate = this._datePipe.transform(booking.untakenDate, 'd MMM yyyy HH:mm');
    booking.startDate = this._datePipe.transform(booking.startDate, 'd MMM yyyy HH:mm');
    booking.endDate = this._datePipe.transform(booking.endDate, 'd MMM yyyy HH:mm');

    return booking;
  }
    

  setStatusBadgeBooking (booking: Booking) : Booking {

    booking.statusClient = { text: '', tooltip: '', cssClass: 'custom-badge'};

    if (booking.status.split('_')[0] === 'UNAVAILABLE') {
      booking.statusClient.text = this._translateService.instant("BOOKING_STATUS.IN_SEARCH");
      booking.statusClient.tooltip = this._translateService.instant("TOOLTIP_STATUS_BOOKING.UNAVAILABLE");
      booking.statusClient.cssClass = ' bg-violet-light';
    }

    if (booking.status.split('_')[0] === 'CLOSED') {
      booking.statusClient.text = this._translateService.instant("BOOKING_STATUS.CONCLUDED");
      let lastReturnedDate = this._datePipe.transform(booking.lastReturnedDate, 'd MMM yyyy HH:mm');
      booking.statusClient.tooltip = this._translateService.instant("TOOLTIP_STATUS_BOOKING.CLOSED", {x: lastReturnedDate});
      booking.statusClient.cssClass = ' bg-danger';
    }

    if (booking.status === 'OK') {
      booking.statusClient.text = this._translateService.instant("BOOKING_STATUS.APPROVED");
      booking.statusClient.tooltip = this._translateService.instant("TOOLTIP_STATUS_BOOKING.OK", {x: booking?.approvedByUser?.displayName ?? 'Vectore'});
      booking.statusClient.cssClass = ' bg-success';
    }

    if (booking.status === 'OK_ACTIVE' && booking.lastTakenDate !== null) {
      booking.statusClient.text = this._translateService.instant("BOOKING_STATUS.USE");
      booking.statusClient.tooltip = this._translateService.instant("TOOLTIP_STATUS_BOOKING.OK_ACTIVE_TAKEN", {x: booking.startDate});
      booking.statusClient.cssClass = ' bg-info';
    }

    if (booking.status === 'OK_ACTIVE' && booking.lastTakenDate === null) {
      booking.statusClient.text = this._translateService.instant("BOOKING_STATUS.APPROVED");
      booking.statusClient.tooltip = this._translateService.instant("TOOLTIP_STATUS_BOOKING.OK", {x: booking?.approvedByUser?.displayName ?? 'Vectore'});
      booking.statusClient.cssClass = ' bg-success';
    }

    if (booking.status === 'OK_DELAYED') {
      booking.statusClient.text = this._translateService.instant("BOOKING_STATUS.DELAY");
      booking.statusClient.tooltip = this._translateService.instant("TOOLTIP_STATUS_BOOKING.OK_DELAYED");
      booking.statusClient.cssClass = ' bg-danger';
    }

    if (booking.status === 'PENDING') {
      booking.statusClient.text = this._translateService.instant("BOOKING_STATUS.TO_BE_APPROVED");
      booking.statusClient.tooltip = this._translateService.instant("TOOLTIP_STATUS_BOOKING.PENDING");
      booking.statusClient.cssClass = ' bg-warning';
    }  

    if (booking.status === 'REJECTED') {
      booking.statusClient.text = this._translateService.instant("BOOKING_STATUS.REJECTED");
      booking.statusClient.tooltip = this._translateService.instant("TOOLTIP_STATUS_BOOKING.REJECTED");
      booking.statusClient.cssClass = ' bg-black';
    } 

    if (booking.status === 'CLOSED_DELAYED') {
      booking.statusClient.text = this._translateService.instant("BOOKING_STATUS.CONCLUDED");
      let lastReturnedDate = this._datePipe.transform(booking.lastReturnedDate, 'd MMM yyyy HH:mm');
      booking.statusClient.tooltip = this._translateService.instant("TOOLTIP_STATUS_BOOKING.CLOSED", {x: lastReturnedDate});
      booking.statusClient.cssClass = ' bg-danger';
    }
    
    if (booking.status === 'CLOSED_RETURNED') {
      booking.statusClient.text = this._translateService.instant("BOOKING_STATUS.CONCLUDED");
      let lastReturnedDate = this._datePipe.transform(booking.lastReturnedDate, 'd MMM yyyy HH:mm');
      booking.statusClient.tooltip = this._translateService.instant("TOOLTIP_STATUS_BOOKING.CLOSED", {x: lastReturnedDate});
      booking.statusClient.cssClass = ' bg-success';
    }

    if (booking.status === 'CLOSED_UNTAKEN') {
      booking.statusClient.text = this._translateService.instant("BOOKING_STATUS.EXPIRED");
      booking.statusClient.tooltip = this._translateService.instant("TOOLTIP_STATUS_BOOKING.CLOSED_UNTAKEN");
      booking.statusClient.cssClass = ' bg-danger';
    }
    
    if (booking.statusClient?.text.length === 0) {
      booking.statusClient.text = booking.status;
    }

    booking.statusClient.cssClass += ' custom-badge';

    return booking;
  }

  setStatusBadgeBookingInDetail(booking: Booking) : Booking  {
    booking.statusClient = { text: '', tooltip: '', cssClass: 'custom-badge'};

    let startDate = new Date(booking.startDate);
    let now = new Date();

    startDate.setHours(0, 0, 0, 0);
    now.setHours(0, 0, 0, 0);

    let tomorrow = new Date(now);
    tomorrow.setDate(now.getDate() + 1);

    if (booking.status === 'OK' && startDate.getTime() === tomorrow.getTime() ) {
      booking.statusClient.text = this._translateService.instant("BOOKING_STATUS.TOMORROW");
      booking.statusClient.tooltip = booking.status;
      booking.statusClient.cssClass = ' bg-violet';
    }


    if (booking.status === 'OK_ACTIVE' && booking.lastTakenDate !== null) {
      booking.statusClient.text = this._translateService.instant("BOOKING_STATUS.USE");
      booking.statusClient.tooltip = booking.status;
      booking.statusClient.cssClass = ' bg-info';
    }

    if (booking.status === 'OK_ACTIVE' && booking.lastTakenDate === null) {
      booking.statusClient.text = this._translateService.instant("BOOKING_STATUS.TO_BE_WITHDRAWN");
      booking.statusClient.tooltip = booking.status;
      booking.statusClient.cssClass = ' bg-warning';
    }

    if (booking.status === 'CLOSED_UNTAKEN') {
      booking.statusClient.text = this._translateService.instant("BOOKING_STATUS.NOT_GET_KEY");
      booking.statusClient.tooltip = booking.status;
      booking.statusClient.cssClass = ' bg-danger';
    }

    if (booking.status === 'CLOSED_DELAYED') {
      booking.statusClient.text = this._translateService.instant("BOOKING_STATUS.CONCLUDED");
      booking.statusClient.tooltip = booking.status;
      booking.statusClient.cssClass = ' bg-danger';
    }
    
    if (booking.status === 'CLOSED_RETURNED') {
      booking.statusClient.text = this._translateService.instant("BOOKING_STATUS.CONCLUDED");
      booking.statusClient.tooltip = booking.status;
      booking.statusClient.cssClass = ' bg-success';
    }

    booking.statusClient.cssClass += ' custom-badge';

    return booking;
  }

  setActionsBookings (booking: Booking, isFleetManager: boolean = false) : Booking {
    booking.actions = [
      { text: this._translateService.instant("ACTIONS.DETAIL"), value: 'detail'},
    ];

    if (isFleetManager && booking.status === 'PENDING') {
      booking.actions.push({ text: this._translateService.instant("ACTIONS.APPROVE"), value: 'approve'});
      booking.actions.push({ text: this._translateService.instant("ACTIONS.REJECT"), value: 'unapprove'});
    }

    if (booking.status.split('_')[0] !== 'CLOSED') {
      booking.actions.push({ text: this._translateService.instant("ACTIONS.EDIT"), value: 'edit'});
    }

    if (booking.status !== 'OK_ACTIVE' && booking.status.split('_')[0] !== 'CLOSED') {
      booking.actions.push({ text: this._translateService.instant("ACTIONS.DELETE"), value: 'delete'});
    }

    return booking;
  }

  setDateVehicleUsage (vehicleUsage: VehicleUsage) : VehicleUsage {
    vehicleUsage.startDate = this._datePipe.transform(vehicleUsage.startDate, 'd MMM yyyy HH:mm');
    vehicleUsage.endDate = this._datePipe.transform(vehicleUsage.endDate, 'd MMM yyyy HH:mm');

    return vehicleUsage;
  }

  setStatusActionBooking (bookingUsage: VehicleUsageClient) : VehicleUsageClient {
    bookingUsage.statusClient = { text: '', tooltip: '', cssClass: ''};

    let now = new Date();
    let nowUtc = now.toISOString();

    if (bookingUsage.startDate > nowUtc) {
      bookingUsage.statusClient.text = this._translateService.instant("BOOKING_STATUS.DELAY");
      bookingUsage.statusClient.cssClass = ' bg-error';
    }

    if (bookingUsage.startDate <= nowUtc && bookingUsage.endDate >= nowUtc) {
      bookingUsage.statusClient.text = this._translateService.instant("BOOKING_STATUS.USE");
      bookingUsage.statusClient.cssClass = ' bg-info';
    }

    if (bookingUsage.endDate < nowUtc) {
      bookingUsage.statusClient.text = this._translateService.instant("BOOKING_STATUS.CONCLUDED");
      bookingUsage.statusClient.cssClass = ' bg-success';
    }

    bookingUsage.statusClient.cssClass += ' custom-badge';

    return bookingUsage;
  }


  setStatusActionVehicleUsages (vehicleUsage: VehicleUsageClient) : VehicleUsageClient {
    vehicleUsage.statusClient = { text: '', tooltip: '', cssClass: ''};

    let now = new Date();
    let nowUtc = now.toISOString();

    if (vehicleUsage.startDate > nowUtc) {
      vehicleUsage.statusClient.text = this._translateService.instant("BOOKING_STATUS.DELAY");
      vehicleUsage.statusClient.cssClass = ' bg-error';
    }

    if (vehicleUsage.startDate <= nowUtc && vehicleUsage.endDate >= nowUtc) {
      vehicleUsage.statusClient.text = this._translateService.instant("BOOKING_STATUS.USE");
      vehicleUsage.statusClient.cssClass = ' bg-info';
    }

    if (vehicleUsage.endDate < nowUtc) {
      vehicleUsage.statusClient.text = this._translateService.instant("BOOKING_STATUS.CONCLUDED");
      vehicleUsage.statusClient.cssClass = ' bg-success';
    }

    vehicleUsage.statusClient.cssClass += ' custom-badge';

    return vehicleUsage;
  }

  setStatusBadgeBookingUsage (bookingUsage: BookingUsage, booking: Booking) : BookingUsage {
    bookingUsage.statusClient = { text: '', tooltip: '', cssClass: ''};

    if (booking.status === 'CLOSED_RETURNED') {
      bookingUsage.statusClient.text = this._translateService.instant("BOOKING_STATUS.RETURNED");
      bookingUsage.statusClient.cssClass = ' bg-success';
    }

    if (bookingUsage.startDate > new Date().toISOString() && booking.status === 'OK_ACTIVE') {
      bookingUsage.statusClient.text = this._translateService.instant("BOOKING_STATUS.TO_BE_USED");
      bookingUsage.statusClient.cssClass = ' bg-warning';
    }

    bookingUsage.statusClient.cssClass += ' custom-badge';

    return bookingUsage;
  }

  setActionsVehicleUsages (bookingUsage: VehicleUsageClient) : VehicleUsageClient {
    bookingUsage.actions = [
      { text: this._translateService.instant("ACTIONS.EDIT"), value: 'edit'},
    ];

    return bookingUsage;
  }  
}