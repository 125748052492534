import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'booking-modal-no-vehicles',
  templateUrl: './booking-modal-no-vehicles.component.html',
  styleUrls: ['./booking-modal-no-vehicles.component.scss']
})
export class BookingModalNoVehiclesComponent {

  @Output() onDeleteEmit = new EventEmitter;

  onClickDelete() {
    this.onDeleteEmit.emit();
  }
}
