<div class="card-duplicate-task-modal p-4">
    <header class="card-duplicate-task-modal-header d-flex justify-content-between align-items-center mb-4">
        <h4 class="card-duplicate-task-modal-title m-0">
            {{ 'TASKS.MODAL_DUPLICATE_TASK.TITLE' | translate }}
        </h4>
            
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </header>

    <content>
        <div class="card-duplicate-task-modal-content">
            @if (!onDuplicateProgressStep) {
                <div class="d-flex flex-column gap-2">
                    <div>{{ "TASKS.MODAL_DUPLICATE_TASK.MESSAGE" | translate:{x: elementsSelected.length} }}</div>
        
                    <dropdown-primary
                        [(selectedOption)]="rangeDateSelected"             
                        [options]="rangeDateOptions"                
                        (selectedOptionChange)="onRangeDateSelected($event)" 
                        [iconClass]="'bi bi-chevron-down'"
                        [cssClass]="'btn-lightgray rounded-1'">               
                    </dropdown-primary>
    
                    @if (warningMessage) {
                        <div class="message-warning color-danger mt-2"><i class="bi bi-exclamation-triangle"></i> {{ warningMessage | translate:{x: listWarningTasks.length} }}</div>
                    }
                </div>
            }
            @else {
                <div class="d-flex flex-column gap-2">
                    <div>{{ "TASKS.MODAL_DUPLICATE_TASK.PROGRESS_BAR_MESSAGE" | translate:{x: elementsDuplicated} }}</div>
                    <div class="progress" style="height: 10px;">
                        <div class="progress-bar bg-success" role="progressbar" [style.width]="progressBarValue + '%'" aria-valuenow="progressBarValue" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div class="color-danger"><i class="bi bi-exclamation-triangle me-2"></i>{{ "TASKS.MODAL_DUPLICATE_TASK.ATTENTION_PROCESS_RUNNING" | translate }}</div>
                </div>
            }
        </div>
    </content>

    <footer class="d-flex align-items-center justify-content-center gap-2 mt-4">
        @if (!onDuplicateProgressStep) {
            <div type="button" class="btn-gs-secondary-light" (click)="this.bsModalRef.hide()">
                {{ 'ACTIONS.CANCEL' | translate }}
            </div>
    
            <button type="submit" class="btn-gs-primary" (click)="duplicateTasks()">
                {{ 'ACTIONS.DUPLICATE' | translate }}
            </button>            
        }
    </footer>
</div>