<div class="dropdown" *ngIf="!isMobile; else mobileSelect">
    <button type="button" 
        [class]="cssClass"
        [ngClass]="{'gap-2' : iconClass !== ''}" 
        [disabled]="isDisabled"
        id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
        
        <span class="text-nowrap">{{option}}</span>
        <i [class]="iconClass"></i>
    </button>
    <ul class="dropdown-menu max-content" [class.active-scrollbar]="activeScrollbar" aria-labelledby="dropdownMenuButton1">
        <li *ngFor="let option of options;" role="menuitem" >
            <a class="dropdown-item" (click)="selectOption(option)">{{option.text}}</a>
        </li>
    </ul>
</div>

<ng-template #mobileSelect>
    <select class="form-select" [disabled]="isDisabled" [(ngModel)]="selectedMobileOption" (change)="onMobileSelectChange()">
        <option value="" disabled selected>Scegli un'opzione</option>
        <option *ngFor="let option of options" [value]="option.value">{{option.text}}</option>
    </select>
</ng-template>