import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, shareReplay, tap } from 'rxjs';
import { Vehicle, VehicleDataResponse, VehicleFieldResp, VehicleFile, VehicleFileResp, VehicleResp, VehicleType, VehicleTypesResp } from '../models/vehicle';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { MappingModel } from '../models/users';
import { CommonService } from './common.service';
import { BookingUsage } from '../models/booking';
import { FileModel } from '../models/common';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { VehicleRequest } from '../models/vehicle/vehicle-request';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { VehicleFilter } from '../models/vehicle/vehicle-filter';
import { TrackResponse } from '../models/track/track-response';
import { TrackFilter } from '../models/track/track-filter';

@Injectable({
  providedIn: 'root'
})
export class VehiclesService {
  private vehicleImageCache = new Map<string, Observable<any>>();

  constructor(
    private _http: HttpClient,
    private _commonService: CommonService,
    private _domSanitizer: DomSanitizer,
    private _translateService: TranslateService,
    private _toastrService: ToastrService,
  ) { }

  listVehicleFields$(tenantId: string, params?: any): Observable<VehicleFieldResp> {
    return this._http.get<VehicleFieldResp>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicle-fields`,
      { params: this._commonService.buildParams(params) });
  }

  createVehicle$(tenantId: string, vehicle: Vehicle): Observable<Vehicle> {
    return this._http.post<Vehicle>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles`, vehicle)
      .pipe(
        catchError(error => this._commonService.handleError(error))
      );
  }

  listVehicles$(tenantId: string, params?: VehicleFilter): Observable<VehicleResp> {
    return this._http.get<VehicleResp>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles`,
      { params: this._commonService.buildParams(params) });
  }

  getVehicleById$(tenantId: string, vehicleId: string, params?: any): Observable<Vehicle> {
    return this._http.get<Vehicle>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        catchError(error => this._commonService.handleError(error))
      );
  }

  updateVehicle$(tenantId: string, vehicleId: string, vehicle: Vehicle): Observable<Vehicle> {
    return this._http.patch<Vehicle>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}`, vehicle)
      .pipe(
        tap(response => {
          if (response) {
            this._toastrService.success(this._translateService.instant('VEHICLES.MESSAGES.SUCCESS_UPDATED'));
          }
        }),
        catchError(error => this._commonService.handleError(error))
      );
  }

  deleteVehicle$(tenantId: string, vehicleId: string): Observable<HttpResponse<Object>> {
    return this._http.delete<Vehicle>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}`,
      { observe: 'response' });
  }

  getVehicleImage$(tenantId: string, vehicleId: string, width?: number, height?: number): Observable<SafeUrl> {
    const cacheKey = `${tenantId}-${vehicleId}-${width}-${height}`;
    if (!this.vehicleImageCache.has(cacheKey)) {
      const params = {
        width: width,
        height: height
      };
      const requestObservable = this._http.get(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/image`, {
        params: this._commonService.buildParams(params),
        responseType: 'blob' as 'json'
      })
        .pipe(
          shareReplay(1),
          catchError(error => this._commonService.hanleErrorImage(error))
        );
      this.vehicleImageCache.set(cacheKey, requestObservable);
    }
    return this.vehicleImageCache.get(cacheKey)
      .pipe(
        map(response => {
          if (response) {
            let urlCreator = window.URL || window.webkitURL;
            return this._domSanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(response));
          }
          return null;
        })
      );
  }

  undeleteVehicle$(tenantId: string, vehicleId: string): Observable<HttpResponse<Object>> {
    return this._http.post<Vehicle>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/undelete`, {}, { observe: 'response' });
  }

  exportVehicles$(tenantId: string, exportData: MappingModel, params?: any): Observable<HttpResponse<Blob>> {
    return this._http.post(`${VECTORE_API_BASE_URL}/${tenantId}/export/vehicles`, exportData, {
      observe: 'response',
      params: this._commonService.buildParams(params),
      responseType: 'blob'
    })
      .pipe(
        catchError(error => this._commonService.handleError(error))
      );
  }

  listVehicleTracks$(tenantId: string, vehicleId: string, params?: TrackFilter): Observable<TrackResponse> {
    return this._http.get<TrackResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/tracks`,
      { params: this._commonService.buildParams(params) });
  }

  createVehicleUsages$(tenantId: string, vehicleId: string, usageRequest: BookingUsage): Observable<BookingUsage> {
    return this._http.post<BookingUsage>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/usages`, usageRequest);
  }

  getVehicleUsageById$(tenantId: string, vehicleId: string, usageId: string, params?: any): Observable<BookingUsage> {
    return this._http.get<BookingUsage>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/usages/${usageId}`,
      { params: this._commonService.buildParams(params) });
  }

  updateVehicleUsage$(tenantId: string, vehicleId: string, usageId: string, usageRequest: BookingUsage): Observable<BookingUsage> {
    return this._http.patch<BookingUsage>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/usages/${usageId}`, usageRequest);
  }

  deleteVehicleUsage$(tenantId: string, vehicleId: string, usageId: string): Observable<HttpResponse<Object>> {
    return this._http.delete<BookingUsage>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/usages/${usageId}`,
      { observe: 'response' });
  }

  linkVehicleFile$(tenantId: string, vehicleId: string, file: FileModel): Observable<HttpResponse<VehicleFile>> {
    return this._http.post<VehicleFile>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/files`, file,
      { observe: 'response' });
  }

  listVehicleFiles$(tenantId: string, vehicleId: string, params?: any): Observable<VehicleFileResp> {
    return this._http.get<VehicleFileResp>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/files`,
      { params: this._commonService.buildParams(params) });
  }

  getVehicleFileById$(tenantId: string, vehicleId: string, fileId: string, params?: any): Observable<VehicleFile> {
    return this._http.get<VehicleFile>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/files/${fileId}`,
      { params: this._commonService.buildParams(params) });
  }

  updateVehicleFile$(tenantId: string, vehicleId: string, fileId: string, file: FileModel): Observable<VehicleFile> {
    return this._http.patch<VehicleFile>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/files/${fileId}`, file);
  }

  deleteVehicleFile$(tenantId: string, vehicleId: string, fileId: string): Observable<HttpResponse<Object>> {
    return this._http.delete<VehicleFile>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/files/${fileId}`,
      { observe: 'response' });
  }

  downloadVehicleFile$(tenantId: string, vehicleId: string, fileId: string): Observable<HttpResponse<any>> {
    return this._http.get(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/files/${fileId}/content`,
      { observe: 'response', responseType: 'blob' });
  }

  getVehicleData$(tenantId: string, params?: any): Observable<VehicleDataResponse> {
    return this._http.get<VehicleDataResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicle-data`,
      { params: this._commonService.buildParams(params) });
  }

  mapVehicleToRequest(vehicle: Vehicle): VehicleRequest {
    return {
      versionId: vehicle.versionId ?? null,
      licensePlate: vehicle.licensePlate ?? null,
      fleetEntryDate: vehicle.fleetEntryDate ?? null,
      fleetEntryValue: vehicle.fleetEntryValue ?? null,
      displayName: vehicle.displayName ?? null,
      locationId: vehicle.locationId ?? null,
      purchaseTypeId: vehicle.purchaseTypeId ?? null,
      imageFileId: vehicle.imageFileId ?? null,
      mileage: vehicle.mileage ?? null,
      tagIds: vehicle.tagIds ?? null,
      extraFields: vehicle.extraFields ?? null,
      fleetExitDate: vehicle.fleetExitDate ?? null,
      disabledFrom: vehicle.disabledFrom ?? null,
      disabledTo: vehicle.disabledTo ?? null,
    }
  }
}