<div class="container-upload-input" 
    (click)="fileInput.click();"
    (dragover)="preventDefault($event)"
    (drop)="handleDrop($event);">

    <div class="text-center m-2">

        <i class="bi bi-inbox fs-1 text-secondary"></i><br>

        <input #fileInput class="d-none" type="file" name="file" id="file"
        (change)="uploadStorageFile($event)"
        (click)="fileInput.value = ''">

        <label for="file" class="fw-bold">{{'INPUT_UPLOAD_FILES.TITLE' | translate}}</label>
        <p>{{'INPUT_UPLOAD_FILES.SUB_FILE' | translate}}</p>

    </div>
</div>