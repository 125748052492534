<div class="card-booking-usage-mileage">
    <div class="card-booking-usage-header mb-4">
        <h2 class="card-booking-usage-header-title m-0 p-0">{{'MILEAGE' | translate}}</h2>
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </div>

    <form [formGroup]="bookingUsageForm" (ngSubmit)="onSubmit()">

        <div class="form-group mb-3">
            <label for="startMileage" class="form-label">{{ 'BOOKINGS.MILEAGE_START' | translate }}</label>
            <div class="input-group mb-1">
                <input type="number" formControlName="startMileage" class="form-control" id="startMileage" aria-describedby="basic-addon3"
                    [ngClass]="{'is-invalid' : bookingUsageForm.get('startMileage').hasError('invalid')}">
                <span class="input-group-text" id="basic-addon3">Km</span>
            </div>

            <div class="feedback text-danger mt-2" *ngIf="bookingUsageForm.get('startMileage').hasError('invalid')">{{ 'BOOKINGS.ATTENTION_START_MILEAGE' | translate: {value: vehicle?.mileage} }}</div>
        </div>
    
        <label for="endMileage" class="form-label">{{ 'BOOKINGS.MILEAGE_END' | translate }}</label>
        <div class="input-group mb-1">
            <input type="number" formControlName="endMileage" class="form-control" id="endMileage" aria-describedby="basic-addon3"
                [ngClass]="{'is-invalid' : bookingUsageForm.get('endMileage').hasError('invalid')}">

            <span class="input-group-text" id="basic-addon3">Km</span>
        </div>

        <div class="feedback text-danger mt-2" *ngIf="bookingUsageForm.get('endMileage').hasError('invalid')">{{ 'BOOKINGS.ATTENTION_MILEAGE' | translate}}</div>

        <div class="card-booking-usage-footer">
            <button type="reset" class="btn-gs-secondary" (click)="this.bsModalRef.hide()">{{ 'ACTIONS.CANCEL' | translate }}</button>
            <button type="submit" class="btn-gs-primary" [disabled]="!this.bookingUsageForm.valid">{{ 'ACTIONS.SAVE' | translate }}</button>
        </div>

    </form>

</div>