import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'button-add-date',
  template: `
    <div class="d-flex">
      
      <div class="btn-gs-primary-noborder d-flex align-items-center gap-2 cursor-pointer text-decoration-none" (click)="dp.toggle()">
        
      <div *ngIf="!!value">
        {{ value | date: 'dd-MM-yyyy' }}
      </div>
      <div *ngIf="!value">
        {{ 'ADD_DATE' | translate }}
      </div>
      
      <i class="bi bi-calendar" [attr.aria-expanded]="dp.isOpen" type="button" *ngIf="!!value"></i>
      <i class="bi bi-plus-circle" [attr.aria-expanded]="dp.isOpen" type="button" *ngIf="!value"></i>

      <input type="text" style="width: 0px; visibility: hidden;" inputmode='none'
          bsDatepicker 
          #dp="bsDatepicker" 
          [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', isAnimated: true, adaptivePosition: true, showClearButton: true }" 
          placement="top" 
          [(ngModel)]="value"
          (bsValueChange)="onChange($event)">
          
      </div>
    </div>
    `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ButtonAddDateComponent),
      multi: true
    }
  ]
})
export class ButtonAddDateComponent implements ControlValueAccessor {
  value: Date | null = null;
  onChange = (value: any) => {};
  onTouched = () => {};

  @Input() isMobile: boolean = false;

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
