<div class="modal-gs p-3 d-flex flex-column gap-3">
    <header class="d-flex justify-content-between align-items-center">
        <h4 class="modal-title fs-5 pull-left">{{'VERSIONS.NEW' | translate}}</h4>
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </header>

    <div class="modal-gs-body" *ngIf="data && !isLoading">
        
        <div class="d-flex gap-2">
            <img *ngIf="data?.topBody?.srcImage| async as imageUrl" [src]="imageUrl" alt="Image">

            <div class="d-flex flex-column justify-content-between">
                <div class="fringe-title">{{data?.topBody?.title}}</div>
                <div class="fringe-sub-title">{{data?.topBody?.subTitle}}</div>
            </div>
        </div>

        <hr>

        <div class="d-flex justify-content-between me-4">

            <div class="d-flex flex-column gap-4">

                <div class="d-flex flex-column gap-2">
                    <span>{{'CARD_FRINGE_BENEFIT.COST_ACI' | translate}}</span>
                    <div class="data">{{data?.aciCost | number:'1.2-4'}}</div>
                </div>

                <div class="d-flex flex-column gap-2">
                    <span>{{'CARD_FRINGE_BENEFIT.FB_YEAR' | translate}}</span>
                    <div class="data">{{data?.fringeBenefitYear | number:'1.2-2'}}</div>
                </div>
            </div>


            <div class="container-donut d-flex flex-column gap-3">
                <circle-progress
                    [radius]="90"
                    [space]="-20"
                    [percent]="30"
                    [startFromZero]="true"
                    [outerStrokeWidth]="20"
                    [innerStrokeWidth]="20"
                    [outerStrokeColor]="'#0DCAF0'"
                    [innerStrokeColor]="'#e7e8ea'"
                    [showTitle]="true"
                    [showSubtitle]="true"
                    [title]="'CARD_FRINGE_BENEFIT.FB_MONTH' | translate"
                    [titleFontSize]="'1rem'"
                    [titleColor]="'#6C757D'"
                    [titleFontWeight]="'400'"
                    [subtitle]="data?.fringeBenefitMonth | number:'1.2-2'"
                    [subtitleFontSize]="'1.25rem'"
                    [subtitleColor]="'#212529'"
                    [subtitleFontWeight]="'600'"
                    [showUnits]="false"
                    [clockwise]="true"
                    [animation]="true"
                    [animationDuration]="300"
                    ></circle-progress>

                <div class="container-legend d-flex justify-content-center align-items-center gap-2">
                    <div class="color"></div>
                    <div class="legend d-flex gap-1">
                        {{'CARD_FRINGE_BENEFIT.TAX' | translate}}
                        <div class="tax">{{data?.tax}}%</div>
                    </div>                    
                </div>
            </div>
        </div>

    </div>

    <div class="modal-gs-body w-100 d-flex justify-content-center align-items-center" *ngIf="isLoading">
        <div class="spinner-border text-success" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</div>