<div class="asset-modal">
    <div class="asset-modal-header mb-4">
        <h4 class="modal-title fs-5 pull-left" *ngIf="modalStatus.isCreate">{{'ASSETS.MODAL.NEW' | translate}}</h4>
        <h4 class="modal-title fs-5 pull-left" *ngIf="modalStatus.isPatch">{{'ASSETS.MODAL.PATCH' | translate}}</h4>
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </div>

    <div class="asset-modal-body">
        <form class="d-flex flex-column gap-3" [formGroup]="assetForm">

            <div class="container-avatar">
                <input #fileInput 
                    type="file" class="form-control d-none" 
                    id="imageFileId"
                    aria-describedby="imageFileIdHelp" (change)="onFileSelected($event)">

                <div class="avatar-placeholder-md asset-placeholder">
                    <img class="w-100 h-100" *ngIf="previewAvatar | async as safeUrl" [src]="safeUrl">
                </div>

                <button-first-type
                    [cssClass]="'btn-gs-primary-icon'"
                    [buttonText]="selectedFile ? 'CHANGE_PHOTO' : 'UPLOAD_PHOTO'"
                    [iconClass]="'bi-camera-fill'"
                    (click)="fileInput.click()">
                </button-first-type>
            </div>

            <div class="form-group">
                <label for="displayName" class="form-label">{{'NAME_TO_VIEW' | translate}} <span>*</span></label>
                <input type="text" class="form-control" formControlName="displayName">
            </div>

            <div class="form-group">
                <label for="code" class="form-label">{{'ASSETS.CODE' | translate}} <span>*</span></label>
                <input type="text" class="form-control" formControlName="code">
            </div>
    
            <div class="form-group">
                <label for="tag" class="form-label">{{'INSERT_TAG' | translate}}</label>        
                <input type="text" class="form-control" [placeholder]="'INSERT_TAG' | translate"
                    formControlName="tag"
                    [isAnimated]="true"
                    [typeahead]="tags$"
                    [typeaheadAsync]="true"
                    [typeaheadMinLength]="0"
                    (typeaheadOnSelect)="onTagSelected($event)">
                
                <div class="d-flex flex-wrap gap-2 mt-2">
                    <button type="button" class="btn-sm-gs-full-secondary d-flex align-items-center gap-1" *ngFor="let tag of selectedTags">
                        {{tag.name}}
                        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="removeTag(tag.id)"></button>
                    </button>
                </div>
            </div>
    
            <div class="form-group">
                <label for="headquarter" class="form-label">{{'HEADQUARTER.NAME' | translate}}</label>
                <dropdown-primary-headquarter
                    [headquarterId]="asset?.headquarterId"
                    (onSelectedEvent)="onHeadquarterSelected($event)">                    
                </dropdown-primary-headquarter>
            </div>
    
            <div class="form-group">
                <label for="assetType" class="form-label">{{'ASSETS.TYPE_ASSET' | translate}} <span>*</span></label>
                <dropdown-primary 
                    class="w-100"
                    [selectedOption]="assetOptionSelected"
                    [options]="assetTypesOptions"
                    [isDisabled]="isDisableAssetType"
                    (selectedOptionChange)="onTaskTypeSelected($event)"
                    [emitNgOnInit]="false"
                    [activeScrollbar]="true"
                    [iconClass]="'bi bi-chevron-down'"
                    [cssClass]="'btn-dropdown'">
                </dropdown-primary>
            </div>
            
            <div class="mt-3">
                <formly-form [form]="assetForm" [fields]="fields"></formly-form>
            </div>

            <div class="container-downloads d-flex flex-column gap-4 mb-4">
                <input-upload-files (fileInfo)="addPendingFile($event)" [tenantId]="tenantId" [bucketName]="bucketName"></input-upload-files>                        
                <output-files [documents]="listDocuments" (downloadDocument)="downloadFile($event)" (deleteDocument)="removeFile($event)"></output-files>
            </div>
        </form>
    </div>

    <div class="asset-modal-footer">
        <button-first-type 
            [cssClass]="'btn-gs-secondary'"
            [buttonText]="'ACTIONS.CANCEL' | translate"
            (click)="bsModalRef.hide()">
        </button-first-type>
        <button class="btn-gs-primary" type="submit" [disabled]="assetForm.invalid" (click)="onSubmit()" *ngIf="this.modalStatus.isCreate">
            {{'ACTIONS.NEW' | translate}}
        </button>

        <button class="btn-gs-primary" type="submit" [disabled]="assetForm.invalid" (click)="onSubmit()" *ngIf="this.modalStatus.isPatch">
            {{'ACTIONS.EDIT' | translate}}
        </button>
    </div>
</div>