import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'input-date-picker',
  templateUrl: './input-date-picker.component.html',
  styleUrls: ['./input-date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDatePickerComponent),
      multi: true
    }
  ]
})
export class InputDatePickerComponent implements ControlValueAccessor {
  value: Date | null = null;
  onChange = (value: any) => {};
  onTouched = () => {};
  
  @Input() cssClass: string;
  @Input() isMobile: boolean = false;
  @Input() placeholder: string = 'SELECT_DATE';

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
