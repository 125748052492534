<div class="card-create-booking" [ngClass]="{'card-create-booking-mobile': isMobile}">
    <div class="card-create-booking-header">

        <div class="card-create-booking-title">            
            <h2 class="modal-title title fs-5 pull-left">{{'BOOKINGS.NAME' | translate}}</h2>
            <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
        </div>

        <div class="card-create-booking-stepper">

        </div>

    </div>

    <form class="form" [formGroup]="bookingCreateForm">
        
        <div class="card-create-booking-body"> 
            
            <div class="step-1" *ngIf="modalStaus.isStepOne">

                <div class="form-group">
                    <label for="titleBooking" class="form-label">{{'TITLE' | translate}} <span>*</span></label>
                    <input type="text" class="form-control" placeholder="{{ 'BOOKINGS_MODAL.TITLE_DESCRIPTION' | translate}}" formControlName="titleBooking">
                </div>

                <div class="form-group">
                    <label for="headquarter" class="form-label">{{'HEADQUARTER.NAME' | translate}} <span>*</span></label>
                    <dropdown-primary-headquarter
                        [headquarterId]="booking?.headquarterId"
                        (onSelectedEvent)="onHeadquartersSelected($event)">                        
                    </dropdown-primary-headquarter>
                </div>
        
                <div class="form-group">
                    <label for="location" class="form-label">{{'LOCATION.NAME' | translate}} <span>*</span></label>
                    <dropdown-primary-location
                        [headquarterId]="bookingCreateForm.controls['headquarterId'].value"
                        [locationId]="booking?.locationId"
                        (onSelectedEvent)="onLocationSelected($event)">                        
                    </dropdown-primary-location>
                </div>
        
                <div class="form-group">
                    <label for="type-booking" class="form-label">{{'TYPOLOGY_OF_BOOKING' | translate}} <span>*</span></label>
                    <dropdown-dynamic 
                        class="w-100"
                        [dropdownId]="dropdownTypeBookingId" 
                        [activeScrollbar]="true"
                        [iconClass]="'bi bi-chevron-down'"
                        [cssClass]="'btn-dropdown'">
                    </dropdown-dynamic>
                </div>

                <div *ngIf="isFleetManager">
                    <h3 class="title">Driver</h3>
            
                    <div class="form-group">
                        <label for="driver" class="form-label">{{'BOOKINGS.DRIVER' | translate}} <span>*</span></label>
                        <input 
                            type="search"
                            formControlName="driverName"
                            [typeahead]="driverNames$"
                            [typeaheadAsync]="true"
                            [typeaheadMinLength]="0"
                            class="form-control"> 
                    </div>
                </div>
        
        
                <h3 class="title">{{'START_USE_VEHICLE' | translate}}</h3>
                <div class="row">
        
                    <div class="col-sm-12 col-md-6 col-xl-6 form-group mb-3">
                        <label for="startDateBooking" class="form-label">{{'START_DATE' | translate}}</label>
                        <input-date-picker formControlName="startDateBooking"></input-date-picker>
                    </div>
        
                    <div class="col-sm-12 col-md-6 col-xl-6 form-group">
                        <label for="startTimeBooking" class="form-label">{{'START_TIME' | translate}}</label>
                        <!-- <input type="time" class="form-control" id="startTimeBooking" [placeholder]="'SELECT_TIME' | translate"
                            [class.is-invalid]="bookingCreateForm.get('startDateBooking')?.touched && bookingCreateForm.hasError('invalidDateRange')"
                            formControlName="startTimeBooking" > -->

                        <input type="time" class="form-control" id="startTimeBooking" [placeholder]="'SELECT_TIME' | translate"
                            formControlName="startTimeBooking" >
                    </div>
        
                    <span *ngIf="closeUntakenAfterMinutes" class="mt-1">{{ 'BOOKINGS_MODAL.UNTAKEN_MESSAGE' | translate:{value: closeUntakenAfterMinutes} }}</span>
        
                </div>
        
                <h3 class="title">{{'END_USE_VEHICLE' | translate}}</h3>
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-xl-6 form-group mb-3">
                        <label for="endDateBooking" class="form-label">{{'END_DATE' | translate}}</label>
                        <!-- <input-date-picker 
                            formControlName="endDateBooking" 
                            [cssClass]="bookingCreateForm.get('endDateBooking').hasError('invalidDateRange') ? 'is-invalid' : ''">
                        </input-date-picker> -->

                        <input-date-picker formControlName="endDateBooking"></input-date-picker>
                    </div>
        
                    <div class="col-sm-12 col-md-6 col-xl-6 form-group">
                        <label for="endTimeBooking" class="form-label">{{'END_TIME' | translate}}</label>
                        <!-- <input type="time" class="form-control" id="endTimeBooking" [placeholder]="'SELECT_TIME' | translate"
                            [class.is-invalid]="bookingCreateForm.get('endDateBooking').hasError('invalidDateRange') ? 'is-invalid' : ''"
                            formControlName="endTimeBooking" > -->

                        <input type="time" class="form-control" id="endTimeBooking" [placeholder]="'SELECT_TIME' | translate"
                            formControlName="endTimeBooking" >
                    </div>
        
                </div>
        
                <div class="form-group" *ngIf="fields?.length > 0">
                    <h3 class="title">{{'INFORMATION' | translate}}</h3>
                    <formly-form [form]="bookingCreateForm" [fields]="fields"></formly-form>
                </div>

            </div>

            <div class="step-2" *ngIf="modalStaus.isStepTwo">

                <div *ngIf="!booking?.vehicle">
                
                    <div *ngIf="vehiclesToShow.length; else noVehiclesFound">
                        <booking-modal-vehicles-to-show [vehiclesToShow]="vehiclesToShow" (onChangeEmitVehicleChecked)="changeVehicleChecked($event)">
                        </booking-modal-vehicles-to-show>
                    </div>
    
                    <ng-template #noVehiclesFound>
                        <booking-modal-no-vehicles (onDeleteEmit)="deleteBooking()"></booking-modal-no-vehicles>
                    </ng-template>

                </div>                

                <div *ngIf="booking?.status === 'OK' || booking?.status === 'OK_ACTIVE'">
                    <div class="container-if-change-vehicle" *ngIf="!hideMessageChangeVehicle">
                        <div class="container-if-change-vehicle-text">
                            <div class="container-if-change-vehicle-title">{{'CHANGE_VEHICLE' | translate}}</div>
                            <div class="container-if-change-vehicle-content">{{'ALTERNATIVES_VEHICLE' | translate}}</div>
                        </div>
                        <button class="btn-primary-light" (click)="showVehicles()">{{'BUTTONS.SHOW_VEHICLES' | translate}}</button>
                    </div>

                    <div *ngIf="hideMessageChangeVehicle">
                        <booking-modal-vehicles-to-show [vehiclesToShow]="vehiclesToShow" (onChangeEmitVehicleChecked)="changeVehicleChecked($event)">
                        </booking-modal-vehicles-to-show>
                    </div>
                    
                    <div class="container-vehicle isCheckedVehicle" *ngIf="booking.vehicle">
                        <div class="vehicle-avatar"></div>
                        <div class="vehicle-content">
    
                            <div class="vehicle-content-header">
                                <div class="license-plate">{{booking.vehicle.licensePlate}} <badge-booking [text]="'Prenotato'"></badge-booking></div>
                                <div class="mileage" *ngIf="booking.vehicle?.mileage">{{booking.vehicle.mileage}} km</div>
                            </div>
    
                            <div class="vehicle-version">{{booking.vehicle.vehicleVersion?.name}}</div>
                            <div class="vehicle-content-important" *ngIf="booking.vehicle?.vehicleType && booking.vehicle?.fuelType">
                                <div class="vehicle-type">{{booking.vehicle.vehicleType?.name}}</div>
                                •
                                <div class="vehicle-fuel-type">{{booking.vehicle.fuelType?.name}}</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>    
    
        </div>

        <div class="card-create-booking-footer">
                
                <button type="button" class="btn-gs-secondary" (click)="bsModalRef.hide()" *ngIf="modalStaus.isStepOne && !modalStaus.isBookingCreated">
                    {{'ACTIONS.CANCEL' | translate}}
                </button>

                <button type="button" class="btn-gs-secondary" (click)="deleteBooking()" *ngIf="modalStaus.isStepOne && modalStaus.isBookingCreated">
                    {{'BUTTONS.CANCEL_BOOKING' | translate}}
                </button>

                <button type="button" class="btn-gs-secondary" (click)="backToStep1()" *ngIf="modalStaus.isStepTwo">
                    {{'ACTIONS.BACK' | translate}}
                </button>



                <button type="button" class="btn-gs-primary"
                    *ngIf="modalStaus.isStepOne && !modalStaus.isBookingCreated" (click)="createBooking()">
                    {{'TO_BOOK' | translate}}
                </button>

                <button type="button" class="btn-gs-primary"
                    *ngIf="modalStaus.isStepOne && modalStaus.isBookingCreated" (click)="patchBooking()">
                    {{'ACTIONS.EDIT' | translate}}
                </button>

                <!-- <button type="button" class="btn-primary-light" *ngIf="isStepOne && isBookingCreated" (click)="goToStep2()">
                    {{'ACTIONS.GO_NEXT' | translate}}
                </button> -->

                

                <button type="button" class="btn-gs-primary" *ngIf="modalStaus.isStepTwo && modalStaus.isBookingCreated && vehiclesToShow?.length > 0" 
                    (click)="addVehicleWithPatchBooking()">{{'ACTIONS.CONFIRM' | translate}}
                </button>

                <button type="button" class="btn-gs-primary" *ngIf="modalStaus.isStepTwo && modalStaus.isBookingCreated && vehiclesToShow?.length === 0" 
                    (click)="closeModal()">{{'ACTIONS.CLOSE' | translate}}
                </button>

        </div>

    </form>

</div>