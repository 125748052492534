<div class="container-description d-flex flex-column gap-2">
    <div class="title">{{'TASKS.MODAL_EDIT_TASK.DESCRIPTION' | translate}}</div>

    <div class="description" *ngIf="!isEditMode">{{description}}</div>

    <form [formGroup]="form" *ngIf="isEditMode">
        <textarea type="text" class="form-control border-none" id="descriptionId" [placeholder]="'PLACEHOLDER.DESCRIPTION' | translate"
            formControlName="description">
        </textarea>
    </form>

    <div class="card-light-footer d-flex gap-2">
        <button class="btn-gs-primary-noborder mt-4" 
            *ngIf="!isEditMode" (click)="editMode()">{{'ACTIONS.EDIT' | translate}}</button>

        <button class="btn-gs-secondary" (click)="cancel()" *ngIf="isEditMode">{{'ACTIONS.CANCEL' | translate}}</button>
        <button class="btn-gs-primary" (click)="onSubmit()" *ngIf="isEditMode" [disabled]="form.invalid">{{'ACTIONS.SAVE' | translate}}</button>
    </div>
</div>