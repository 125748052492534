<form [formGroup]="form">
    <div class="form-group">
        <label for="invoiceName">{{ 'TASKS.LINK_INVOICES' | translate }}</label>
        <ng-select
            [items]="invoicesToShow"
            #select
            bindLabel="invoiceNumber"
            dropdownPosition="auto"
            bindValue="id"
            [placeholder]="''"
            [virtualScroll]="true"
            (change)="addInvoiceToTask($event)"
        >
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                <div>
                    <span>{{ 'INVOICES.invoiceNumber' | translate }}: </span> <span>{{ item.invoiceNumber }}</span>
                </div>
                <small>{{ item.supplier?.code }} | {{ item.supplier?.name }}</small>
            </ng-template>
        </ng-select>
    </div>
</form>

@if (listInvoicesLinkedToTask.length > 0) {
    <div class="d-flex flex-column gap-3 mt-4">
        <h5>{{ 'TASKS.LINKED_INVOICES' | translate }}</h5>
        @for (item of listInvoicesLinkedToTask; track item) {
            <div class="d-flex justify-content-between">
                <div class="d-flex flex-column">
                    <div class="small-title">{{ item.invoice?.supplier?.name }}</div>
                    <div>{{ item.invoice?.invoiceNumber }}, {{ item.invoice?.invoiceDate | date:'dd/MM/yyyy' }}, {{ item.invoice?.grossAmount | number:'1.2-2' }}</div>
                </div>
                <div>
                    <button class="btn btn-danger">
                        <i class="bi bi-trash" (click)="onDelete(item.id)"></i>
                    </button>
                </div>
            </div>
        }
    </div>
}