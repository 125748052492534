import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { forkJoin, map } from 'rxjs';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { AccessUtilsService } from 'src/app/shared/utilities/access-utils.servic';
import { ModalUtilsService } from "src/app/shared/utilities/modal-utils.service";

@Component({
  selector: 'app-actions-modal',
  templateUrl: './actions-modal.component.html',
  styleUrls: ['./actions-modal.component.scss']
})
export class ActionsModalComponent {
  tenantId = this._tenantService.getTenantId();
  
  actions: ActionItems[];

  constructor(
    private _tenantService: TenantService,
    private _modalUtilsService: ModalUtilsService,
    private _accessUtilsService: AccessUtilsService,
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
    this.actions = [
      {
        sector: 'bookings/my-bookings',
        header: 'MODAL_ACTIONS.BOOKING_NOW',
        description: 'MODAL_ACTIONS.BOOKING_NOW_DESCRIPTION',
        icon: 'bi-bookmark',
        action: () => this.openBookingModal()
      },
      {
        sector: 'activity/tasks',
        header: 'MODAL_ACTIONS.CREATE_TASK',
        description: 'MODAL_ACTIONS.CREATE_TASK_DESCRIPTION',
        icon: 'icon-task-vectore',
        action: () => this.openCreateTaskModal()
      },
      {
        sector: 'activity/costs',
        header: 'MODAL_ACTIONS.CREATE_COST',
        description: 'MODAL_ACTIONS.CREATE_COST_DESCRIPTION',
        icon: 'bi-wallet2',
        action: () => this.openCreateTaskModal(true)
      },
      {
        sector: 'vehicles',
        header: 'MODAL_ACTIONS.ADD_VEHICLE',
        description: 'MODAL_ACTIONS.ADD_VEHICLE_DESCRIPTION',
        icon: 'bi-truck',
        action: () => this.openVehicleModal()
      },
      {
        sector: 'page-assets',
        header: 'MODAL_ACTIONS.ADD_ASSET',
        description: 'MODAL_ACTIONS.ADD_ASSET_DESCRIPTION',
        icon: 'bi-layers',
        action: () => this.openCreateAssetModal()
      },
      {
        sector: 'imports',
        header: 'MODAL_ACTIONS.IMPORT_TRACE',
        description: 'MODAL_ACTIONS.IMPORT_TRACE_DESCRIPTION',
        icon: 'bi-download',
        action: () => this.openImportModal()
      },
      {
        sector: 'imports',
        header: 'MODAL_ACTIONS.IMPORT_DOWNLOAD_TRACE',
        description: 'MODAL_ACTIONS.IMPORT_DOWNLOAD_TRACE_DESCRIPTION',
        icon: 'bi-file-earmark-excel',
        action: () => this.openImportTraceDownloadModal()
      }
    ];

    const accessCheckObservables = this.actions.map(action =>
      this._accessUtilsService.checkAccess(action.sector).pipe(
         map(hasAccess => ({ action, hasAccess }))
      )
    );
     
    forkJoin(accessCheckObservables).pipe(
      map(results => results.filter(result => result.hasAccess))
    ).subscribe(filteredActions => {
      this.actions = filteredActions.map(result => result.action);
    });
  }

  openBookingModal() {
    this.bsModalRef.hide();
    setTimeout(() => {
      this._modalUtilsService.openBookingModal().subscribe();;     
    }, 200);
  }

  openCreateTaskModal(isCost: boolean = false) {
    this.bsModalRef.hide();
    setTimeout(() => {
      this._modalUtilsService.openCreateModalTask(isCost).subscribe();
    }, 200)
  }

  openVehicleModal() {
    this.bsModalRef.hide();
    setTimeout(() => {
      this._modalUtilsService.openVehicleModal().subscribe();   
    }, 200);
  }

  openCreateAssetModal() {
    this.bsModalRef.hide();
    setTimeout(() => {
      this._modalUtilsService.openAssetModal().subscribe();
    }, 200);
  }

  openImportModal() {
    this.bsModalRef.hide();
    setTimeout(() => {
      this._modalUtilsService.openCreateImportModal().subscribe();
    }, 200);
  }

  openImportTraceDownloadModal() {
    this.bsModalRef.hide();
    setTimeout(() => {
      this._modalUtilsService.openDownloadTraceImportModal().subscribe();
    }, 200);
  }
}

interface ActionItems {
  sector: string;
  header: string;
  description: string;
  icon?: string;
  action?: () => void;
}