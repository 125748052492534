<div class="card-filter-reports p-3">

    <header class="d-flex justify-content-between mb-4">
        <h4>{{ 'REPORTS.REPORT_FILTER.TITLE' | translate }}</h4>
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </header>

    <div class="card-filter-reports-body mb-4 d-flex flex-column gap-3">
        <dropdown-primary
            [selectedOption]="filterSelected"
            [options]="listFilterOption"
            [emitNgOnInit]="false"
            (selectedOptionChange)="onFilterSelected($event)"
            [activeScrollbar]="true"
            [iconClass]="'bi bi-chevron-down'"
            [cssClass]="'btn-dropdown'">            
        </dropdown-primary>

        <form [formGroup]="form">
            <div class="form-range-date" *ngIf="typeFilter === 'range-date'">
                <div class="input-group text-black-50 d-flex align-items-center border rounded bg-white">
                    <input type="text" class="from-date form-control border-0" [placeholder]="'START_DATE' | translate" 
                        bsDatepicker formControlName="startDate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
                    <i class="bi bi-arrow-right"></i>
            
                    <input type="text" class="to-date form-control border-0" [placeholder]="'END_DATE' | translate" 
                        bsDatepicker formControlName="endDate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
                    <i class="bi bi-calendar-check p-2"></i>
                  </div>
            </div>

            <div class="form-vehicle-type" *ngIf="typeFilter === 'vehicle-type'">
                <dropdown-primary-vehicle-type 
                    [vehicleTypeId]="form.value.vehicleTypeId ?? null"
                    (onSelectedEvent)="onVehicleTypeSelected($event)">                    
                </dropdown-primary-vehicle-type>
            </div>

            <div class="form-headquarter" *ngIf="typeFilter === 'headquarter'">
                <input type="text" class="form-control" [placeholder]="'TYPE_TO_SEARCH' | translate"
                    formControlName="headquarterName"
                    [isAnimated]="true"
                    [typeahead]="headquarterName$"
                    [typeaheadAsync]="true"
                    [typeaheadMinLength]="0"
                    (typeaheadOnSelect)="onSelectHeadquarter($event)">
            </div>

            <div class="form-mileage" *ngIf="typeFilter === 'mileage'">

                <div class="form-check" (click)="selectRadioMileage('before-100k')">
                  <input class="form-check-input" type="radio" name="typeMileage" id="before-100k" value="before-100k" formControlName="typeMileage">
                  <label class="form-check-label" for="before-100k">
                    {{'REPORTS.REPORT_FILTER.FILTER_KM.BEFORE_100K' | translate}}
                  </label>
                </div>

                <div class="form-check" (click)="selectRadioMileage('after-100k')">
                  <input class="form-check-input" type="radio" name="typeMileage" id="after-100k" value="after-100k" formControlName="typeMileage">
                  <label class="form-check-label" for="after-100k">
                    {{'REPORTS.REPORT_FILTER.FILTER_KM.AFTER_100K' | translate}}
                  </label>
                </div>
                
                <div class="form-check" (click)="selectRadioMileage('range-km')">
                  <input class="form-check-input" type="radio" name="typeMileage" id="range-km" value="range-km" formControlName="typeMileage">
                  <label class="form-check-label" for="range-km">
                    {{'REPORTS.REPORT_FILTER.FILTER_KM.RANGE_KM' | translate}}
                  </label>
                </div>

                <div class="d-flex gap-2 pt-3 pb-3">
                    <div class="form-group">
                        <label for="startMileage">{{'REPORTS.REPORT_FILTER.KM_FROM' | translate}}</label>
                        <input type="number" class="form-control" formControlName="startMileage">
                    </div>

                    <div class="form-group">
                        <label for="endMileage">{{'REPORTS.REPORT_FILTER.KM_TO' | translate}}</label>
                        <input type="number" class="form-control" formControlName="endMileage">
                    </div>
                </div>
            </div>

        </form>
    </div>

    <footer class="d-flex gap-2 justify-content-center pt-4">
        <button type="button" class="btn-gs-secondary" (click)="reset()">{{'ACTIONS.CANCEL' | translate}}</button>
        <button type="button" class="btn-gs-primary" (click)="apply()">{{'ACTIONS.APPLY' | translate}}</button>
    </footer>
</div>