import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { DropdownOption } from 'src/app/shared/components/dropdown/dropdown-primary/dropdown.interface';
import { ModalUtilsService } from 'src/app/shared/utilities/modal-utils.service';

@Component({
  selector: 'vehicles-table-filters',
  templateUrl: './vehicles-table-filters.component.html',
  styleUrls: ['./vehicles-table-filters.component.scss']
})
export class VehiclesTableFiltersComponent implements OnInit, OnDestroy {
  @Input() titleTable: string;

  listTableFilter!: DropdownOption[];
  filterTable!: DropdownOption;
  listTableFilterHeaders!: DropdownOption[]; 
  filterHeader: DropdownOption;

  listMassiveActions: DropdownOption[];

  filterForm: FormGroup = new FormGroup({
    headerToFilter: new FormControl(),
    inputFilter: new FormControl(),
    startDate:  new FormControl('', Validators.pattern(/^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[012])-(\d{4})$/)),
    endDate: new FormControl('', Validators.pattern(/^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[012])-(\d{4})$/))
  }); 

  @Output() filterTableChanged = new EventEmitter<string>(); 
  @Output() filterTableHeaderChanged = new EventEmitter<FormGroup>();
  @Output() exportEvent = new EventEmitter<any>();
  @Output() onMassiveAction = new EventEmitter<string>();

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _translateService: TranslateService,
    private _modalUtilsService: ModalUtilsService
  ) { }

  ngOnInit(): void {
    this.listTableFilter = [{ 
      text: this._translateService.instant('ALL'), 
      value: type_table_filter 
    }];
    
    this.filterTable = JSON.parse(JSON.stringify(this.listTableFilter[0]));

    this.listTableFilterHeaders = this._translateService.instant('VEHICLES.FILTERS.HEADERS');
    this.filterHeader = JSON.parse(JSON.stringify(this.listTableFilterHeaders[0]));

    this.listMassiveActions = this._translateService.instant('MASSIVE_ACTIONS');

    this.filterForm.patchValue({ headerToFilter: this.filterHeader.value });
    this.filterForm.valueChanges
      .pipe(
        debounceTime(500), 
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
        .subscribe(() => {
          this.filterTableHeaderChanged.emit(this.filterForm); 
        });
  }
  
  selectedOption($event: DropdownOption) {
    this.filterHeader = $event;
    this.filterForm.patchValue({ headerToFilter: $event.value });
  }

  selectMassiveOption($event: DropdownOption) {
    this.onMassiveAction.emit($event.value);
  }

  onFilterClick() {
    this._modalUtilsService.openModalFilterVehicle()
    .pipe(takeUntil(this.destroy$))
    .subscribe();
  }

  onClickExport() {
    this.exportEvent.emit();
  }

  onFilterTableChange(selectedOption: DropdownOption) {
    this.filterTable = selectedOption; 
    this.filterTableChanged.emit(selectedOption.value); 
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export const type_table_filter = 'all_vehicles'; 