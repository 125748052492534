import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { Observable, catchError, shareReplay, tap } from 'rxjs';
import { CommonService } from './common.service';
import { VehicleUsageDataResponse, VehicleUsageResponse } from '../models/vehicle-usages/vehicle-usages-response';
import { VehicleUsageRequest } from '../models/vehicle-usages/vehicle-usage-request';
import { VehicleUsage } from '../models/vehicle-usages/vehicle-usages';
import { MappingModel } from '../models/users';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class VehicleUsagesService {

  constructor(
    private _httpClient: HttpClient,
    private _commonService: CommonService,
    private _toasterService: ToastrService,
    private _translateService: TranslateService,
  ) { }

  createVehicleUsage$(tenantId: string, vehicleUsage: VehicleUsageRequest): Observable<VehicleUsage> {
    return this._httpClient.post<VehicleUsage>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicle-usages`, vehicleUsage)
    .pipe(
      tap(response => {
        if (response.id) {
          this._toasterService.success(this._translateService.instant("BOOKINGS.MESSAGES.BOOKING_USAGE_CREATED"));
        }
      }),
      catchError(error => this._commonService.handleError(error))
    );
  }
  
  listVehicleUsages$(tenantId: string, params?: any): Observable<VehicleUsageResponse> {
    return this._httpClient.get<VehicleUsageResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicle-usages`, 
    { params: this._commonService.buildParams(params) });
  }

  getVehicleUsage$(tenantId: string, vehicleUsageId: string, params?: any): Observable<VehicleUsage> {
    return this._httpClient.get<VehicleUsage>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicle-usages/${vehicleUsageId}`, 
    { params: this._commonService.buildParams(params) })
    .pipe(
      shareReplay(1),
    );
  }

  updateVehicleUsage$(tenantId: string, vehicleUsageId: string, vehicleUsage: VehicleUsageRequest): Observable<VehicleUsage> {
    return this._httpClient.put<VehicleUsage>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicle-usages/${vehicleUsageId}`, vehicleUsage);
  }

  deleteVehicleUsage$(tenantId: string, vehicleUsageId: string): Observable<VehicleUsage> {
    return this._httpClient.delete<VehicleUsage>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicle-usages/${vehicleUsageId}`);
  }

  exportVehicleUsages$(tenantId: string, exportData: MappingModel, params?: any): Observable<HttpResponse<Blob>> {
    return this._httpClient.post(`${VECTORE_API_BASE_URL}/${tenantId}/export/vehicle-usages`, exportData,
    { observe: 'response', responseType: 'blob', params: this._commonService.buildParams(params) })
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

  getVehicleUsageData$(tenantId: string, params?: any): Observable<VehicleUsageDataResponse> {
    return this._httpClient.get<VehicleUsageDataResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicle-usage-data`, 
    { params: this._commonService.buildParams(params) });
  }
}
