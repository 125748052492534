<div class="card-vehicle-modal">
    <header class="card-vehicle-modal-header">
        <h4 class="card-vehicle-modal-title" *ngIf="this.modalStatus.isCreate">
            {{ 'VEHICLES.NEW' | translate }}
        </h4>

        <h4 class="card-vehicle-modal-title" *ngIf="this.modalStatus.isPatch">
            {{ 'VEHICLES.PATCH' | translate }}
        </h4>
            
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </header>

    <content>
        <form [formGroup]="vehicleForm">
            <div class="container-avatar">
                <input #fileInput 
                    type="file" class="form-control d-none" 
                    id="imageFileId"
                    aria-describedby="imageFileIdHelp" (change)="onFileSelected($event)">

                <div class="avatar-placeholder-md vehicle-placeholder">
                    <img class="w-100 h-100" *ngIf="previewAvatar | async as safeUrl" [src]="safeUrl">
                </div>

                <button-first-type
                    [cssClass]="'btn-gs-primary-icon'"
                    [buttonText]="selectedFile ? 'CHANGE_PHOTO' : 'UPLOAD_PHOTO'"
                    [iconClass]="'bi-camera-fill'"
                    (click)="fileInput.click()">
                </button-first-type>
            </div>

            <div class="container-main-fields d-flex gap-3 flex-column">

                <div class="form-group">
                    <label for="license-plate" class="form-label">{{ 'LICENSE_PLATE' | translate }} <span>*</span></label>
                    <input type="text" class="form-control" formControlName="licensePlate">
                </div>

                <div class="form-group">
                    <label for="vehicle-type" class="form-label">{{'VEHICLES.PURCHASED_TYPE' | translate}} <span>*</span></label>
                    <dropdown-primary-purchase-type
                        [purchaseTypeId]="vehicle?.purchaseTypeId"
                        (onSelectedEvent)="onVehiclePurchaseTypeSelected($event)">
                    </dropdown-primary-purchase-type>
                </div> 

            </div>

            <div class="container-version-fields d-flex gap-3 flex-column mt-4">
                <h3 class="title">
                    {{'VEHICLES.VERSION' | translate}}
                </h3>

                <div class="form-group">
                    <label for="make" class="form-label">{{'VEHICLES.MAKE' | translate}} <span>*</span></label>
                    <dropdown-primary-make
                        [makeId]="vehicle?.makeId"
                        (onSelectedEvent)="onVehicleMakeSelected($event)">
                    </dropdown-primary-make>
                </div>

                <div class="form-group">
                    <label for="model" class="form-label">{{'VEHICLES.MODEL' | translate}} <span>*</span></label>
                    <dropdown-primary-model
                        [modelId]="vehicle?.modelId"
                        [makeId]="vehicleForm.get('makeId')?.value"
                        (onSelectedEvent)="onVehicleModelSelected($event)">
                    </dropdown-primary-model>
                </div>

                <div class="form-group">
                    <label for="version" class="form-label">{{'VEHICLES.VERSION' | translate}} <span>*</span></label>
                    <dropdown-primary
                        class="w-100"
                        [isDisabled]="!vehicleVersionStatus.version"
                        [selectedOption]="vehicleVersionSelected"
                        [options]="vehicleVersionListOptions"
                        (selectedOptionChange)="onVehicleVersionSelected($event)"
                        [emitNgOnInit]="false"
                        [activeScrollbar]="true"
                        [iconClass]="'bi bi-chevron-down'"
                        [cssClass]="'btn-dropdown'">
                    </dropdown-primary>
                </div>

                <div class="form-group">
                    <label for="vehicle-type" class="form-label">{{ 'VEHICLES.VEHICLE_TYPE' | translate }}</label>
                    <input type="text" class="form-control" formControlName="vehicleTypeId">
                </div>

            </div>

            <div class="container-fleet-fields d-flex gap-3 flex-column mt-4">
                <h3 class="title">
                    {{'VEHICLES.FLEET_DATA' | translate}}
                </h3>

                <div class="form-group">
                    <label for="display-name" class="form-label">{{ 'NAME_TO_VIEW' | translate }}</label>
                    <input type="text" class="form-control" formControlName="displayName">
                </div>

                <div class="form-group">
                    <label for="mileage" class="form-label">{{ 'MILEAGE' | translate }}</label>

                    <div class="input-group mb-3">
                        <input type="number" class="form-control" aria-describedby="basic-addon2" formControlName="mileage">
                        <span class="input-group-text" id="basic-addon2">Km</span>
                    </div>
                </div>

                <div class="form-group">
                    <label for="license-plate" class="form-label">{{ 'VEHICLES.FLEET_ENTRY_DATE' | translate }} <span>*</span></label>
                    <input-date-picker formControlName="fleetEntryDate"></input-date-picker>
                </div>

                <div class="form-group">
                    <label for="license-plate" class="form-label">{{ 'VEHICLES.FLEET_END_DATE' | translate }}</label>
                    <input-date-picker formControlName="fleetExitDate"></input-date-picker>
                </div>

                <div class="form-group">
                    <label for="license-plate" class="form-label">{{ 'VEHICLES.FLEET_ENRTY_COST' | translate }}</label>
                    <input type="number" class="form-control" formControlName="fleetEntryValue">
                </div>

                <div class="form-group">
                    <label for="headquarter" class="form-label">{{'HEADQUARTER.NAME' | translate}}</label>
                    <dropdown-primary-headquarter
                        [headquarterId]="vehicle?.headquarterId"
                        (onSelectedEvent)="onHeadquarterSelected($event)">                        
                    </dropdown-primary-headquarter>
                </div>

                <div class="form-group">
                    <label for="location" class="form-label">{{'LOCATION.NAME' | translate}}</label>
                    <dropdown-primary-location
                        [headquarterId]="vehicleForm.controls['headquarterId'].value"
                        [locationId]="vehicle?.locationId"
                        (onSelectedEvent)="onLocationSelected($event)">                        
                    </dropdown-primary-location>
                </div>

                <div class="form-group">
                    <label for="tag" class="form-label">{{'TAG' | translate}}</label>        
                    <input type="text" class="form-control" [placeholder]="'INSERT_TAG' | translate"
                        formControlName="tag"
                        [isAnimated]="true"
                        [typeahead]="tags$"
                        [typeaheadAsync]="true"
                        [typeaheadMinLength]="0"
                        (typeaheadOnSelect)="onTagSelected($event)">
                    
                    <div class="d-flex flex-wrap gap-2 mt-2">
                        <button type="button" class="btn-sm-gs-full-secondary d-flex align-items-center gap-1" *ngFor="let tag of selectedTags">
                            {{tag.name}}
                            <button type="button" class="btn-close pull-right" aria-label="Close" (click)="removeTag(tag.id)"></button>
                        </button>
                    </div>
                </div>

            </div>

            <div class="container-fleet-fields d-flex gap-3 flex-column mt-4" *ngIf="fields && fields?.length > 0">
                <div class="title">{{'VEHICLES.ADDITIONAL_INFORMATION' | translate}}</div>
                <formly-form [form]="vehicleForm" [fields]="fields"></formly-form>
            </div>
            
        </form>
    </content>
    
    <footer>
        <div class="d-flex gap-3 justify-content-center mt-3">
            <div type="button" class="btn-gs-secondary-light" (click)="this.bsModalRef.hide()">
                {{ 'ACTIONS.CANCEL' | translate }}
            </div>
    
            <button type="submit" class="btn-gs-primary " 
                [disabled]="vehicleForm.invalid" (click)="onSubmit()">
                {{ 'ACTIONS.SAVE' | translate }}
            </button>
        </div>
    </footer>
</div>