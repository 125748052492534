import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DropdownOption } from 'src/app/shared/components/dropdown/dropdown-primary/dropdown.interface';
import { Task } from 'src/app/shared/models/task';
import { TasksService } from 'src/app/shared/api-services/tasks.service';
import { TaskManageFollowerModalComponent } from '../../../task-manage-follower-modal/task-manage-follower-modal.component';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { TaskFilter } from 'src/app/shared/models/tasks/task-filter';

@Component({
  selector: 'dropdown-actions-dots',
  template: `
    <dropdown-vertical-dots 
      [options]="listActions" (selectedOptionChange)="onSelectedOption($event)">
    </dropdown-vertical-dots>
  `,
})
export class DropdownActionsDotsComponent {
  tenantId = this._tenantService.getTenantId();
  @Input() task: Task;

  listActions: DropdownOption[] = [];

  modalFollower: BsModalRef;

  constructor(
    private _tenantService: TenantService,
    private _taskService: TasksService,
    private _translateService: TranslateService,
    private _modalService: BsModalService,
  ) { }

  ngOnInit() {
    this.listActions = this._translateService.instant('TASKS.ACTIONS_TASK');
  }

  onSelectedOption(option: DropdownOption): void {
    
    if (option.value === 'open_modal_follower') {
      let params: TaskFilter = {
        includeTaskWatchers: true,
      };

      let task = this._taskService.getTaskId$(this.tenantId, this.task.id, params);
      task.subscribe({
        next: task => {
          this.modalFollower = this._modalService.show(TaskManageFollowerModalComponent, {
            class: 'modal-sm',
            initialState: { taskId : task.id},
            animated: true,
          });
        }
      })
    }
  }
}