import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, tap } from 'rxjs';
import { CultureInfo, TenantInfo, TimeZone } from '../models/info';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class InfoService {
  
  constructor(
    private _http: HttpClient,
    private _commonService: CommonService
  ) { }

  getTenantInfos(tenantId: string): Observable<TenantInfo> {
    return this._http.get<TenantInfo>(`${VECTORE_API_BASE_URL}/${tenantId}/tenant-infos`)
    .pipe(
      catchError(error => this._commonService.handleErrorForGetNotFound(error))
    );
  }

  patchTenantInfos(tenantId: string, tenantInfo: TenantInfo): Observable<TenantInfo> {
    return this._http.patch<TenantInfo>(`${VECTORE_API_BASE_URL}/${tenantId}/tenant-infos`, tenantInfo)
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

  downloadCarPolicyFile(tenantId: string): Observable<HttpResponse<any>> {
    return this._http.get(`${VECTORE_API_BASE_URL}/${tenantId}/tenant-infos/car-policy-file`, 
    { observe: 'response', responseType: 'blob' })
    .pipe(
      tap(response => {
        const url = window.URL.createObjectURL(response.body);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'car-policy';
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }),
      catchError(error => this._commonService.handleErrorDownload(error))
    );
  }

  getCultureInfos(tenantId: string): Observable<CultureInfo[]> {
    return this._http.get<CultureInfo[]>(`${VECTORE_API_BASE_URL}/${tenantId}/culture-infos`)
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

  getTimeZones(tenantId: string): Observable<TimeZone[]> {
    return this._http.get<TimeZone[]>(`${VECTORE_API_BASE_URL}/${tenantId}/time-zones`)
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

  listTenantExtraFields(tenantId: string): Observable<any> {
    return this._http.get<any>(`${VECTORE_API_BASE_URL}/${tenantId}/tenant-infos/extra-fields`)
    .pipe(
      catchError(error => this._commonService.handleError(error))
    );
  }

}
  